import React from "react";
import { Close } from "../../Common/Images/Images";

export const DeleteModal = ({ close, deletefunc }) => {
  return (
    <div className="items_delete_modal">
      <div className="items_delete_modal_main">
        <div className="header">
          <Close clickhandel={close} />
        </div>
        <div className="body">Confirm Delete item ?</div>
        <div className="footer">
          <div className="btn" type="type2" onClick={deletefunc}>
            Delete
          </div>
          <div className="btn" type="type2" onClick={close}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};
