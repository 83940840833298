import React, { useEffect, useState } from "react";
import Calendar from "../../../custpackages/react-date-range/dist/components/Calendar";
import { ReactComponent as Arrow_down } from "../../../img/icons/black/arrow_down_icon.svg";
import "./custdatepickerStyle.scss";
import "../../../custpackages/react-date-range/dist/styles.css";
import "../../../custpackages/react-date-range/dist/theme/default.css";

export const CustDatePicker = ({
  custid,
  value,
  setvalue,
  defaultplaceholder,
}) => {
  const [date, setdate] = useState(null);
  const [date_picker_active, setdate_picker_active] = useState(false);
  const [dropdown_id, setdropdown_id] = useState("");
  useEffect(() => {
    if (date != null) {
      setdate_picker_active(false);
    }
  }, [date]);
  useEffect(() => {
    window.addEventListener("click", (e) => {
      let flyoutEl = document.getElementById("custdatepicker" + custid),
        targetEl = e.target; // clicked element
      do {
        if (targetEl == flyoutEl) {
          return;
        }
        targetEl = targetEl.parentNode;
      } while (targetEl);
      setdate_picker_active(false);
    });
  }, []);

  useEffect(() => {
    setdate(value && value !== "" ? new Date(value) : null);
  }, [value]);

  return (
    <div className="custdatepicker" id={"custdatepicker" + custid}>
      <div
        className="default_txt"
        onClick={() => {
          setdate_picker_active(!date_picker_active);
        }}
      >
        {date == null
          ? defaultplaceholder
          : date?.toString().split(" ").slice(1, 4).join(" ")}
        <Arrow_down
          className="dropdown_arrow_down"
          style={!date_picker_active ? {} : { transform: "rotate(180deg)" }}
        />
      </div>
      <div
        className="calender_main"
        style={date_picker_active ? { height: "350px" } : { height: "0px" }}
      >
        <Calendar
          onChange={(item) => {
            setdate(item);
            setvalue(item?.toString().split(" ").slice(1, 4).join(" "));
          }}
          date={date}
        />
      </div>
    </div>
  );
};
