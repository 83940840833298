import React, { useEffect, useState } from "react";
import "./addclientmodalStyle.scss";
import { Close } from "../../Common/Images/Images";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  add_client_options,
  add_client_withoutaddress,
} from "../../../API/API";
import { encrypt_data } from "../../../RSA_Encryption/RSA";

const month_count = [
  [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
  [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
];

export const AddClientModal = ({
  setaddclientmodal,
  client_table_original,
}) => {
  // console.log(client_table_original);
  const navigate = useNavigate();
  const current_store = useSelector((state) => state);
  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [phone_err, setphone_err] = useState("");
  const [email, setemail] = useState("");
  const [email_err, setemail_err] = useState("");
  const [add, setadd] = useState(["", "", ""]);
  const [note, setnote] = useState("");
  const [add_client, setadd_client] = useState(false);
  const [err, seterr] = useState([false, false]);
  const [card, setcard] = useState({ name: "", number: "", date: "", cvv: "" });
  // phone_cop_err,email_copy_err
  const OnChangeEmail = (e) => {
    setemail(e.target.value);
    setemail_err(
      (
        e.target.value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) == null
      ).toString()
    );
  };
  const OnChangePhone = (e) => {
    if (
      Number(e.target.value.slice(-1)).toString() == "NaN" ||
      e.target.value.length > 17 ||
      (e.target.value.slice(-1) == " " &&
        ![5, 9, 13].some((el) => el == e.target.value.length))
    ) {
      return;
    }
    setphone_err("true");
    if (e.target.value.length == 17) {
      setphone_err("false");
      // setphone_verification_state('')
    }
    switch (e.target.value.length) {
      case 1:
        return setphone(`(+1) ${e.target.value[0]}`);
      case 5:
        return setphone("");
      case 9:
        if (e.target.value.slice(-1) == " ") {
          return setphone(e.target.value.slice(0, 8));
        }
        return setphone(
          e.target.value.slice(0, 8) + " " + e.target.value.slice(-1)
        );
      case 13:
        if (e.target.value.slice(-1) == " ") {
          return setphone(e.target.value.slice(0, 12));
        }
        return setphone(
          e.target.value.slice(0, 12) + " " + e.target.value.slice(-1)
        );

      default:
        return setphone(e.target.value);
    }
  };

  useEffect(() => {
    if (name != "" && phone_err == "false" && email_err == "false") {
      if (
        (add[0] === "" && add[1] === "" && add[2] === "") ||
        (add[0].length > 0 && add[1].length > 0 && add[2].length > 0)
      ) {
        let got_user_phone = client_table_original.filter(
          (el) => el.phone === phone.split(" ").splice(1).join("")
        );
        let got_user_email = client_table_original.filter(
          (el) => el.email === email
        );
        // console.log("def", got_user_phone, got_user_email);
        seterr([got_user_phone.length > 0, got_user_email.length > 0]);
        if (got_user_phone.length > 0 || got_user_email.length > 0) {
          setadd_client(false);
        } else {
          setadd_client(true);
        }
      } else {
        return setadd_client(false);
      }
      if (
        (card.number === "" && card.date === "" && card.cvv === "") ||
        (card.number.length >= 18 &&
          card.date.length === 7 &&
          card.cvv.length >= 3)
      ) {
        // console.log("card_err");
        return setadd_client(true);
      } else {
        return setadd_client(false);
      }
    } else {
      seterr([false, false]);
      setadd_client(false);
    }
  }, [name, phone, email, add, note, card]);

  const handeladdclient = () => {
    let phone_de_formatted = phone.split(" ").splice(1).join("");
    if (add[0] == "" && add[1] == "" && add[2] == "" && card.number == "") {
      add_client_withoutaddress(
        {
          name: name,
          email: email,
          mobile: phone_de_formatted,
          notes: note,
        },
        current_store.user.token,
        (data) => {
          if (!data.status) {
            return navigate("/404");
          }
        }
      );
    } else {
      add_client_options(
        {
          name: name,
          email: email,
          mobile: phone_de_formatted,
          notes: note,
          address1: add[0],
          apt: add[1],
          zipcode: add[2],
          doorman: 0,
          card_name: encrypt_data(name),
          card_number: encrypt_data(card.number),
          card_cvc: encrypt_data(card.cvc),
          card_expiry_month: encrypt_data(card.date.split("/")[0].trim()),
          card_expiry_year: encrypt_data(card.date.split("/")[1].trim()),
        },
        current_store.user.token,
        (data) => {
          if (!data.status) {
            return navigate("/404");
          }
        }
      );
    }
  };
  const handel_setcard = (data, to) => {
    setcard((prev) => ({ ...prev, [to]: data }));
  };
  const set_value_cardno = (current) => {
    // checks for a number as a input
    if (Number(current.value.substr(-1)) == current.value.substr(-1)) {
      // checks for the length of value to be less than 20
      if (current.value.replaceAll(" ", "").length >= 20) {
        return;
      }
      if (current.value.replaceAll(" ", "").length < 20) {
        handel_setcard(
          (
            [15, 16, 19].indexOf(current.value.replaceAll(" ", "").length) == -1
          ).toString(),
          "number"
        );
        // puts spaces between 4 digits eg. 1234 1234 1234 1234
        if (
          [5, 10, 15, 20].indexOf(current.value.length) > -1 &&
          card.number.length <= current.value.length
        ) {
          handel_setcard(card.number + " " + current.value.slice(-1), "number");
        } else {
          if (
            [5, 10, 15, 20].indexOf(current.value.length) > -1 &&
            current.value.slice(-1) == " "
          ) {
            return handel_setcard(current.value.trim(), "number");
          }
          handel_setcard(current.value, "number");
        }
      }
    }
  };
  const set_value_carddate = (current) => {
    // checks for a number as a input and also if cheks for while backspacing for '' and '/' if dsabled you wouldnt be able to backspace in card month
    if (
      Number(current.value.substr(-1)) == current.value.substr(-1) ||
      current.value.substr(-1) == " " ||
      current.value.substr(-1) == "/"
    ) {
      if (current.value.replaceAll("/", "").replaceAll(" ", "").length < 5) {
        if (current.value.replaceAll("/", "").replaceAll(" ", "").length != 4) {
          // setmodal_cc_date_err("true");
        } else {
          let entered_date = new Date(
            `20${current.value.split("/")[1].trim()}-${current.value
              .split("/")[0]
              .trim()}-${
              month_count[
                (2000 + current.value.split("/")[1].trim()) % 4 == 0 ? 1 : 0
              ][Number(current.value.split("/")[0].trim())]
            }T00:00:00`
          );
          let current_date = new Date(
            `${new Date().getFullYear()}-${
              new Date().getMonth() < 9 ? "0" : ""
            }${new Date().getMonth() + 1}-${
              new Date().getDate() < 10 ? "0" : ""
            }${new Date().getDate()}T00:00:00`
          );
          // setmodal_cc_date_err((entered_date - current_date <= 0).toString());
        }
        // adds a ' / ' etween two numbers and doesnot lets user to enter the first number greater than 12 if it is then changes eg. 13 =  01 / 3
        if (
          current.value.length == 3 &&
          card.date.length + 1 <= current.value.length
        ) {
          handel_setcard(card.date + " / " + current.value.slice(-1), "date");
        } else {
          if (current.value > 12) {
            let date_arr = current.value.split("");
            handel_setcard("0" + date_arr[0] + " / " + date_arr[1]);
          } else {
            if (current.value.length == 5 && current.value.substr(-1) == " ") {
              return handel_setcard(current.value.slice(0, 2), "date");
            }
            handel_setcard(current.value, "date");
          }
        }
      }
    }
  };

  const set_value_cardcvv = (current) => {
    if (current.value <= 9999) {
      handel_setcard(current.value, "cvv");
    }
  };

  return (
    <div className="addclientmodal">
      <div>
        <div className="header">
          <Close clickhandel={() => setaddclientmodal(false)} />
        </div>
        <div className="body">
          <div className="title">Name</div>
          <div className="cust_input">
            <input
              type="text"
              placeholder="Insert Name"
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
          </div>
          <div className="mt-15"></div>
          <div className="title">Mobile</div>
          <div className="cust_input">
            <input
              type="text"
              placeholder="Insert Mobile"
              value={phone}
              onChange={OnChangePhone}
            />
          </div>
          <div
            style={{
              color: "red",
              height: err[0] ? 21 : 0,
              overflow: "hidden",
              transition: "height .2s linear",
              fontSize: 13,
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            Phone number already in use
          </div>
          <div className="mt-15"></div>
          <div className="title">Email</div>
          <div className="cust_input">
            <input
              type="text"
              placeholder="Insert Email"
              value={email}
              onChange={OnChangeEmail}
            />
          </div>
          <div
            style={{
              color: "red",
              height: err[1] ? 21 : 0,
              overflow: "hidden",
              transition: "height .2s linear",
              fontSize: 13,
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
            }}
          >
            Email already in use
          </div>
          <div className="mt-20"></div>
          <div className="title">Address (opt.)</div>
          <div className="cust_input add_input_group">
            <input
              type="text"
              placeholder="Insert Address"
              value={add[0]}
              onChange={(e) => setadd([e.target.value, add[1], add[2]])}
            />
            <input
              type="text"
              placeholder="Insert Apt"
              value={add[1]}
              onChange={(e) => setadd([add[0], e.target.value, add[2]])}
            />
            <input
              type="text"
              placeholder="Insert Zip Code"
              value={add[2]}
              onChange={(e) => setadd([add[0], add[1], e.target.value])}
            />
          </div>
          <div className="mt-20"></div>
          <div className="title">Note</div>
          <div className="cust_input">
            <input
              type="text"
              placeholder="Insert Note"
              value={note}
              onChange={(e) => setnote(e.target.value)}
            />
          </div>
          <div className="mt-20"></div>
          <div className="title">Credit card</div>
          <div className="cust_input">
            <input
              type="text"
              placeholder="Insert Card Number"
              value={card.number}
              onChange={(e) => {
                set_value_cardno(e.target);
                // setcard((prev) => ({ ...prev, number: e.target.value }))
              }}
            />
          </div>
          <div className="cust_input">
            <input
              type="text"
              placeholder="Insert Month /Year"
              value={card.date}
              onChange={(e) => {
                set_value_carddate(e.target);
                // setcard((prev) => ({ ...prev, date: e.target.value }))
              }}
            />
          </div>
          <div className="cust_input">
            <input
              type="text"
              placeholder="Insert Cvv"
              value={card.cvv}
              onChange={(e) => {
                set_value_cardcvv(e.target);
                // setcard((prev) => ({ ...prev, cvv: e.target.value }))
              }}
            />
          </div>
          <div className="mt-20"></div>
        </div>
        <div className="footer">
          <div
            className="btn"
            type={add_client ? "type2" : "type1"}
            onClick={() => add_client && handeladdclient()}
          >
            Add Client
          </div>
        </div>
      </div>
    </div>
  );
};
