import React, { useEffect, useState } from "react";
import "./cashStyle.scss";
import { Eye, MenuDots } from "../../Component/Common/Images/Images";
import { SearchAndFilterBar } from "../../Component/Common/SearchAndFilterBar/SearchAndFilterBar";
import { Table } from "../../Component/Common/Table/Table";
import { CashMenu } from "../../Component/Cash/CashMenu/CashMenu";
import { CashInOutModal } from "../../Component/Cash/CashInOutModal/CashInOutModal";
import { CashViewEditModal } from "../../Component/Cash/CashViewEditModal/CashViewEditModal";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Edit_Icon } from "../../img/miscellaneous/edit_icon.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { checktoken, convert_to_am_pm, getcashlist } from "../../API/API";

const img_base64 = "";

const convert_cashdate = (date1) => {
  let temp_date = new Date(date1);
  let [date, month, year] = [
    temp_date.getDate(),
    temp_date.getMonth() + 1,
    temp_date.getFullYear(),
  ];
  let [hrs, mins, unit] = [temp_date.getHours(), temp_date.getMinutes(), "AM"];
  if (date <= 9) {
    date = `0${date}`;
  }
  if (month <= 9) {
    month = `0${month}`;
  }
  if (hrs > 12) {
    hrs = `${hrs - 12}`;
    unit = "PM";
  }
  if (hrs <= 9) {
    hrs = `0${hrs}`;
  }
  if (mins <= 9) {
    mins = `0${mins}`;
  }
  // console.log(`${date}/${month}/${year}`, `${hrs}:${mins} ${unit}`);
  // setcurrentdatetime([`${date}/${month}/${year}`, `${hrs}:${mins} ${unit}`]);
  return `${date}/${month}/${year}`;
};
const convert_cashtime = (date1) => {
  let temp_date = new Date(date1);
  let [date, month, year] = [
    temp_date.getDate(),
    temp_date.getMonth() + 1,
    temp_date.getFullYear(),
  ];
  let [hrs, mins, unit] = [temp_date.getHours(), temp_date.getMinutes(), "AM"];
  if (date <= 9) {
    date = `0${date}`;
  }
  if (month <= 9) {
    month = `0${month}`;
  }
  if (hrs > 12) {
    hrs = `${hrs - 12}`;
    unit = "PM";
  }
  if (hrs <= 9) {
    hrs = `0${hrs}`;
  }
  if (mins <= 9) {
    mins = `0${mins}`;
  }
  // console.log(`${date}/${month}/${year}`, `${hrs}:${mins} ${unit}`);
  // setcurrentdatetime([`${date}/${month}/${year}`, `${hrs}:${mins} ${unit}`]);
  return `${hrs}:${mins} ${unit}`;
};

export const Cash = ({ setshow_navbar, setloader }) => {
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    setshow_navbar(true);
    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("jc_pos_token");
      if (local_token) {
        setloader(true);
        checktoken(local_token, (data) => {
          if (!data.status) {
            return navigate("/");
          }
          setis_admin(data.admin);
          dispatch({
            type: "login",
            payload: { token: local_token, admin: data.admin },
          });
          setloader(false);
        });
        return;
      } else {
        return navigate("/");
      }
    } else {
      // return navigate("/");
      setis_admin(current_store.user.admin);
      setloader(true);

      getcashlist(current_store.user.token, (data) => {
        if (!data.status) {
          return navigate("/404");
        }
        setcash_table([...data.data.cashData]);
        let temp_total_cash = 0;
        data.data.cashData.map((el) => (temp_total_cash += Number(el.amount)));
        setinregister(temp_total_cash.toFixed(2));
        setloader(false);
      });
    }
  }, [current_store.user.admin]);
  const [menutoshow, setmenutoshow] = useState(-1);
  const [inregister, setinregister] = useState(0);
  const [cashinout, setcashinout] = useState([false, ""]);
  const [cashviewedit, setcashviewedit] = useState([false, ""]);
  const [is_admin, setis_admin] = useState(false);
  const [cash_table, setcash_table] = useState([]);
  const [search_string, setsearch_string] = useState("");
  useEffect(() => {}, []);
  return (
    <div className="cash">
      <div className="pagetitle">
        <div>Cash</div>
      </div>
      <div className="cash_body">
        <div className="cash_body_heading">
          <div className="in_cash_register">
            <div>IN CASH REGISTER</div>
            <div>${inregister}</div>
          </div>
          <div className="cash_action_btn">
            <div
              onClick={() => {
                setcashinout([true, "cashin"]);
              }}
            >
              + Cash In
            </div>
            <div
              onClick={() => {
                setcashinout([true, "cashout"]);
              }}
            >
              - Cash Out
            </div>
          </div>
        </div>
        <SearchAndFilterBar
          placeholder="Search for Number, Date, Name, Description,..."
          filter_show="no"
          handlechange_search={setsearch_string}
        />
        <Table
          cust_class="cash_table"
          type="type1"
          table_head_data={[
            "NAME",
            "AMOUNT",
            "DATE",
            "TIME",
            "DESCRIPTION",
            "",
          ]}
          table_body_data={[
            // [1, 1, 1, 1, 1],
            ...cash_table
              .filter(
                (el) =>
                  el.full_name
                    .toLowerCase()
                    .match(search_string.toLowerCase()) ||
                  el.description
                    .toLowerCase()
                    .match(search_string.toLowerCase())
              )
              .map((el, i) => {
                return [
                  el.full_name,
                  Number(el.amount) >= 0 ? (
                    <div fcolor="green">+ $ {Number(el.amount).toFixed(2)}</div>
                  ) : (
                    <div fcolor="red">
                      - $ {Math.abs(Number(el.amount)).toFixed(2)}
                    </div>
                  ),
                  new Date(el.dateTime) != "Invalid Date"
                    ? convert_cashdate(el.dateTime)
                    : el.dateTime,
                  new Date(el.dateTime) != "Invalid Date"
                    ? convert_cashtime(el.dateTime)
                    : el.dateTime,
                  // convert_to_am_pm(el.dateTime),
                  el.description,
                  <>
                    {is_admin ? (
                      <MenuDots
                        clickhandel={() => {
                          setmenutoshow(menutoshow !== i ? i : -1);
                        }}
                      />
                    ) : (
                      <div
                        onClick={() => {
                          setcashviewedit([
                            true,
                            "view",
                            {
                              id: el.id,
                              name: el.full_name,
                              amount: el.amount,
                              credited: Number(el.amount) >= 0,
                              date:
                                new Date(el.dateTime) != "Invalid Date"
                                  ? convert_cashdate(el.dateTime)
                                  : el.dateTime,
                              time:
                                new Date(el.dateTime) != "Invalid Date"
                                  ? convert_cashtime(el.dateTime)
                                  : el.dateTime,
                              description: el.description,
                              img: el.img,
                            },
                          ]);
                        }}
                      >
                        <Eye />
                      </div>
                    )}
                    {menutoshow === i && (
                      <CashMenu
                        close={() => setmenutoshow(-1)}
                        view={() => {
                          setcashviewedit([
                            true,
                            "view",
                            {
                              id: el.id,
                              name: el.full_name,
                              amount: Number(el.amount),
                              credited: Number(el.amount) >= 0,
                              date:
                                new Date(el.dateTime) != "Invalid Date"
                                  ? convert_cashdate(el.dateTime)
                                  : el.dateTime,
                              time:
                                new Date(el.dateTime) != "Invalid Date"
                                  ? convert_cashtime(el.dateTime)
                                  : el.dateTime,
                              description: el.description,
                              img: el.img,
                            },
                          ]);
                        }}
                        edit={() => {
                          setcashviewedit([
                            true,
                            "edit",
                            {
                              id: el.id,
                              name: el.full_name,
                              amount: Number(el.amount),
                              credited: Number(el.amount) >= 0,
                              date:
                                new Date(el.dateTime) != "Invalid Date"
                                  ? convert_cashdate(el.dateTime)
                                  : el.dateTime,
                              time:
                                new Date(el.dateTime) != "Invalid Date"
                                  ? convert_cashtime(el.dateTime)
                                  : el.dateTime,
                              description: el.description,
                              img: el.img,
                              full_datetime: el.dateTime,
                            },
                          ]);
                        }}
                      />
                    )}
                  </>,
                ];
              }),
            // [
            //   "Paolo Sanches",
            //   <div fcolor="green">+ $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Tipaaaaaaaaaaaaaaaaa",
            //   <>
            //     {is_admin ? (
            //       <MenuDots
            //         clickhandel={() => {
            //           setmenutoshow(menutoshow !== 0 ? 0 : -1);
            //         }}
            //       />
            //     ) : (
            //       <div
            //         onClick={() => {
            //           setcashviewedit([
            //             true,
            //             "view",
            //             {
            //               name: "Paolo Sanches",
            //               amount: 30,
            //               credited: true,
            //               date: "04/06/2022",
            //               time: "1:30 PM",
            //               description: "Tip",
            //               img: img_base64,
            //             },
            //           ]);
            //         }}
            //       >
            //         <Eye />
            //       </div>
            //     )}
            //     {menutoshow === 0 && (
            //       <CashMenu
            //         close={() => setmenutoshow(-1)}
            //         view={() => {
            //           setcashviewedit([
            //             true,
            //             "view",
            //             {
            //               name: "Paolo Sanches",
            //               amount: 30,
            //               credited: true,
            //               date: "04/06/2022",
            //               time: "1:30 PM",
            //               description: "Tip",
            //               img: img_base64,
            //             },
            //           ]);
            //         }}
            //         edit={() => {
            //           setcashviewedit([
            //             true,
            //             "edit",
            //             {
            //               name: "Paolo Sanches",
            //               amount: 30,
            //               credited: true,
            //               date: "04/06/2022",
            //               time: "1:30 PM",
            //               description: "Tip",
            //               img: img_base64,
            //             },
            //           ]);
            //         }}
            //       />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="red">- $ 10.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Towels",
            //   <>
            //     {is_admin ? (
            //       <MenuDots
            //         clickhandel={() => setmenutoshow(menutoshow !== 1 ? 1 : -1)}
            //       />
            //     ) : (
            //       <div
            //         onClick={() => {
            //           setcashviewedit([
            //             true,
            //             "view",
            //             {
            //               name: "Paolo Sanches",
            //               amount: 10,
            //               credited: false,
            //               date: "04/06/2022",
            //               time: "1:30 PM",
            //               description: "Towels",
            //               img: img_base64,
            //             },
            //           ]);
            //         }}
            //       >
            //         <Eye />
            //       </div>
            //     )}
            //     {menutoshow === 1 && (
            //       <CashMenu
            //         close={() => setmenutoshow(-1)}
            //         view={() => {
            //           setcashviewedit([
            //             true,
            //             "view",
            //             {
            //               name: "Paolo Sanches",
            //               amount: 10,
            //               credited: false,
            //               date: "04/06/2022",
            //               time: "1:30 PM",
            //               description: "Towels",
            //               img: img_base64,
            //             },
            //           ]);
            //         }}
            //         edit={() => {
            //           setcashviewedit([
            //             true,
            //             "edit",
            //             {
            //               name: "Paolo Sanches",
            //               amount: 10,
            //               credited: false,
            //               date: "04/06/2022",
            //               time: "1:30 PM",
            //               description: "Towels",
            //               img: img_base64,
            //             },
            //           ]);
            //         }}
            //       />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="green">+ $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Tip",
            //   <>
            //     <MenuDots
            //       clickhandel={() => setmenutoshow(menutoshow !== 2 ? 2 : -1)}
            //     />
            //     {menutoshow === 2 && (
            //       <CashMenu close={() => setmenutoshow(-1)} />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="red">- $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Towels",
            //   <>
            //     <MenuDots
            //       clickhandel={() => setmenutoshow(menutoshow !== 3 ? 3 : -1)}
            //     />
            //     {menutoshow === 3 && (
            //       <CashMenu close={() => setmenutoshow(-1)} />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="red">- $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Towels",
            //   <>
            //     <MenuDots
            //       clickhandel={() => setmenutoshow(menutoshow !== 4 ? 4 : -1)}
            //     />
            //     {menutoshow === 4 && (
            //       <CashMenu close={() => setmenutoshow(-1)} />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="green">+ $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Clothes",
            //   <>
            //     <MenuDots
            //       clickhandel={() => setmenutoshow(menutoshow !== 5 ? 5 : -1)}
            //     />
            //     {menutoshow === 5 && (
            //       <CashMenu close={() => setmenutoshow(-1)} />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="green">+ $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Tip",
            //   <>
            //     <MenuDots
            //       clickhandel={() => setmenutoshow(menutoshow !== 6 ? 6 : -1)}
            //     />
            //     {menutoshow === 6 && (
            //       <CashMenu close={() => setmenutoshow(-1)} />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="green">+ $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Tip",
            //   <>
            //     <MenuDots
            //       clickhandel={() => setmenutoshow(menutoshow !== 7 ? 7 : -1)}
            //     />
            //     {menutoshow === 7 && (
            //       <CashMenu close={() => setmenutoshow(-1)} />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="green">+ $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Tip",
            //   <>
            //     <MenuDots
            //       clickhandel={() => setmenutoshow(menutoshow !== 8 ? 8 : -1)}
            //     />
            //     {menutoshow === 8 && (
            //       <CashMenu close={() => setmenutoshow(-1)} />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="green">+ $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Tip",
            //   <>
            //     <MenuDots
            //       clickhandel={() => setmenutoshow(menutoshow !== 9 ? 9 : -1)}
            //     />
            //     {menutoshow === 9 && (
            //       <CashMenu close={() => setmenutoshow(-1)} />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="green">+ $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Tip",
            //   <>
            //     <MenuDots
            //       clickhandel={() => setmenutoshow(menutoshow !== 10 ? 10 : -1)}
            //     />
            //     {menutoshow === 10 && (
            //       <CashMenu close={() => setmenutoshow(-1)} />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="green">+ $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Tip",
            //   <>
            //     <MenuDots
            //       clickhandel={() => setmenutoshow(menutoshow !== 11 ? 11 : -1)}
            //     />
            //     {menutoshow === 11 && (
            //       <CashMenu close={() => setmenutoshow(-1)} />
            //     )}
            //   </>,
            // ],
            // [
            //   "Paolo Sanches",
            //   <div fcolor="green">+ $ 30.00</div>,
            //   "04/06/2022",
            //   "1:30 PM",
            //   "Tip",
            //   <>
            //     <MenuDots
            //       clickhandel={() => setmenutoshow(menutoshow !== 12 ? 12 : -1)}
            //     />
            //     {menutoshow === 12 && (
            //       <CashMenu close={() => setmenutoshow(-1)} />
            //     )}
            //   </>,
            // ],
          ]}
        />
      </div>
      {cashinout[0] && (
        <CashInOutModal
          type={cashinout[1]}
          close={() => {
            setcashinout([false, ""]);
          }}
          setloader={setloader}
        />
      )}
      {cashviewedit[0] && (
        <CashViewEditModal
          type={cashviewedit[1]}
          data={cashviewedit[2]}
          close={() => {
            setcashviewedit([false, ""]);
          }}
          setloader={setloader}
        />
      )}
    </div>
  );
};
