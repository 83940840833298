import React from "react";
import { RTE } from "../../Common/Test/Rich text editor/RTE";
import { faq_link_icons } from "../AddFAQ/AddFAQ";
import "./previewStyle.scss";
export const Preview = ({ preview, question, answer, links }) => {
  return (
    <div className={"preview " + (preview ? "active" : "")}>
      {preview && (
        <>
          <div className="preview_navbar">
            <img src="https://app.juliettecleaners.com/static/media/logo.def22475f2efb356957a.png" />
          </div>
          <div className="preview_body">
            <div className="question">
              <div>Q:</div>
              <div>
                <RTE read_only={true} msg={question} />
              </div>
            </div>
            <div className="answer">
              <div>A:</div>
              <div>
                <RTE read_only={true} msg={answer} />
              </div>
            </div>
            <div className="links_group">
              {links?.map((el, i) => (
                <div className="link_btn_items">
                  <div className="link_btn">
                    {el.img && (
                      <div className="link_img">
                        {
                          faq_link_icons.filter(
                            (el1) => el.img === el1.title
                          )[0].svg
                        }
                      </div>
                    )}
                    <a href={el.href} target="_blank">
                      <div>{el.txt}</div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
