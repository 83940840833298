import React from "react";
// scss in parent file
import icon1 from "../../../img/clients/modal/view_icon.svg";
import icon2 from "../../../img/clients/modal/edit_icon.svg";
import icon3 from "../../../img/clients/modal/createorder_icon.svg";
import icon4 from "../../../img/clients/modal/orderhistory_icon.svg";
import icon5 from "../../../img/clients/modal/analytics_icon.svg";
import { Close } from "../../Common/Images/Images";

export const ClientTableMenu = ({ handelonclick, setclient_table_menu }) => {
  return (
    <div className="clienttablemenu">
      <div className="header">
        <Close type="sm" clickhandel={() => setclient_table_menu("")} />
      </div>
      <div className="body">
        {[
          [icon1, "View", handelonclick[0]],
          [icon2, "Edit", handelonclick[1]],
          [icon3, "Create Order", handelonclick[2]],
          // [icon2, "Pre Order", handelonclick[3]],
          [icon4, "Order History", handelonclick[4]],
          [icon5, "Analytics", handelonclick[5]],
          [icon5, "Predictions", handelonclick[6]],
          [icon5, "Archive", handelonclick[7]],
        ].map((el, i) => {
          return (
            <div onClick={el[2]} key={i}>
              <div className="body_img">
                <img src={el[0]} />
              </div>
              <div className="body_txt">{el[1]}</div>
            </div>
          );
        })}
      </div>
      <div className="footer"></div>
    </div>
  );
};
