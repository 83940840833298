import React, { useState } from "react";
import { useSelector } from "react-redux";
import { addteam } from "../../../API/API";
import { encrypt_data } from "../../../RSA_Encryption/RSA";
import { Close } from "../../Common/Images/Images";
import { Switcher } from "../../Switcher/Switcher";

import "./addteamModalStyle.scss";

export const AddteamModal = ({ close, setteam_list, setloader }) => {
  const [team_data, setteam_data] = useState({
    name: "",
    // number: "",
    email: "",
    password: "",
    is_admin: 0,
  });
  const current_store = useSelector((state) => state);

  return (
    <div className="addteammodal">
      <div className="modal_main">
        <div className="header">
          <Close clickhandel={close} />
        </div>
        <div className="body">
          <div className="title">Name</div>
          <div className="cust_inp">
            <input
              type="text"
              placeholder="Insert Name"
              onChange={({ target }) => {
                setteam_data((prev) => ({ ...prev, name: target.value }));
              }}
            />
          </div>
          {/* <div className="title">Number</div>
          <div
            className="cust_inp"
            style={
              team_data.number.length < 10 && team_data.number !== ""
                ? { borderColor: "red" }
                : {}
            }
          >
            <input
              type="number"
              placeholder="Insert Number"
              onChange={({ target }) => {
                setteam_data((prev) => ({ ...prev, number: target.value }));
              }}
            />
          </div> */}
          <div className="title">Mail</div>
          <div className="cust_inp">
            <input
              type="text"
              placeholder="Insert Mail"
              onChange={({ target }) => {
                setteam_data((prev) => ({ ...prev, email: target.value }));
              }}
            />
          </div>
          <div className="title">Password</div>
          <div
            className="cust_inp"
            style={
              team_data.password.length < 8 && team_data.password !== ""
                ? { borderColor: "red" }
                : {}
            }
          >
            <input
              type="text"
              placeholder="Insert Password"
              onChange={({ target }) => {
                setteam_data((prev) => ({ ...prev, password: target.value }));
              }}
            />
          </div>
          <div className="title admin_switch">
            <div>Admin</div>
            <Switcher
              active={
                team_data.is_admin === 0
                  ? true
                  : team_data.is_admin === 1
                  ? false
                  : true
              }
              setactive={() => {
                setteam_data((prev) => ({
                  ...prev,
                  is_admin: prev.is_admin === 0 ? 1 : 0,
                }));
              }}
            />
          </div>
        </div>
        <div className="footer">
          <div
            className="btn"
            type={
              team_data.name === "" ||
              // team_data.number === "" ||
              team_data.email === "" ||
              team_data.password === "" ||
              team_data.password.length < 8
                ? // ||
                  // team_data.number.length < 10
                  "type1"
                : "type2"
            }
            onClick={() => {
              if (
                team_data.name === "" ||
                // team_data.number === "" ||
                team_data.email === "" ||
                team_data.password === "" ||
                team_data.password.length < 8
                // ||
                // team_data.number.length < 10
              ) {
                return;
              } else {
                // return;
                setloader(true);
                addteam(
                  {
                    name: team_data.name,
                    email: team_data.email,
                    password: encrypt_data(team_data.password),
                    admin: team_data.is_admin,
                  },
                  current_store.user.token,
                  (data) => {
                    if (!data.status) {
                      return;
                    }
                    setteam_list([
                      ...data.data.employees.map((el, i) => {
                        return {
                          id: i,
                          name: el.name,
                          mobile: el?.mobile ? el.mobile : "-",
                          email: el.email,
                          password: Math.floor(Math.random() * 2) + 6,
                        };
                      }),
                    ]);
                    setloader(!true);
                    close();
                  }
                );
              }
            }}
          >
            Add Employee
          </div>
        </div>
      </div>
    </div>
  );
};
