import React from "react";
import "./addordertagsStyle.scss";

import tag_id_add_img from "../../../img/miscellaneous/close_square_add.png";
import tag_delete_icon from "../../../img/miscellaneous/trash.png";
import { ArrowDown } from "../../Common/Images/Images";

export const AddOrderTags = ({
  data,
  order_item_cards,
  tag_ids,
  settag_ids,
  setadd_order_current_showing,
  // select_item,
  // setselect_item,
  // tag_id_value,
  // settag_id_value,
}) => {
  const handel_change_tagid = (current, i) => {
    let temp_tag_ids = [...tag_ids];
    let temp_data = {
      order_id: current,
      order_item: tag_ids[i].order_item,
    };
    temp_tag_ids[i] = temp_data;
    settag_ids([...temp_tag_ids]);
  };
  const handel_change_selectitem = (current, i) => {
    let temp_tag_ids = [...tag_ids];
    let temp_data = {
      order_id: tag_ids[i].order_id,
      order_item: current,
    };
    temp_tag_ids[i] = temp_data;
    settag_ids([...temp_tag_ids]);
  };
  return (
    <div className="addordertags">
      <div className="tag_order_id">
        <div className="tag_title">Order ID</div>
        <input type="text" value={data.order_id} readOnly />
      </div>
      <div className="create_tag_ids">
        {tag_ids.map((el, i) => (
          <div className="tag_id_body">
            <div>
              <div className="tag_title">Tag ID #{i + 1}</div>
              <input
                placeholder={"#Order_Tag" + (i + 1)}
                type="text"
                value={el.order_id}
                onChange={(e) => {
                  handel_change_tagid(e.target.value, i);
                }}
              />
            </div>
            <div>
              <div className="tag_title">Select Item</div>
              <div
                className="tag_cust_select"
                onClick={() => {
                  let tag_id_item_dropdown = document.getElementById(
                    "tag_id_item_dropdown" + i
                  );
                  let tag_id_item_dropdown_arr =
                    document.getElementsByClassName("tag_id_item_dropdown");
                  [...tag_id_item_dropdown_arr].map((el, i1) => {
                    if (i1 == i) return;
                    el.style.height = "";
                    el.setAttribute("active", "false");
                  });

                  tag_id_item_dropdown.setAttribute(
                    "active",
                    (tag_id_item_dropdown.style.height == "").toString()
                  );
                  tag_id_item_dropdown.style.height =
                    tag_id_item_dropdown.style.height == ""
                      ? `${order_item_cards.length * 55}px`
                      : "";
                }}
              >
                {el.order_item.split("%ITEMS_NAME_DIVIDER%").length == 3 ? (
                  <div className="tag_id_item_part3">
                    {el.order_item.split("%ITEMS_NAME_DIVIDER%").map((el) => (
                      <div>{el}</div>
                    ))}
                  </div>
                ) : el.order_item.split("%ITEMS_NAME_DIVIDER%").length == 2 ? (
                  <div className="tag_id_item_part2">
                    {el.order_item.split("%ITEMS_NAME_DIVIDER%").map((el) => (
                      <div>{el}</div>
                    ))}
                  </div>
                ) : (
                  <div className="tag_id_item_part2">{el.order_item}</div>
                )}
                <ArrowDown type="sm" />
                <div
                  className="tag_id_item_dropdown"
                  id={"tag_id_item_dropdown" + i}
                  active="false"
                >
                  {order_item_cards.map((el) => (
                    <div
                      onClick={() => {
                        handel_change_selectitem(el.item_name, i);
                        // setselect_item(el.item_name);
                      }}
                    >
                      {el.item_name.split("%ITEMS_NAME_DIVIDER%").length ==
                      3 ? (
                        <div className="tag_id_item_part3">
                          {el.item_name
                            .split("%ITEMS_NAME_DIVIDER%")
                            .map((el1) => (
                              <div>{el1}</div>
                            ))}
                        </div>
                      ) : (
                        <div className="tag_id_item_part2">
                          {el.item_name
                            .split("%ITEMS_NAME_DIVIDER%")
                            .map((el1) => (
                              <div>{el1}</div>
                            ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {/* <input type="text" value={el.order_item} readOnly /> */}
            </div>
            <div className="tag_delete_btn">
              <img
                src={tag_delete_icon}
                onClick={() => {
                  let temp_tag_ids = [...tag_ids];
                  temp_tag_ids.splice(i, 1);
                  settag_ids([...temp_tag_ids]);
                }}
              />
            </div>
          </div>
        ))}
        <div className="tag_id_add_img">
          <img
            src={tag_id_add_img}
            onClick={() => {
              settag_ids([
                ...tag_ids,
                { order_id: "", order_item: order_item_cards[0].item_name },
              ]);
            }}
          />
        </div>
      </div>
      {/* <div className="tag_id_body ">
        <div>
          <div className="tag_title">Tag ID #{tag_ids.length + 1}</div>
          <input
            type="text"
            value={tag_id_value}
            onChange={(e) => {
              settag_id_value(e.target.value);
            }}
          />
        </div>
        <div>
          <div className="tag_title">Select Item</div>
          <div
            className="tag_cust_select"
            onClick={() => {
              let tag_id_item_dropdown = document.getElementsByClassName(
                "tag_id_item_dropdown"
              )[0];
              tag_id_item_dropdown.setAttribute(
                "active",
                (tag_id_item_dropdown.style.height == "").toString()
              );
              tag_id_item_dropdown.style.height =
                tag_id_item_dropdown.style.height == ""
                  ? `${order_item_cards.length * 55}px`
                  : "";
            }}
          >
            {select_item.split("-").length == 3 ? (
              <div className="tag_id_item_part3">
                {select_item.split("-").map((el) => (
                  <div>{el}</div>
                ))}
              </div>
            ) : select_item.split("-").length == 2 ? (
              <div className="tag_id_item_part2">
                {select_item.split("-").map((el) => (
                  <div>{el}</div>
                ))}
              </div>
            ) : (
              select_item
            )}
            <div className="tag_id_item_dropdown" active="false">
              {order_item_cards.map((el) => (
                <div
                  onClick={() => {
                    setselect_item(el.item_name);
                  }}
                >
                  {el.item_name.split("-").length == 3 ? (
                    <div className="tag_id_item_part3">
                      {el.item_name.split("-").map((el1) => (
                        <div>{el1}</div>
                      ))}
                    </div>
                  ) : (
                    <div className="tag_id_item_part2">
                      {el.item_name.split("-").map((el1) => (
                        <div>{el1}</div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
       
      </div> */}
      <div className="tags_btn_group">
        <div
          className="btn"
          type="type2"
          onClick={() => setadd_order_current_showing("add_order_menu_items")}
        >
          Add another Item
        </div>
        <div
          className="btn"
          type="type2"
          onClick={() => {
            settag_ids([
              ...tag_ids.filter(
                (el) =>
                  el.order_id != "" && el.order_item != "Select One..." && el
              ),
            ]);
            // settag_ids([...tag_ids.filter=>])
            setadd_order_current_showing("add_order_menu_images");
          }}
        >
          Continue To Images
        </div>
      </div>
    </div>
  );
};
