import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { update_discount } from "../../API/API";
import { Close, Tick } from "../Common/Images/Images";
import { Switcher } from "../Switcher/Switcher";
import "./addDiscountModalStyle.scss";

export const AddDiscountModal = ({
  og_item_list,
  discount_list,
  setdiscount_list,
  setloader,
  close,
}) => {
  const current_store = useSelector((state) => state);

  const [save_btn, setsave_btn] = useState(false);
  const [category, setcategory] = useState({
    main: [],
    sub_main: [],
    items: [],
  });
  const [discount_data, setdiscount_data] = useState({
    // id: 1,
    discount_txt: "",
    is_active: true,
    is_deleted: false,
    is_for: {},
    amount: 0,
    is_percentage: false,
    desc: "",
  });
  useEffect(() => {
    if (discount_data.discount_txt !== "") {
      setsave_btn(true);
    } else {
      setsave_btn(false);
    }
  }, [discount_data]);

  return (
    <div className="adddiscountmodal">
      <div>
        <div className="header">
          <Close clickhandel={close} />
        </div>
        <div className="body">
          <div>
            <div className="title">Name</div>
            <div className="cust_inp">
              <input
                type="text"
                placeholder="Insert Name"
                value={discount_data.discount_txt}
                onChange={(e) =>
                  setdiscount_data((prev) => ({
                    ...prev,
                    discount_txt: e.target.value,
                  }))
                }
              />
            </div>
          </div>
          <div>
            <div className="title">Category</div>
            <div className="dropdown_group">
              <DiscountDropdown
                selected={category.main}
                setselected={(key) => {
                  setcategory({ main: key, sub_main: [], items: [] });
                }}
                arr={[
                  // {
                  //   key: -1,
                  //   value: "All",
                  // },
                  ...og_item_list
                    .filter((el) => el.delete_status !== 1)
                    .map((el) => ({ key: el.id, value: el.name })),
                ]}
              />
              {category.main.length == 1 ? (
                og_item_list.filter((el) => el.id === category.main[0])[0]
                  .has_child ? (
                  <>
                    <DiscountDropdown
                      selected={category.sub_main}
                      setselected={(key) => {
                        setcategory((prev) => ({
                          ...prev,
                          sub_main: key,
                          items: [],
                        }));
                      }}
                      arr={[
                        ...og_item_list.filter(
                          (el) => category.main[0] === el.id
                        )[0].items,
                      ].map((el) => ({ key: el.id, value: el.name }))}
                    />
                    {category.sub_main.length == 1 ? (
                      <DiscountDropdown
                        selected={category.items}
                        setselected={(key) => {
                          setcategory((prev) => ({
                            ...prev,
                            // sub_main: k,
                            items: key,
                          }));
                        }}
                        arr={[
                          ...og_item_list
                            .filter((el) => category.main[0] === el.id)[0]
                            .items.filter(
                              (el) => el.id === category.sub_main[0]
                            )[0]
                            .items.map((el) => ({
                              key: el.item_id,
                              value: el.name,
                            })),
                        ]}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <>
                    <DiscountDropdown
                      selected={category.items}
                      setselected={(key) => {
                        setcategory((prev) => ({
                          ...prev,
                          sub_main: [],
                          items: key,
                        }));
                      }}
                      arr={[
                        ...og_item_list.filter(
                          (el) => category.main[0] === el.id
                        )[0].items,
                      ].map((el) => ({ key: el.item_id, value: el.name }))}
                    />
                  </>
                )
              ) : (
                ""
              )}
              {/* {category.main.length ===1 ? (
                og_item_list.filter((el) => el.id === category.main[0])[0]
                  .has_child ? (
                  <>
                    <DiscountDropdown
                      selected={category.sub_main}
                      setselected={(key) => {
                        setcategory((prev) => ({
                          ...prev,
                          sub_main: key,
                          items: [],
                        }));
                      }}
                      arr={[
                        // {
                        //   key: -1,
                        //   value: "All",
                        // },
                        ...og_item_list
                          .filter((el) => el.id === category.main[0])[0]
                          .items // .filter((el) => el.delete_status !== 1)
                          .map((el) => ({ key: el.id, value: el.name })),
                      ]}
                    />
                    {category.sub_main.length === 1 ? (
                      <DiscountDropdown
                        selected={category.items}
                        setselected={(key) => {
                          setcategory((prev) => ({
                            ...prev,
                            items: key,
                          }));
                        }}
                        arr={[
                          // {
                          //   key: -1,
                          //   value: "All",
                          // },
                          ...[
                            ...og_item_list.filter(
                              (el) => el.id === category.main
                            )[0].items,
                          ]
                            .filter((el) => el.id === category.sub_main)[0]
                            .items // .filter((el) => el.delete_status !== 1)
                            .map((el) => ({ key: el.item_id, value: el.name })),
                        ]}
                      />
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  <DiscountDropdown
                    selected={category.items}
                    setselected={(key) => {
                      setcategory((prev) => ({ ...prev, items: key }));
                    }}
                    arr={[
                      // {
                      //   key: -1,
                      //   value: "All",
                      // },
                      ...og_item_list
                        .filter((el) => el.id === category.main)[0]
                        .items //   .filter((el) => el.delete_status !== 1)
                        .map((el) => ({ key: el.item_id, value: el.name })),
                    ]}
                  />
                )
              ) : (
                ""
              )} */}
            </div>
          </div>
          <div className="cust_inp_parent">
            <div className="title">Amount</div>
            <div className="cust_inp input_switcher">
              <div
                className="input_switcher_main"
                style={{
                  transform: `translateX(-${
                    discount_data.is_percentage ? 25 : 0
                  }px)`,
                }}
              >
                <div className="inp_icon">$</div>
                <input
                  style={
                    discount_data.is_percentage
                      ? {
                          padding: "0 10px",
                        }
                      : {}
                  }
                  type="number"
                  placeholder="Choose Amount"
                  value={discount_data.amount}
                  onChange={(e) => {
                    // if (e.target.value.split("").length === 0) return;
                    if (e.target.value.split("")[0] == 0) {
                      setdiscount_data((prev) => ({
                        ...prev,
                        amount: e.target.value.split("").splice(1)[0],
                      }));
                    } else {
                      setdiscount_data((prev) => ({
                        ...prev,
                        amount: e.target.value,
                      }));
                    }
                  }}
                />
                <div className="inp_icon">%</div>
              </div>
            </div>
            {/* <div
              className="input_switch"
              onClick={() =>
                setdiscount_data((prev) => ({
                  ...prev,
                  is_percentage: !prev.is_percentage,
                }))
              }
              >
              <div
              className={
                "input_switch_blob" +
                (discount_data.is_percentage ? " active" : "")
              }
              ></div>
            </div> */}
            <Switcher
              custClass="input_switch"
              active={discount_data.is_percentage}
              setactive={() =>
                setdiscount_data((prev) => ({
                  ...prev,
                  is_percentage: !prev.is_percentage,
                }))
              }
            />
          </div>
          <div>
            <div className="title">Description</div>
            <div className="cust_inp">
              <input
                type="text"
                placeholder="Insert Description"
                value={discount_data.desc}
                onChange={(e) => {
                  setdiscount_data((prev) => ({
                    ...prev,
                    desc: e.target.value,
                  }));
                }}
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <div
            className={"save_btn" + (save_btn ? " active" : "")}
            onClick={() => {
              if (!save_btn) return;
              update_discount(
                {
                  // type: 2,
                  data: JSON.stringify([
                    ...discount_list,
                    {
                      id: discount_list.length,
                      ...discount_data,
                      amount: discount_data.amount || 0,
                      is_for: {
                        ...category,
                      },
                    },
                  ]),
                },
                current_store.user.token,
                (data) => {
                  setdiscount_list((prev) => [
                    ...prev,
                    {
                      id: prev.length,
                      ...discount_data,
                      amount: discount_data.amount || 0,
                      is_for: {
                        ...category,
                      },
                    },
                  ]);
                  setloader(false);
                  close();
                }
              );
            }}
          >
            Save
          </div>
        </div>
      </div>
    </div>
  );
};
const DiscountDropdown = ({ selected, setselected, arr }) => {
  const [is_active, setis_active] = useState(false);
  const [values, setvalues] = useState({
    max_height: 0,
  });
  if (!arr || arr.length == 0) {
    return "err";
  }
  return (
    <div className="discount_dropdown" onClick={() => setis_active(true)}>
      <div className="discount_dropdown_default">
        {
          selected.length === 0 ? (
            <div className="default_items">All</div>
          ) : arr.filter((el) => selected.indexOf(el.key) !== -1).length ==
            1 ? (
            arr
              .filter((el) => selected.indexOf(el.key) !== -1)
              .map((el) => <div className="default_items">{el.value}</div>)
          ) : (
            <div className="default_items">
              {arr.filter((el) => selected.indexOf(el.key) !== -1).length +
                " selected"}
            </div>
          )
          // arr.filter((el) => el.key === selected[0])[0].value
        }
        <div
          className="discount_dropdown_arrow"
          active={is_active.toString()}
        ></div>
      </div>
      {is_active && (
        <div
          className="discount_dropdown_closer"
          onClick={(e) => {
            e.stopPropagation();
            setis_active(false);
          }}
        ></div>
      )}
      <div
        className="discount_dropdown_main"
        style={!is_active ? { height: 0 } : { height: arr.length * 30 }}
      >
        {arr.map((el) => (
          <div
            className="discount_dropdown_items"
            onClick={(e) => {
              e.stopPropagation();
              if (selected.indexOf(el.key) == -1) {
                setselected([...selected, el.key]);
              } else {
                let temp_selected = [...selected];
                temp_selected = temp_selected.filter((el1) => el1 !== el.key);
                setselected([...temp_selected]);
              }
            }}
          >
            <div className="txt">{el.value}</div>
            {selected.indexOf(el.key) !== -1 && (
              <div className="tick">
                <Tick style={{ height: 10, width: 10 }} />
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
// const Test = ({ arr }) => {
//   return "test";
// };
