import React, { useState } from "react";
import { useSelector } from "react-redux";
import { setitems } from "../../../API/API";
import { Close, Tick } from "../../Common/Images/Images";

export const ItemsAddModal = ({
  og_item_list,
  setog_item_list,
  cancel,
  setloader,
  item_list,
  setitem_list,
}) => {
  const current_store = useSelector((state) => state);

  const [item_name, setitem_name] = useState("");
  const [haschild_checkbox, sethaschild_checkbox] = useState(false);
  return (
    <div className="itemadd">
      <div>
        <div className="head">
          <Close clickhandel={cancel} />
        </div>
        <div className="body">
          <div className="title">Item Name</div>
          <input
            type="text"
            placeholder="Enter Item-Name"
            value={item_name}
            onChange={(e) => {
              setitem_name(e.target.value);
            }}
          />
          <div
            className="custoptionselect"
            onClick={() => {
              sethaschild_checkbox(!haschild_checkbox);
            }}
          >
            <div
              className={
                "checkbox " + (haschild_checkbox ? " checkbox_active" : "")
              }
            ></div>
            <div className="title">Will contain collections ?</div>
          </div>
        </div>

        <div className="footer">
          {/* <Tick
                        color={item_name.trim().length > 0 ? 3 : 0}
                        custStyle={(item_name.trim().length > 0) ? {} : { cursor: 'no-drop' }}
                        clickhandel={() => {
                            cancel();
                        }}
                    /> */}
          <div
            className="btn"
            type={item_name.trim().length > 0 ? "type2" : "type1"}
            onClick={() => {
              if (item_name.trim().length > 0) {
                setloader(true);
                let temp_arr = [...og_item_list];
                temp_arr.push({
                  has_child: haschild_checkbox,
                  id: temp_arr.length,
                  name: item_name,
                  special_cost: false,
                  items: [],
                  // 0 => not deleted
                  // 1 => soft delet
                  delete_status: 0,
                  // 0 => shown in client
                  // 1 => not shown in client
                  show_client_status: 0,
                });
                setitems(temp_arr, current_store.user.token, (data) => {
                  setog_item_list([...temp_arr]);
                  setloader(false);
                  cancel();
                });
              }
            }}
          >
            Add
          </div>
        </div>
      </div>
    </div>
  );
};
