export const og_faq = [
  {
    id: 0,
    que: [
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "WHAT'S THE DIFFERENCE BETWEEN WASHED & PRESSED AND DRY CLEANED FOR MEN'S SHIRTS?",
          },
        ],
      },
    ],
    ans: [
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "When it comes to men's shirts there are several differences in washing & pressing versus dry cleaning - which also drives the price.",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "When a men's shirt is washed & pressed; water is involved. The shirt is laundered with soap and water. Afterwards it is automatically pressed by a large machine.",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "When a men's shirt is dry cleaned, no water is involved. It is cleaned with a solvent that contains no water; then it is pressed by hand versus machine, allowing for greater care for the shirt.",
          },
        ],
      },
    ],
    links: [],
    is_deleted: false,
    links: [],
  },
  {
    id: 1,
    que: [
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "WHAT IS DRY CLEANING?",
          },
        ],
      },
    ],
    ans: [
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "Dry cleaning is very similar to doing regular laundry except for two things:",
          },
        ],
      },
      {
        type: "numbered-list",
        children: [
          {
            type: "list-item",
            align: "left",
            children: [
              {
                text: "No water is used",
              },
            ],
          },
          {
            type: "list-item",
            align: "left",
            children: [
              {
                text: "Each garment is pressed by hand afterwards",
              },
            ],
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: ' Regular laundry uses water and detergent to clean clothes. However; when clothes are dry cleaned, they are cleaned with a solvent that contains no water. Hence the term "dry clean". Once the clothes have been cleaned they are then hand pressed to remove wrinkles; checked over for finishing; then packaged for delivery or pickup.',
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "This is why dry cleaning is always more expensive than regular wash and fold. Each garment is carefully cared for and handled by multiple people.",
          },
        ],
      },
    ],
    links: [],
    is_deleted: false,
    links: [],
  },
  {
    id: 2,
    que: [
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "SO HOW DOES IT WORK?",
          },
        ],
      },
    ],
    ans: [
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "Register, and request your pickup, that's it!",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "Normally we pickup & deliver 7 days a week. However, with reduced hours due to Covid 19, we are currently only picking up and delivering Monday to Friday; 6PM-10PM.",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "After requesting your pickup, simply leave your clothes with your front desk or doorman. If you don't have a doorman, please only request the pickup if you'll be home between 6PM-10PM that evening so our driver can meet you.",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "Your clothes are then taken to our shoppe on the Upper West Side for processing and cleaning.",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "Regular wash and fold is returned back to you in 24 hours; and dry cleaning in returned in 48-72 hours.",
          },
        ],
      },
    ],
    links: [],
    is_deleted: false,
    links: [],
  },
  {
    id: 3,
    que: [
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "WHAT IS JULIETTE?",
          },
        ],
      },
    ],
    ans: [
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "We offer laundry & dry cleaning services. We pickup, clean and deliver your clothes so you can always look good 😉 But it's ",
          },
          {
            text: "how we do it",
            bold: true,
            italic: true,
          },
          {
            text: " that makes us special!",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "We meet the online and offline needs of the modern client. Clients can request a pickup by using our app, texting, or calling us. They can also pop by our shoppe on the Upper West Side for a friendly chat.",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "We believe that clients want the ease and efficiency of technology; coupled with good old customer service and attention. So that's what we offer.",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "Our app ensures that each order is cashless, consistent, and convenient. Clients can track the status of their order, view their order history, and receive email receipts. For any questions or concerns; we're just a text, call, or email away!",
          },
        ],
      },
    ],
    links: [],
    is_deleted: false,
    links: [
      {
        img: "msg",
        txt: "(315) 284-5689",
        href: "sms:3152845689",
      },
      {
        img: "call",
        txt: "(212) 877-9863",
        href: "tel:2128779863",
      },
      {
        img: "email",
        txt: " hello@juliettecleaners.com",
        href: "mailto: hello@juliettecleaners.com",
      },
    ],
  },
  {
    id: 4,
    que: [
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "normal",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "bold",
            bold: true,
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "italic",
            italic: true,
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "underline",
            underline: true,
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "bold-italic",
            italic: true,
            bold: true,
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "bold-underline",
            bold: true,
            underline: true,
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            italic: true,
            text: "italic-underline",
            underline: true,
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "code",
            code: true,
          },
        ],
      },
      {
        type: "block-quote",
        align: "left",
        children: [
          {
            text: "quote",
          },
        ],
      },
      {
        type: "strike-through",
        align: "left",
        children: [
          {
            text: "strikethrough",
          },
        ],
      },
      {
        type: "numbered-list",
        children: [
          {
            type: "list-item",
            align: "left",
            children: [
              {
                text: "ol 1",
              },
            ],
          },
          {
            type: "list-item",
            align: "left",
            children: [
              {
                text: "ol 2",
              },
            ],
          },
          {
            type: "list-item",
            align: "left",
            children: [
              {
                text: "ol 3",
              },
            ],
          },
        ],
      },
      {
        type: "bulleted-list",
        children: [
          {
            type: "list-item",
            align: "left",
            children: [
              {
                text: "ul1",
              },
            ],
          },
          {
            type: "list-item",
            align: "left",
            children: [
              {
                text: "ul 2",
              },
            ],
          },
          {
            type: "list-item",
            align: "left",
            children: [
              {
                text: "ul 3",
              },
            ],
          },
        ],
      },
      {
        type: "heading-1",
        align: "left",
        children: [
          {
            text: "heading 1",
          },
        ],
      },
      {
        type: "heading-2",
        align: "left",
        children: [
          {
            text: "heading 2",
          },
        ],
      },
      {
        type: "heading-3",
        align: "left",
        children: [
          {
            text: "heading 3",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "left align",
          },
        ],
      },
      {
        type: "paragraph",
        align: "right",
        children: [
          {
            text: "right align",
          },
        ],
      },
      {
        type: "paragraph",
        align: "center",
        children: [
          {
            text: "center align",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "leftzzz align content a asd asdasdasd asd asd asd as d asd asda sdas dasd asdasd as dasd asd",
          },
        ],
      },
      {
        type: "paragraph",
        align: "justify",
        children: [
          {
            text: "justify align content a asd asdasdasd asd asd asd as d asd asda sdas dasd asdasd as dasd asd",
          },
        ],
      },
    ],
    ans: [
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "ans",
          },
        ],
      },
      {
        type: "paragraph",
        align: "left",
        children: [
          {
            text: "",
          },
        ],
      },
    ],
    links: [
      {
        img: "",
        txt: "no img",
        href: "https://www.youtube.com",
      },
      {
        img: "email",
        txt: "with img",
        href: "https://www.youtube.com",
      },
    ],
    is_deleted: false,
  },
];
