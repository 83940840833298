import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { base_url, checktoken } from "../../API/API";
import { ReactComponent as Logo } from "../../img/logo.svg";
import { encrypt_data } from "../../RSA_Encryption/RSA";
import "./loginStyle.scss";

export const Login = ({ setshow_navbar, setloader }) => {
  const [login_btn, setlogin_btn] = useState(false);
  const [login_err, setlogin_err] = useState("");
  const [name, setname] = useState("");
  const [password, setpassword] = useState("");

  const navigate = useNavigate();
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {
    setshow_navbar(false);
    setloader(false);
  }, []);
  useEffect(() => {
    setlogin_btn(name != "" && password != "");
  }, [name, password]);
  useEffect(() => {
    let local_token = localStorage.getItem("jc_pos_token");
    if (local_token) {
      checktoken(local_token, (data) => {
        if (data.status) {
          dispatch({
            type: "login",
            payload: { token: local_token, admin: data.admin },
          });
          return navigate("/clients");
        }
      });
    }
  }, [current_store.user.token]);

  const handleClickLogin = () => {
    axios
      .post(`${base_url}login`, {
        email: name,
        password: encrypt_data(password),
      })
      .then((res) => res.data)
      .then((res) => {
        if (res.error) {
          if (
            ["Invalid Credentials", "User does not exist"].indexOf(
              res.error
            ) !== -1
          ) {
            return setlogin_err(res.error);
          }
          return navigate("/404");
        }
        localStorage.setItem("jc_pos_token", res.token);
        dispatch({
          type: "login",
          payload: { token: res.token, admin: res.is_admin },
        });
        navigate("/clients");
      });
  };
  return (
    <div className="login">
      <Logo />
      <div className="login_inputs">
        <input
          type="text"
          placeholder="User Name"
          // onkeyup ={(e)=>{
          //   if (e.key === 'Enter' || e.keyCode === 13) {
          //     // Do something
          // }
          // }}
          onChange={(e) => {
            setlogin_err("");
            setname(e.target.value);
          }}
        />
        <input
          type="password"
          placeholder="Password"
          onKeyDown={(e) => {
            if (login_btn && e.key === "Enter") {
              handleClickLogin();
            }
          }}
          onChange={(e) => {
            setlogin_err("");
            setpassword(e.target.value);
          }}
        />
      </div>
      <div
        className="btn login_btn"
        type={login_btn ? "type2" : "type1"}
        onClick={() => {
          login_btn && handleClickLogin();
        }}
      >
        Login
      </div>
      {login_err && (
        <div className={"error " + (login_err ? "show" : "")}>
          <div>{login_err}</div>
        </div>
      )}
    </div>
  );
};
