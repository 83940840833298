import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checktoken, getitems, getsubcategory, setitems } from "../../API/API";
import {
  Close,
  EnclosedPlus,
  Eye,
  Pencil,
  Plus,
  Tick,
  TickSwitcher,
} from "../../Component/Common/Images/Images";
import { pricelist_items } from "../../Component/dummydata";
import { DeleteModal } from "../../Component/Items/DeleteModal/DeleteModal";
import { ItemsAddModal } from "../../Component/Items/ItemsAddModal/ItemsAddModal";
import { ItemSubCategory } from "../../Component/Items/ItemSubCategory/ItemSubCategory";
import { ItemsViewEditModal } from "../../Component/Items/ItemsViewEditModal/ItemsViewEditModal";
import { Switcher } from "../../Component/Switcher/Switcher";
import "./itemsStyle.scss";

export const Items = ({ setshow_navbar, setloader }) => {
  const [og_item_list, setog_item_list] = useState([]);
  const [item_list, setitem_list] = useState([]);
  const [add_item, setadd_item] = useState(false);
  const [view_item, setview_item] = useState(false);
  const [deletemodal, setdeletemodal] = useState(false);
  const [show_deleted_items, setshow_deleted_items] = useState(true);
  // const [deletefunc, setdeletefunc] = useState(() => {
  // });
  const [to_show_itemspage, setto_show_itemspage] = useState(
    "items_category_page"
  );
  const current_store = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    let local_token = localStorage.getItem("jc_pos_token");
    if (local_token) {
      checktoken(local_token, (data) => {
        if (data.status) {
          dispatch({
            type: "login",
            payload: { token: local_token, admin: data.admin },
          });
          return navigate("/items");
        }
      });
    }
  }, [current_store.user.token]);
  useEffect(() => {
    setshow_navbar(true);
    setloader(true);
    getitems("", current_store.user.token, (data) => {
      setog_item_list([...data]);
      setloader(false);
    });
  }, []);
  useEffect(() => {
    setitem_list([
      ...og_item_list.map((el) => {
        return {
          // category_id: el.id,
          id: el.id,
          // item_category_name: el.name,
          name: el.name,
          delete_status: el.delete_status,
          show_client_status: el.show_client_status,
          is_addon: el.is_addon,
        };
      }),
      // ...og_item_list
      //   .map((el) => {
      //     return {
      //       // category_id: el.id,
      //       id: el.id,
      //       // item_category_name: el.name,
      //       name: el.name,
      //       delete_status: el.delete_status,
      //       show_client_status: el.show_client_status,
      //       is_addon: el.is_addon,
      //     };
      //   })
      //   .splice(0, 3),
      // ...og_item_list.map((el) => {
      //   return {
      //     // category_id: el.id,
      //     id: el.id,
      //     // item_category_name: el.name,
      //     name: el.name,
      //     delete_status: el.delete_status,
      //     show_client_status: el.show_client_status,
      //     is_addon: el.is_addon,
      //   };
      // }),
    ]);
  }, [og_item_list]);

  // subcategory_page - START
  const [current_item, setcurrent_item] = useState({});
  const [sub_category, setsub_category] = useState({});
  // subcategory_page - END
  return (
    <div
      className={
        "itemspage " +
        (to_show_itemspage == "items_category_page" ? " pt-0" : "")
      }
    >
      <div
        className="pagetitle"
        onClick={() => {
          setshow_deleted_items(!show_deleted_items);
        }}
      >
        {!show_deleted_items ? (
          <div fcolor="red">Items*</div>
        ) : (
          <div>Items</div>
        )}
      </div>

      <div
        className={
          "items_card_body" +
          (item_list.filter((el) => el.delete_status !== 1).length > 6 &&
          to_show_itemspage == "items_category_page"
            ? " columns_layout"
            : // ""
              "")
        }
        style={
          to_show_itemspage == "items_category_page"
            ? {
                display: "flex",
                justifyContent: "center",
              }
            : {}
        }
      >
        {to_show_itemspage == "items_category_page" ? (
          <>
            {item_list.map((el) => {
              if (el.delete_status === 1 && show_deleted_items) {
                return "";
              }
              return (
                <div
                  className="itemcards"
                  onClick={() => {
                    //   setloader(true);
                    //   getsubcategory(el.category_id, "", (data) => {
                    // setloader(false);
                    setto_show_itemspage("items_subcategory_page");

                    setsub_category(el.id);
                  }}
                >
                  {el.name}
                  <Pencil
                    edit={true}
                    type="m"
                    clickhandel={(e) => {
                      e.stopPropagation();
                      setcurrent_item({ ...el });
                      setview_item(true);
                      // viewedit
                    }}
                    custstyle={{
                      // border: "1px solid red",
                      width: 22,
                      height: 22,
                      position: "absolute",
                      top: 0,
                      bottom: 0,
                      right: "-40px",
                      margin: "auto",
                    }}
                  />
                  {/* <Pencil
                    edit={true}
                    type="m"
                    // clickhandel={(e) => {
                    //   e.stopPropagation();
                    //   setcurrent_item({ ...el });
                    //   setview_item(true);
                    //   // viewedit
                    // }}
                    custstyle={{
                      // border: "1px solid red",
                      width: 22,
                      height: 22,
                      position: "absolute",
                      top: 12,
                      right: "-70px",
                      margin: "auto",
                    }}
                  /> */}
                  {el.is_addon && el.is_addon === 1 ? (
                    <Tick
                      type="m"
                      clickhandel={(e) => {
                        e.stopPropagation();
                        let temp_items = [...og_item_list];
                        temp_items = temp_items.map((el1) => {
                          if (el1.id == el.id) {
                            return {
                              ...el1,
                              is_addon: el1.show_client_status === 1 ? 0 : 1,
                            };
                          }
                          return el1;
                        });
                        setloader(true);
                        setitems(
                          temp_items,
                          current_store.user.token,
                          (data) => {
                            // setitem_list([...temp_items]);
                            setog_item_list([...temp_items]);
                            setloader(false);
                            // cancel();
                          }
                        );
                      }}
                      color={"3"}
                      style={{
                        // border: "1px solid red",
                        position: "absolute",
                        // top: 0,
                        top: 0,
                        bottom: 0,
                        right: "-70px",
                        margin: "auto",
                        border: "1px solid var(--svg_current_color)",
                        height: 22,
                        width: 22,
                        padding: 5,
                        borderRadius: "var( --border-radius-6)",
                      }}
                    />
                  ) : (
                    <Close
                      style={{
                        position: "absolute",
                        top: 0,
                        bottom: 0,
                        right: "-70px",
                        margin: "auto",
                        "--svg_current_color": "var(--red)",
                        border: "1px solid var(--svg_current_color)",
                        height: 22,
                        width: 22,
                        padding: 5,
                        borderRadius: "var( --border-radius-6)",
                      }}
                      clickhandel={(e) => {
                        e.stopPropagation();

                        let temp_items = [...og_item_list];
                        temp_items = temp_items.map((el1) => {
                          if (el1.id == el.id) {
                            return {
                              ...el1,
                              // name: item_name,
                              is_addon: el1.is_addon === 1 ? 0 : 1,
                            };
                          }
                          return el1;
                        });
                        setloader(true);
                        setitems(
                          temp_items,
                          current_store.user.token,
                          (data) => {
                            // setitem_list([...temp_items]);
                            setog_item_list([...temp_items]);
                            setloader(false);
                            // cancel();
                          }
                        );
                      }}
                    />
                  )}
                  {/* {el.show_client_status === 1 ? (
                    <Close
                    style={{
                      position: "absolute",
                      bottom: 12,
                      right: "-40px",
                      margin: "auto",
                        "--svg_current_color": "var(--red)",
                        border: "1px solid var(--svg_current_color)",
                        height: 25,
                        width: 25,
                        padding: 5,
                        borderRadius: "var( --border-radius-6)",
                      }}
                      clickhandel={(e) => {
                        e.stopPropagation();
                      
                        let temp_items = [...og_item_list];
                        temp_items = temp_items.map((el1) => {
                          if (el1.id == el.id) {
                            return {
                              ...el1,
                              // name: item_name,
                              show_client_status:
                                el1.show_client_status === 1 ? 0 : 1,
                            };
                          }
                          return el1;
                        });
                        setloader(true);
                        setitems(
                          temp_items,
                          current_store.user.token,
                          (data) => {
                            // setitem_list([...temp_items]);
                            setog_item_list([...temp_items]);
                            setloader(false);
                            // cancel();
                          }
                        );
                      }}
                    />
                  ) : (
                    <> */}
                  {/* <Tick
                        type="m"
                        clickhandel={(e) => {
                          e.stopPropagation();
                          let temp_items = [...og_item_list];
                          temp_items = temp_items.map((el1) => {
                            if (el1.id == el.id) {
                              return {
                                ...el1,
                                show_client_status:
                                  el1.show_client_status === 1 ? 0 : 1,
                              };
                            }
                            return el1;
                          });
                          setloader(true);
                          setitems(
                            temp_items,
                            current_store.user.token,
                            (data) => {
                              // setitem_list([...temp_items]);
                              setog_item_list([...temp_items]);
                              setloader(false);
                              // cancel();
                            }
                          );
                        }}
                        color={"3"}
                        style={{
                          // border: "1px solid red",
                          position: "absolute",
                          // top: 0,
                          bottom: 12,
                          right: "-40px",
                          margin: "auto",
                          border: "1px solid var(--svg_current_color)",
                          height: 25,
                          width: 25,
                          padding: 5,
                          borderRadius: "var( --border-radius-6)",
                        }}
                      /> */}
                  <div
                    style={{
                      position: "absolute",
                      right: "-105px",
                      top: 0,
                      bottom: 0,
                      margin: "auto",
                      width: 25,
                      height: 22,
                    }}
                  >
                    <div
                      className={el.show_client_status !== 1 ? "" : "eye_off"}
                      style={{
                        width: 25,
                        height: 22,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Eye
                        style={{
                          width: 25,
                          height: 22,
                        }}
                        clickhandel={(e) => {
                          e.stopPropagation();
                          let temp_items = [...og_item_list];
                          temp_items = temp_items.map((el1) => {
                            if (el1.id == el.id) {
                              return {
                                ...el1,
                                show_client_status:
                                  el1.show_client_status === 1 ? 0 : 1,
                              };
                            }
                            return el1;
                          });
                          setloader(true);
                          setitems(
                            temp_items,
                            current_store.user.token,
                            (data) => {
                              // setitem_list([...temp_items]);
                              setog_item_list([...temp_items]);
                              setloader(false);
                              // cancel();
                            }
                          );
                        }}
                      />
                    </div>
                    {/* <Switcher
                      active={el.show_client_status !== 1}
                      setactive={(e) => {
                        e.stopPropagation();
                        let temp_items = [...og_item_list];
                        temp_items = temp_items.map((el1) => {
                          if (el1.id == el.id) {
                            return {
                              ...el1,
                              show_client_status:
                                el1.show_client_status === 1 ? 0 : 1,
                            };
                          }
                          return el1;
                        });
                        setloader(true);
                        setitems(
                          temp_items,
                          current_store.user.token,
                          (data) => {
                            // setitem_list([...temp_items]);
                            setog_item_list([...temp_items]);
                            setloader(false);
                            // cancel();
                          }
                        );
                      }}
                      style={{
                        height: 21,
                        width: 50,
                      }}
                    /> */}
                  </div>
                  {/* </>
                  )} */}
                </div>
              );
            })}
            {view_item && (
              <div className="itemcards view_card">
                <ItemsViewEditModal
                  current_item={current_item}
                  og_item_list={og_item_list}
                  cancel={() => {
                    setview_item(false);
                  }}
                  setitem_list={setitem_list}
                  item_list={item_list}
                  setdeletemodal={setdeletemodal}
                />
              </div>
            )}
            {add_item ? (
              <div className="itemcards add_card">
                <ItemsAddModal
                  setog_item_list={setog_item_list}
                  setloader={setloader}
                  og_item_list={og_item_list}
                  cancel={() => {
                    setadd_item(false);
                  }}
                  setitem_list={setitem_list}
                  item_list={item_list}
                />
                <Plus white={true} />
              </div>
            ) : (
              <div
                className="itemcards add_card"
                onClick={() => {
                  setadd_item(true);
                }}
              >
                <Plus white={true} />
              </div>
            )}
          </>
        ) : to_show_itemspage == "items_subcategory_page" ? (
          <ItemSubCategory
            setloader={setloader}
            show_deleted_items={show_deleted_items}
            og_item_list={og_item_list}
            setog_item_list={setog_item_list}
            selected_sub_category={sub_category}
            add_subcategory={() => {}}
            cancel={() => {
              setto_show_itemspage("items_category_page");
            }}
          />
        ) : (
          <>404</>
        )}
      </div>
      {to_show_itemspage == "items_category_page" && (
        <div className="items_note">
          <div className="note">Note:</div>
          <div>
            <div className="items_note_flex">
              <div className="tick_group">
                <div
                  className={""}
                  style={{
                    width: 25,
                    height: 22,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Eye
                    style={{
                      width: 25,
                      height: 22,
                    }}
                  />
                </div>
                <div>visible to client</div>
                <div>, </div>
                <div
                  className={"eye_off"}
                  style={{
                    width: 25,
                    height: 22,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Eye
                    style={{
                      width: 25,
                      height: 22,
                    }}
                  />
                </div>
                <div>hidden to client</div>
              </div>
            </div>
            <div className="items_note_flex">
              <div className="tick_group">
                <Tick
                  type="m"
                  color="3"
                  style={{
                    border: "1px solid var(--svg_current_color)",
                    height: 20,
                    width: 20,
                    padding: 5,
                    borderRadius: "var( --border-radius-6)",
                  }}
                />
                <div>use as modifier</div>
                <div>, </div>
                <Close
                  style={{
                    "--svg_current_color": "var(--red)",
                    border: "1px solid var(--svg_current_color)",
                    height: 22,
                    width: 22,
                    padding: 5,
                    borderRadius: "var( --border-radius-6)",
                  }}
                />
                <div>use as product / item</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {deletemodal && (
        // || !true
        <DeleteModal
          close={() => {
            setdeletemodal(false);
          }}
          deletefunc={() => {
            setloader(true);
            let temp_og = [...og_item_list];
            let resetted_og = [
              ...temp_og
                // .filter((el) => el.id !== current_item.category_id)
                .map((el) => {
                  if (el.id == current_item.id) {
                    return {
                      ...el,
                      delete_status: 1,
                    };
                  }
                  return el;
                }),
            ];
            setitems(resetted_og, current_store.user.token, (data) => {
              setdeletemodal(false);
              setview_item(false);
              setog_item_list([...resetted_og]);
              setloader(false);
            });
          }}
        />
      )}
    </div>
  );
};
