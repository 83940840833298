import React from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { addclientaddress } from "../../../API/API";
import { Close } from "../../Common/Images/Images";

export const EditClientAddAddressModal = ({
  seteditclient_add_address_modal,
  settemp_address_arr,
  temp_address_arr,
  address_arr,
  setaddress_arr,
  setloader,
  temp_data,
  settemp_data,
}) => {
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();
  return (
    <div className="editclient_add_address_modal">
      <div>
        <div className="heading">
          <Close
            clickhandel={() => {
              seteditclient_add_address_modal(false);
              settemp_address_arr(["", "", ""]);
            }}
          />
        </div>
        <div className="body">
          <div className="title">Address (opt.)</div>
          <input
            type="text"
            placeholder="Insert Address"
            id="add1"
            value={temp_address_arr[0]}
            onChange={(e) => {
              let temp_arr = [...temp_address_arr];
              temp_arr[0] = e.target.value;
              settemp_address_arr([...temp_arr]);
            }}
          />
          <input
            type="text"
            placeholder="Insert Apt"
            id="add2"
            value={temp_address_arr[1]}
            onChange={(e) => {
              let temp_arr = [...temp_address_arr];
              temp_arr[1] = e.target.value;
              settemp_address_arr([...temp_arr]);
            }}
          />
          <input
            type="number"
            placeholder="Insert Zip Code"
            id="add3"
            inp_error={(
              temp_address_arr[2] !== "" &&
              temp_address_arr[2].length != 5 &&
              temp_address_arr[2].length != 6
            ).toString()}
            value={temp_address_arr[2]}
            onChange={(e) => {
              let temp_arr = [...temp_address_arr];
              temp_arr[2] = e.target.value;
              settemp_address_arr([...temp_arr]);
            }}
          />
        </div>
        <div className="footer">
          <div
            className="btn "
            type={
              temp_address_arr[0] == "" ||
              temp_address_arr[1] == "" ||
              temp_address_arr[2] == "" ||
              (temp_address_arr[2] !== "" &&
                temp_address_arr[2].length != 5 &&
                temp_address_arr[2].length != 6)
                ? "type1"
                : "type2"
            }
            onClick={() => {
              if (
                temp_address_arr[0] == "" ||
                temp_address_arr[1] == "" ||
                temp_address_arr[2] == ""
              )
                return;
              setloader(true);
              addclientaddress(
                {
                  address1: temp_address_arr[0],
                  apt: temp_address_arr[1],
                  zipcode: temp_address_arr[2],
                  doorman: "0",
                  is_default: "0",
                  client_id: temp_data.id,
                },
                current_store.user.token,
                (data) => {
                  setloader(false);
                  if (!data.status) {
                    return navigate("/404");
                  }
                  settemp_data({
                    ...data.data,
                    phone: data.data.mobile,
                  });
                  settemp_address_arr(["", "", ""]);
                  seteditclient_add_address_modal(false);
                }
              );
            }}
          >
            Add
          </div>
        </div>
      </div>
    </div>
  );
};
