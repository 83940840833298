import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { add_vendor_report, base64tolink } from "../../../../API/API";
import { Camera, Close } from "../../../Common/Images/Images";
// import { addcashlist, base64tolink } from "../../../API/API";
// import { Camera, Close } from "../../Common/Images/Images";
import "./vendorcashvieweditmodal.scss";

export const VendorCashInOutView = ({ type, close, setloader, data }) => {
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();
  const [currentdatefull, setcurrentdatefull] = useState("");
  const [currentdatetime, setcurrentdatetime] = useState([,]);
  const [receipt, setreceipt] = useState("");
  const [name, setname] = useState("");
  const [amount, setamount] = useState("");
  const [description, setdescription] = useState("");

  useEffect(() => {
    let tempcurrent = new Date();
    // "Sat Nov 26 2022 20:01:00 GMT+0530 (India Standard Time)"
    setcurrentdatefull(tempcurrent);
    let [date, month, year] = [
      tempcurrent.getDate(),
      tempcurrent.getMonth() + 1,
      tempcurrent.getFullYear(),
    ];
    let [hrs, mins, unit] = [
      tempcurrent.getHours(),
      tempcurrent.getMinutes(),
      "AM",
    ];
    if (date <= 9) {
      date = `0${date}`;
    }
    if (month <= 9) {
      month = `0${month}`;
    }
    if (hrs > 12) {
      hrs = `${hrs - 12}`;
      unit = "PM";
    }
    if (hrs <= 9) {
      hrs = `0${hrs}`;
    }
    if (mins <= 9) {
      mins = `0${mins}`;
    }
    // console.log(`${date}/${month}/${year}`, `${hrs}:${mins} ${unit}`);
    setcurrentdatetime([`${date}/${month}/${year}`, `${hrs}:${mins} ${unit}`]);
  }, []);

  const addcash = () => {
    if (amount == "") {
      return;
    }
    add_vendor_report(
      {
        vendor_id: data.id,
        img: receipt,
        amount: amount * (type == "cashout" ? -1 : 1),
        description: description,
        dateTime: new Date(),
      },
      current_store.user.token,
      (data) => {
        // console.log(data);
        if (!data.status) {
          return navigate("/404");
        }
        close();
        window.location.reload();
        return;
      }
    );
  };
  return (
    <div className="vendorcashinoutmodal">
      <div>
        <div className="header">
          <Close clickhandel={close} />
        </div>
        <div className="body">
          <div className="left_body">
            <div className="current_date_time">
              <div>{currentdatetime[0]}</div>
              <div>{currentdatetime[1]}</div>
            </div>
            <input
              type="text"
              placeholder="Full Name"
              value={data.name}
              // onChange={(e) => {
              //   setname(e.target.value);
              // }}
              readOnly
            />
            <div
              className={
                "amount" + (type == "cashout" ? " amount_red" : " amount_green")
              }
            >
              {amount != "" && (
                <div>
                  {type == "cashin" ? "+" : type == "cashout" ? "-" : ""}
                  &nbsp;$&nbsp;
                </div>
              )}
              <input
                id="amount_inp"
                style={amount != "" ? { textAlign: "left" } : { padding: 0 }}
                // style={{ width: "0", padding: "0" }}
                type="number"
                placeholder="Payment Amount"
                valu={amount}
                onChange={(e) => {
                  setamount(e.target.value);
                }}
              />
            </div>
            <textarea
              rows={Math.floor(description.length / 21) + 1}
              type="text"
              placeholder="Notes"
              value={description}
              onChange={(e) => {
                setdescription(e.target.value);
              }}
            />

            <label
              className="receipt"
              style={receipt ? { borderColor: "var(--black)" } : {}}
            >
              <Camera />
              Receipt
              <input
                type="file"
                accept=".jpeg,.png"
                onChange={(e) => {
                  var reader = new FileReader();
                  setloader(true);
                  reader.readAsDataURL(e.target.files[0]);
                  reader.onload = function () {
                    // temp_arr.push(reader.result)
                    base64tolink(
                      {
                        base_64: reader.result.split(",")[1],
                        name: `vendorcash_img_${new Date()
                          .valueOf()
                          .toString()}.jpeg`,
                      },
                      current_store.user.token,
                      (data) => {
                        if (!data.status) {
                          setloader(false);
                          return navigate("/");
                        }
                        setloader(false);
                        setreceipt(data.link + "?" + new Date());
                        setTimeout(() => {
                          let receipt_img =
                            document.getElementById("receipt_img1");
                          let receipt_img_rect =
                            receipt_img.getBoundingClientRect();
                          if (
                            receipt_img_rect.height > receipt_img_rect.width
                          ) {
                            receipt_img.style.height = "100%";
                            receipt_img.style.width = "auto";
                          } else {
                            receipt_img.style.width = "100%";
                            receipt_img.style.height = "auto";
                          }
                        }, 100);
                      }
                    );
                  };
                }}
              />
            </label>
            {receipt && (
              <div
                style={{
                  height: "250px",
                  textAlign: "center",
                  border: "1px solid var(--black)",
                  borderBottomLeftRadius: "var(--border-radius-4)",
                  borderBottomRightRadius: "var(--border-radius-4)",
                  overflow: "hidden",
                  margin: "-4px 0 0 0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={receipt} id="receipt_img1" />
              </div>
            )}
            {type == "cashin" ? (
              <div
                className="cashinout_btn btn"
                type={
                  amount != ""
                    ? //    &&
                      //   description != "" &&
                      //   receipt != ""
                      "type2"
                    : "type1"
                }
                style={{
                  fontSize: "14px",
                  lineHeight: "18px",
                }}
                onClick={addcash}
              >
                Add Cash In
              </div>
            ) : type == "cashout" ? (
              <div
                className="cashinout_btn btn"
                style={{
                  fontSize: "14px",
                  lineHeight: "18px",
                }}
                type={
                  amount != ""
                    ? //   &&
                      //   description != "" &&
                      //   receipt != ""
                      "type2"
                    : "type1"
                }
                onClick={addcash}
              >
                Add Cash Out
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
