import React from "react";
import "./tableStyle.scss";

export const Table = ({
  cust_class,
  type,
  table_head_data,
  table_body_data,
}) => {
  return (
    <div className={cust_class} table={type}>
      <div className="table_heading">
        {
          (type == 'type2') ?
            <>
              <div className="column1">{table_head_data[0]}</div>
              <div className="child_row">
                {table_head_data.map((el1, i) => {

                  if (i == 0 || i == (table_head_data.length - 1)) return <></>;
                  return <>
                    <div className={"column" + (i + 1)}>{el1}</div>
                  </>
                }
                )
                }
              </div>
              <div className={"column" + table_head_data.length}>{table_head_data[table_head_data.length - 1]}</div>
            </>

            :
            table_head_data.map((el, i) => (
              <div className={"column" + (i + 1)}>{el}</div>
            ))
        }
      </div>
      <div className="table_body">
        {table_body_data.map((el) => {
          if (el.length == 0) return <></>;
          if (type == 'type2') {
            return <div className="row">
              <div className="column1">{el[0]}</div>
              <div className="child_row">
                {el.map((el1, i) => {

                  if (i == 0 || i == (el.length - 1)) return <></>;
                  return <>
                    <div className={"column" + (i + 1)}>{el1}</div>
                  </>
                }
                )
                }
              </div>
              <div className={"column" + el.length}>{el[el.length - 1]}</div>
            </div>
          }
          return (
            <div className="row">
              {el.map((el1, i) => (
                <>
                  <div className={"column" + (i + 1)}>{el1}</div>
                </>
              ))
              }
            </div>
          )
        }
        )}
      </div>
    </div >
  );
};
