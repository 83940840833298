import React from "react";
import { ReactComponent as BoldIcon } from "./RTE_icons/bold.svg";
import { ReactComponent as ItalicIcon } from "./RTE_icons/italic.svg";
import { ReactComponent as UnderlineIcon } from "./RTE_icons/underline.svg";
import { ReactComponent as CodeIcon } from "./RTE_icons/code.svg";
import { ReactComponent as H1Icon } from "./RTE_icons/heading/h1.svg";
import { ReactComponent as H2Icon } from "./RTE_icons/heading/h2.svg";
import { ReactComponent as H3Icon } from "./RTE_icons/heading/h3.svg";
import { ReactComponent as H4Icon } from "./RTE_icons/heading/h4.svg";
import { ReactComponent as H5Icon } from "./RTE_icons/heading/h5.svg";
import { ReactComponent as H6Icon } from "./RTE_icons/heading/h6.svg";
import { ReactComponent as AlignLeft } from "./RTE_icons/align/left.svg";
import { ReactComponent as AlignCenter } from "./RTE_icons/align/center.svg";
import { ReactComponent as AlignRight } from "./RTE_icons/align/right.svg";
import { ReactComponent as AlignJustify } from "./RTE_icons/align/justify.svg";
import { ReactComponent as QuoteIcon } from "./RTE_icons/quote.svg";
import { ReactComponent as OlListIcon } from "./RTE_icons/ulList.svg";
import { ReactComponent as UlListIcon } from "./RTE_icons/olList.svg";
import { ReactComponent as StrikeIcon } from "./RTE_icons/strike.svg";

export const RTE_Icons = ({ icon, active }) => {
  switch (icon) {
    case "bold":
      return (
        <BoldIcon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "italic":
      return (
        <ItalicIcon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "underline":
      return (
        <UnderlineIcon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "code":
      return (
        <CodeIcon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "h1":
      return (
        <H1Icon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "h2":
      return (
        <H2Icon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "h3":
      return (
        <H3Icon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "h4":
      return (
        <H4Icon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "h5":
      return (
        <H5Icon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "h6":
      return (
        <H6Icon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "left":
      return (
        <AlignLeft
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "center":
      return (
        <AlignCenter
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "right":
      return (
        <AlignRight
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "justify":
      return (
        <AlignJustify
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "quote":
      return (
        <QuoteIcon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "list_numbered":
      return (
        <UlListIcon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "list_bulleted":
      return (
        <OlListIcon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );
    case "strike":
      return (
        <StrikeIcon
          style={{
            "--current_svg_color": !active ? "var(--modal-bg)" : "var(--black)",
          }}
        />
      );

    default:
      return <div>{icon}</div>;
      break;
  }
  return <div>RTE_Icons</div>;
};
