const default_user = {
  token: "",
  admin: false,
};
export const user = {
  ...default_user,
};

const User = (state = user, { type, payload }) => {
  switch (type) {
    case "login":
      return {
        ...state,
        token: payload.token,
        admin: payload.admin,
        // admin: false,
      };
    case "logout":
      return { ...state, ...default_user };
    default:
      return state;
  }
};
export default User;
