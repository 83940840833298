import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addclientcard } from "../../../API/API";
import { encrypt_data } from "../../../RSA_Encryption/RSA";
import { Close } from "../../Common/Images/Images";
const month_count = [
  [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
  [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
];

export const EditClientAddCreditcardModal = ({
  seteditclient_add_creditcard_modal,
  temp_ccdatamodal_arr,
  settemp_ccdatamodal_arr,
  // cc_arr,
  // setcc_arr,
  temp_data,
  settemp_data,
  current_showing_client_data,
  setcurrent_showing_client_data,
}) => {
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();

  const [modal_cc_date, setmodal_cc_date] = useState("");
  const [modal_cc_date_err, setmodal_cc_date_err] = useState("");

  const [modal_cc_no, setmodal_cc_no] = useState("");
  const [modal_cc_no_err, setmodal_cc_no_err] = useState("");

  const [modal_cc_cvv, setmodal_cc_cvv] = useState("");
  const [modal_cc_cvv_err, setmodal_cc_cvv_err] = useState("");

  const [api_card_err, setapi_card_err] = useState("");

  const set_value_cardno = (current) => {
    // checks for a number as a input
    if (Number(current.value.substr(-1)) == current.value.substr(-1)) {
      // checks for the length of value to be less than 20
      if (current.value.replaceAll(" ", "").length >= 20) {
        return;
      }
      if (current.value.replaceAll(" ", "").length < 20) {
        setmodal_cc_no_err(
          (
            [15, 16, 19].indexOf(current.value.replaceAll(" ", "").length) == -1
          ).toString()
        );
        // puts spaces between 4 digits eg. 1234 1234 1234 1234
        if (
          [5, 10, 15, 20].indexOf(current.value.length) > -1 &&
          modal_cc_no.length <= current.value.length
        ) {
          setmodal_cc_no(modal_cc_no + " " + current.value.slice(-1));
        } else {
          if (
            [5, 10, 15, 20].indexOf(current.value.length) > -1 &&
            current.value.slice(-1) == " "
          ) {
            return setmodal_cc_no(current.value.trim());
          }
          setmodal_cc_no(current.value);
        }
      }
    }
  };
  const set_value_carddate = (current) => {
    // checks for a number as a input and also if cheks for while backspacing for '' and '/' if dsabled you wouldnt be able to backspace in card month
    if (
      Number(current.value.substr(-1)) == current.value.substr(-1) ||
      current.value.substr(-1) == " " ||
      current.value.substr(-1) == "/"
    ) {
      if (current.value.replaceAll("/", "").replaceAll(" ", "").length < 5) {
        if (current.value.replaceAll("/", "").replaceAll(" ", "").length != 4) {
          setmodal_cc_date_err("true");
        } else {
          let entered_date = new Date(
            `20${current.value.split("/")[1].trim()}-${current.value
              .split("/")[0]
              .trim()}-${
              month_count[
                (2000 + current.value.split("/")[1].trim()) % 4 == 0 ? 1 : 0
              ][Number(current.value.split("/")[0].trim())]
            }T00:00:00`
          );
          let current_date = new Date(
            `${new Date().getFullYear()}-${
              new Date().getMonth() < 9 ? "0" : ""
            }${new Date().getMonth() + 1}-${
              new Date().getDate() < 10 ? "0" : ""
            }${new Date().getDate()}T00:00:00`
          );
          setmodal_cc_date_err((entered_date - current_date <= 0).toString());
        }
        let data = "";
        // adds a ' / ' etween two numbers and doesnot lets user to enter the first number greater than 12 if it is then changes eg. 13 =  01 / 3
        if (
          current.value.length == 3 &&
          modal_cc_date.length + 1 <= current.value.length
        ) {
          setmodal_cc_date(modal_cc_date + " / " + current.value.slice(-1));
          data = current.value.slice(0, 2) + "/" + current.value.slice(-1);
        } else {
          if (current.value > 12) {
            let date_arr = current.value.split("");
            setmodal_cc_date("0" + date_arr[0] + " / " + date_arr[1]);
            data = "0" + date_arr[0] + " / " + date_arr[1];
          } else {
            if (current.value.length == 5 && current.value.substr(-1) == " ") {
              return setmodal_cc_date(current.value.slice(0, 2));
            }
            setmodal_cc_date(current.value);
            data = current.value;
          }
        }
      }
    }
  };
  const set_value_cardcvv = (current) => {
    if (current.value > 99) {
      setmodal_cc_cvv_err("false");
    } else {
      setmodal_cc_cvv_err("true");
    }
    if (current.value <= 9999) {
      setmodal_cc_cvv(current.value);
    }
  };
  return (
    <div className="editclient_add_address_modal">
      <div>
        <div className="heading">
          <Close
            clickhandel={() => {
              seteditclient_add_creditcard_modal(false);
              settemp_ccdatamodal_arr(["", "", ""]);
            }}
          />
        </div>
        <div className="body">
          <div className="title">Credit card (opt.)</div>
          <input
            autocomplete="off"
            type="text"
            placeholder="Insert Card Number"
            id="add2"
            value={modal_cc_no}
            inp_error={modal_cc_no_err.toString()}
            onChange={(e) => {
              set_value_cardno(e.target);
              setapi_card_err("");
            }}
          />
          <input
            autocomplete="off"
            type="text"
            placeholder="Insert Month / Year"
            id="add1"
            value={modal_cc_date}
            inp_error={modal_cc_date_err.toString()}
            onChange={(e) => {
              set_value_carddate(e.target);
              setapi_card_err("");
            }}
          />
          <input
            autocomplete="off"
            type="text"
            placeholder="Insert Cvv"
            id="add3"
            value={modal_cc_cvv}
            inp_error={modal_cc_cvv_err.toString()}
            onChange={(e) => {
              set_value_cardcvv(e.target);
              setapi_card_err("");
            }}
          />
        </div>
        {api_card_err && <div style={{ color: "red" }}>{api_card_err}</div>}
        <div className="footer">
          <div
            className="btn "
            type={
              modal_cc_no_err.toString() != "false" ||
              modal_cc_date_err.toString() != "false" ||
              modal_cc_cvv_err.toString() != "false"
                ? "type1"
                : "type2"
            }
            onClick={() => {
              if (
                modal_cc_no_err.toString() != "false" ||
                modal_cc_date_err.toString() != "false" ||
                modal_cc_cvv_err.toString() != "false"
              )
                return;
              addclientcard(
                {
                  card_name: encrypt_data(temp_data.name),
                  card_number: encrypt_data(modal_cc_no.split(" ").join("")),
                  card_expiry_month: encrypt_data(
                    modal_cc_date.split("/")[0].trim()
                  ),
                  card_expiry_year: encrypt_data(
                    modal_cc_date.split("/")[1].trim()
                  ),
                  card_cvc: encrypt_data(modal_cc_cvv),
                  primary: temp_data.cards.length == 0 ? "1" : "0",
                  client_id: temp_data.id,
                  // "address1": temp_address_arr[0],
                  // "apt": temp_address_arr[1],
                  // "zipcode": temp_address_arr[2],
                  // "doorman": "0",
                  // "is_default": "0",
                  // "client_id": temp_data.id
                },
                current_store.user.token,
                (data) => {
                  // setloader(false)
                  if (!data.status) {
                    return navigate("/404");
                  }
                  if (data.error == "Invalid Card") {
                    setmodal_cc_no("");
                    setmodal_cc_date("");
                    setmodal_cc_cvv("");
                    setapi_card_err("Invalid Card");
                    return;
                  }
                  settemp_data({
                    // ...data.data,
                    // phone: data.data.mobile,
                    id: data.data.id,
                    img:
                      data.data.profile_photo_link == null ||
                      data.data.profile_photo_link ==
                        "https://julietteimages.s3.amazonaws.com/profileimages/default.png"
                        ? "https://julietteimages.s3.amazonaws.com/profileimages/default.png?1656920271059"
                        : data.data.profile_photo_link,
                    name: data.data.full_name,
                    phone: data.data.mobile,
                    payment: data.data.cc_last_4_digits || "",
                    last_order: data.data.order_date
                      ? data.data.order_date.split("T")[0]
                      : "",
                    email: data.data.email,
                    cards: data.data.cards,
                  });
                  setcurrent_showing_client_data({
                    ...current_showing_client_data,
                    cards: data.data.cards,
                  });
                  // settemp_address_arr(['', '', ''])
                  // seteditclient_add_address_modal(false);

                  settemp_ccdatamodal_arr(["", "", ""]);
                  seteditclient_add_creditcard_modal(false);
                }
              );
              // let temp = modal_cc_no.slice(-4)
              // setcc_arr(
              //     [
              //         ...cc_arr,
              //         temp
              //     ]
              // );
            }}
          >
            Add
          </div>
        </div>
      </div>
    </div>
  );
};
