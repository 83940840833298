import React, { useEffect, useState } from "react";
import { order_status, pay_status } from "../../dummydata";
import "./ordersfiltermodalStyle.scss";
import { CustDropdown } from "../../Custom/CustDropdown/CustDropdown";
import { CustDatePicker } from "../../Custom/CustDatePicker/CustDatePicker";
import { Close } from "../../Common/Images/Images";
import Calendar from "../../../custpackages/react-date-range/dist/components/Calendar";
import "../../../custpackages/react-date-range/dist/styles.css";
import "../../../custpackages/react-date-range/dist/theme/default.css";
export const OrdersFilterModal = ({
  setshow_filter_modal,
  filter_options,
  setfiler_options,
  order_statuses,
  type,
  pricelist,
}) => {
  const [tempfilter_options, settempfilter_options] = useState({
    addfilter: false,
    paid: [false, false],
    status: "",
    date: "",
    category: 0,
    sub_category: 0,
    item: 0,
  });
  const [orderfilteradd, setorderfilteradd] = useState(false);
  const handelClickOnModal = (e) => {
    // if (!filter_options.addfilter) {
    //   setfiler_options({
    //     addfilter: false,
    //     paid: [false, false],
    //     status: "",
    //     date: "",
    //   })
    // }
    if (e.target.getAttribute("closemodal") == "yes") {
      setshow_filter_modal(false);
    }
  };
  const handelCurrentActive = (which) => {
    settempfilter_options({
      ...tempfilter_options,
      current_active: tempfilter_options.current_active == which ? 0 : which,
    });
    // if (which) {
    //   settempfilter_options({
    //     ...tempfilter_options,
    //     paid: [!tempfilter_options.paid[0], false],
    //   });
    // } else {
    //   settempfilter_options({
    //     ...tempfilter_options,
    //     paid: [false, !tempfilter_options.paid[1]],
    //   });
    // }
  };

  useEffect(() => {
    // if (!filter_options.addfilter) {
    //   setfiler_options({
    //     addfilter: false,
    //     paid: [false, false],
    //     status: "",
    //     date: "",
    //   })
    // }
    settempfilter_options({ ...filter_options });
  }, []);
  useEffect(() => {
    if (
      tempfilter_options.current_active != filter_options.current_active ||
      tempfilter_options.status !== filter_options.status ||
      tempfilter_options.date !== filter_options.date ||
      tempfilter_options.category !== filter_options.category ||
      tempfilter_options.sub_category !== filter_options.sub_category ||
      tempfilter_options.item !== filter_options.item
    ) {
      setorderfilteradd(true);
    } else {
      setorderfilteradd(false);
    }
  }, [tempfilter_options]);

  return (
    <div
      className="order_filter_modal"
      closemodal="yes"
      onClick={handelClickOnModal}
      id="order_filter_modal"
    >
      <div className="filter_modal">
        <div className="filter_modal_header">
          <Close
            clickhandel={() => {
              setshow_filter_modal(false);
            }}
          />
        </div>
        <div
          className="filter_modal_body"
          onClick={(e) => {
            // if (e.target.className != "default_show") {
            //   document.getElementById("status_dropdown_selection").style = "";
            // }
          }}
        >
          <div className="paid_unpaid_toggeler">
            <label className="input_custom" htmlFor="paid_checkbox">
              <input
                type="checkbox"
                id="paid_checkbox"
                onChange={() => handelCurrentActive("1")}
                checked={tempfilter_options.current_active == "1"}
              />
              <div className="checkbox_custom"></div>
              <div className="txt">CANCELLED ORDER</div>
            </label>
            <br />
            <label className="input_custom" htmlFor="unpaid_checkbox">
              <input
                type="checkbox"
                id="unpaid_checkbox"
                onChange={() => handelCurrentActive("2")}
                checked={tempfilter_options.current_active == "2"}
              />
              <div className="checkbox_custom"></div>
              <div className="txt">COMPLETED ORDER</div>
            </label>
          </div>
          {tempfilter_options.current_active == 0 && (
            <div className="status_selector">
              <div className="title">Status</div>
              <CustDropdown
                custid="cust1"
                value={tempfilter_options.status}
                defaultplaceholder="Choose Status"
                dropdown_arr={[
                  ...order_statuses.map((el, i) => {
                    return { key: i, value: el };
                  }),
                ]}
                setvalue={(data) => {
                  settempfilter_options({
                    ...tempfilter_options,
                    status: data,
                  });
                }}
              />
            </div>
          )}
          <div className="date_selector">
            <div className="title">Date</div>
            {/* <Calendar /> */}
            <CustDatePicker
              defaultplaceholder="Choose Date"
              value={tempfilter_options.date}
              custid="cust2"
              setvalue={(data) => {
                settempfilter_options({ ...tempfilter_options, date: data });
              }}
            />
          </div>
          {type === "report" && (
            <>
              <div className="date_selector">
                <div className="title">Order Category</div>
                {/* <Calendar /> */}
                <CustDropdown
                  custid="cust2"
                  value={tempfilter_options.category}
                  defaultplaceholder="Choose Status"
                  dropdown_arr={[
                    {
                      key: 0,
                      value: "All",
                    },
                    ...pricelist
                      .filter((el) => el.is_addon !== 1)
                      .map((el, i) => {
                        return { key: i + 1, value: el.title };
                      }),
                  ]}
                  setvalue={(data) => {
                    settempfilter_options({
                      ...tempfilter_options,
                      category: data,
                      sub_category: 0,
                      item: 0,
                    });
                  }}
                />
              </div>
              {pricelist.filter((el) => el.is_addon !== 1).length !== 0 &&
              tempfilter_options.category !== 0 &&
              pricelist
                .filter((el) => el.is_addon !== 1)
                .filter((el, i) => i === tempfilter_options.category - 1)[0]
                .haschild ? (
                <>
                  <div className="date_selector">
                    <div className="title">Order Sub-Category</div>
                    {/* <Calendar /> */}
                    <CustDropdown
                      custid="cust3"
                      value={tempfilter_options.sub_category}
                      defaultplaceholder="Choose Status"
                      dropdown_arr={[
                        {
                          key: 0,
                          value: "All",
                        },
                        ...pricelist
                          .filter((el) => el.is_addon !== 1)
                          .filter(
                            (el, i) => i === tempfilter_options.category - 1
                          )[0]
                          .child.map((el, i) => {
                            return { key: i + 1, value: el.acc_title };
                          }),
                      ]}
                      setvalue={(data) => {
                        settempfilter_options({
                          ...tempfilter_options,
                          sub_category: data,
                          item: 0,
                        });
                      }}
                    />
                  </div>
                  {tempfilter_options.sub_category !== 0 && (
                    // pricelist
                    //   .filter((el) => el.is_addon !== 1)
                    //   .filter((el, i) => i === 0)[0].child[0].acc_body.length >
                    //   0 &&
                    <div className="date_selector">
                      <div className="title">Order Item</div>
                      <CustDropdown
                        custid="cust4"
                        value={tempfilter_options.item}
                        defaultplaceholder="Choose Status"
                        dropdown_arr={[
                          {
                            key: 0,
                            value: "All",
                          },
                          ...pricelist
                            .filter((el) => el.is_addon !== 1)
                            .filter(
                              (el, i) => i === tempfilter_options.category - 1
                            )[0]
                            .child[
                              tempfilter_options.sub_category - 1
                            ].acc_body.map((el, i) => {
                              return {
                                key: i + 1,
                                value: el[0],
                              };
                            }),
                        ]}
                        setvalue={(data) => {
                          settempfilter_options({
                            ...tempfilter_options,
                            item: data,
                          });
                        }}
                      />
                    </div>
                  )}
                </>
              ) : (
                // false &&
                tempfilter_options.category !== 0 && (
                  <div className="date_selector">
                    <div className="title">Order Item</div>
                    <CustDropdown
                      custid="cust5"
                      value={tempfilter_options.item}
                      defaultplaceholder="Choose Items"
                      dropdown_arr={[
                        {
                          key: 0,
                          value: "All",
                        },
                        ...pricelist
                          .filter((el) => el.is_addon !== 1)
                          .filter(
                            (el, i) => i + 1 === tempfilter_options.category
                          )[0]
                          .acc_body.map((el, i) => ({
                            key: i + 1,
                            value: el[0],
                          })),
                      ]}
                      setvalue={(data) => {
                        settempfilter_options({
                          ...tempfilter_options,
                          item: data,
                        });
                      }}
                    />
                  </div>
                )
              )}
            </>
          )}
          {/* <div className="order_selector" style={{ visibility: "hidden" }}>
            <div className="title">Order Type</div>
            <CustDropdown
              // tempclose={true}
              custid="cust3"
              // value={tempfilter_options.status}
              defaultplaceholder="Choose Order Type"
              dropdown_arr={[
                ...order_status.map((el, i) => {
                  return { key: i, value: el };
                }),
              ]}
            />
            <CustDropdown
              // tempclose={true}
              custid="cust4"
              defaultplaceholder="Choose Order Type"
              dropdown_arr={[
                ...order_status.map((el, i) => {
                  return { key: i, value: el };
                }),
              ]}
            />
          </div> */}
        </div>
        <div className="filter_modal_footer">
          <div
            className="btn"
            type={orderfilteradd ? "type2" : "type1"}
            onClick={() => {
              if (!orderfilteradd) {
                return;
              }
              let temp_obj = {
                ...tempfilter_options,
              };
              if (tempfilter_options.current_active != 0) {
                temp_obj.status = 0;
              }
              setfiler_options({ addfilter: true, ...temp_obj });
              setshow_filter_modal(false);
            }}
          >
            Add Filter
          </div>
        </div>
      </div>
    </div>
  );
};
