import React, { useEffect, useLayoutEffect, useState } from "react";
import "./ordersStyle.scss";
import CountUp from "react-countup";

import { Cards } from "../../Component/Common/Cards/Cards";

import filter_img from "../../img/orders/filter.png";
import search_img from "../../img/orders/search.png";
import edit_order_table_item from "../../img/orders/eye.png";
import close_icon from "../../img/icons/white/x_icon.svg";

import {
  order_status,
  order_table,
  pay_status,
} from "../../Component/dummydata";
import { OrdersFilterModal } from "../../Component/Orders/FilterModal/OrdersFilterModal";
import { Table } from "../../Component/Common/Table/Table";
import { AddOrder } from "../AddOrder/AddOrder";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddOrderModal } from "../../Component/Orders/AddOrderModal/AddOrderModal";
import { Close } from "../../Component/Common/Images/Images";
import { SearchAndFilterBar } from "../../Component/Common/SearchAndFilterBar/SearchAndFilterBar";
import { TempDraftOrdersTable } from "../TempDraftOrdersTable/TempDraftOrdersTable";
import { useDispatch, useSelector } from "react-redux";
import { checktoken, getitems } from "../../API/API";
// const status = [
//   0  "waiting for pickup",
//   1  "ready for pickup",
//   2  "picked up",
//   3  "in cleaning",
//   4  "out for delivery",
//   5  'delivered'
//       ];
// const pay_status = [
//   0  "PAID",
//   1  "UNPAID",
//   2  "FAILED"];

const order_statuses = [
  "All",
  "Pickup Requested",
  "Driver Enroute to Pickup",
  "Pickup Failed",
  "Picked Up",
  "Customer Charged",
  "Deliver Clothes",
  "Driver Enroute to Dropoff",
  "Delivery Failed",
  "Order Cancelled",
  "Order Completed",
];
const order_statuses_filtered = order_statuses.filter(
  (el) => ["Order Cancelled", "Order Completed"].indexOf(el) == -1
);

export const TempOrders = ({
  setshow_navbar,
  setdata,
  setview_order,
  setloader,
  type,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [orderpage_data, setorderpage_data] = useState([0, 0, 0, 0, 0, 0, 0]);
  const current_store = useSelector((state) => state);
  const [pricelist, setpricelist] = useState([]);
  const [filter_category, setfilter_category] = useState("");
  const [filter_category_name, setfilter_category_name] = useState("");
  useEffect(() => {
    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("jc_pos_token");
      if (local_token) {
        setloader(true);
        checktoken(local_token, (data) => {
          if (!data.status) {
            return navigate("/");
          }
          dispatch({
            type: "login",
            payload: { token: local_token, admin: data.admin },
          });
          setloader(false);
        });
        return;
      } else {
        return navigate("/");
      }
    } else {
      // return navigate("/");
    }
    return () => {
      setorderpage_data([0, 0, 0, 0, 0, 0, 0]);
    };
  }, []);

  useEffect(() => {
    setshow_navbar(true);
    if (current_store.user.token !== "" && type == "report") {
      getitems({}, current_store.user.token, (data) => {
        let temp_arr = [
          ...data
            .filter(
              (el) => el.delete_status !== 1
              // && el.show_client_status !== 1
            )
            .map((el) => {
              if (el.has_child) {
                return {
                  title: el.name,
                  haschild: el.has_child,
                  is_addon: el.is_addon,
                  child: [
                    ...el.items
                      .filter(
                        (el) => el.delete_status !== 1
                        //  && el.show_client_status !== 1
                      )
                      .map((el1) => {
                        return {
                          acc_title: el1.name,
                          acc_body: [
                            ...el1.items
                              .filter((el) => el.delete_status !== 1)
                              .map((el) => {
                                return [
                                  el.name,
                                  Number(el.cost).toFixed(2).toString(),
                                ];
                              }),
                          ],
                        };
                      }),
                  ],
                };
              }
              if (el.special_cost) {
                return {
                  title: el.name,
                  haschild: false,
                  special_cost: true,
                  is_addon: el.is_addon,
                  acc_body: [
                    ...el.items
                      .filter((el) => el.delete_status !== 1)
                      .map((el1) => {
                        return [
                          el1.name,
                          `${Number(el1.cost.min_cost)
                            .toFixed(2)
                            .toString()} for ${el1.cost.min_weight}lb`,
                          `${Number(el1.cost.per_cost).toFixed(2)} per lb`,
                        ];
                      }),
                  ],
                };
              }
              return {
                title: el.name,
                haschild: false,
                is_addon: el.is_addon,
                acc_body: [
                  ...el.items
                    .filter((el) => el.delete_status !== 1)
                    .map((el1) => {
                      return [el1.name, Number(el1.cost).toFixed(2).toString()];
                    }),
                ],
                // ...el,
              };
            }),
        ];
        setpricelist([...temp_arr]);

        setloader(false);
      });
    }
  }, [current_store, type]);
  const [add_order_modal, setadd_order_modal] = useState(false);
  // const [add_order_data, setadd_order_data] = useState({})
  //   useEffect(() => {}, []);
  const [show_filter_modal, setshow_filter_modal] = useState(false);
  const [search, setsearch] = useState("");
  const [clientmini_data, setclientmini_data] = useState([]);

  const [filter_options, setfiler_options] = useState({
    // addfilter: false,
    current_active: 0,
    status: 0,
    date: "",
    category: 0,
    sub_category: 0,
    item: 0,
  });

  useEffect(() => {
    if (filter_options.category !== 0) {
      let temp_current_pricelist = pricelist
        .filter((el) => el.is_addon !== 1)
        .filter((el, i) => {
          return i === filter_options.category - 1;
        });
      if (temp_current_pricelist.length > 0) {
        setfilter_category(
          temp_current_pricelist[0].title +
            " > " +
            (temp_current_pricelist[0].haschild
              ? filter_options.sub_category === 0
                ? ""
                : temp_current_pricelist[0].child[
                    filter_options.sub_category - 1
                  ].acc_title +
                  " > " +
                  (filter_options.item === 0
                    ? ""
                    : temp_current_pricelist[0].child[
                        filter_options.sub_category - 1
                      ].acc_body[filter_options.item - 1][0])
              : // temp_current_pricelist[0].child[filter_options.sub_category][0]
              //     .acc_title +
              //     " > " +
              //     temp_current_pricelist[0].child[filter_options.sub_category][0]
              //       .acc_body[filter_options.item][0]
              filter_options.item === 0
              ? ""
              : temp_current_pricelist[0].acc_body[filter_options.item - 1][0])
        );
        setfilter_category_name(
          temp_current_pricelist[0].title +
            " > " +
            (temp_current_pricelist[0].haschild
              ? filter_options.sub_category === 0
                ? "All"
                : temp_current_pricelist[0].child[
                    filter_options.sub_category - 1
                  ].acc_title +
                  " > " +
                  (filter_options.item === 0
                    ? "All"
                    : temp_current_pricelist[0].child[
                        filter_options.sub_category - 1
                      ].acc_body[filter_options.item - 1][0])
              : filter_options.item === 0
              ? "All"
              : temp_current_pricelist[0].acc_body[filter_options.item - 1][0])
        );
      } else {
        setfilter_category("");
        setfilter_category_name("");
      }
    } else {
      setfilter_category("");
      setfilter_category_name("");
    }
  }, [filter_options, pricelist, current_store]);

  return (
    <>
      {/* {
        !add_order_modal ? */}
      <div className="orders">
        <div className="pagetitle">
          <div> {type == "report" ? "Reports" : "Orders"}</div>
        </div>
        <div className="order_body">
          <Cards
            card_arr={
              type === "report"
                ? [
                    {
                      title: "Total revenue orders",
                      value: (
                        <>
                          {orderpage_data[0] ? "$" : "-"}
                          {orderpage_data[0] ? (
                            <CountUp end={Number(orderpage_data[0])} />
                          ) : (
                            ""
                          )}
                        </>
                      ),
                    },
                    {
                      title: "Unpaid orders",
                      value: orderpage_data[3] ? (
                        <CountUp end={Number(orderpage_data[3])} />
                      ) : (
                        "-"
                      ),
                    },
                    {
                      title: "Orders Today",
                      value: orderpage_data[4] ? (
                        <CountUp end={Number(orderpage_data[4])} />
                      ) : (
                        "-"
                      ),
                    },
                    {
                      title: "New Client's This Month",
                      value: orderpage_data[5] ? (
                        <CountUp end={Number(orderpage_data[5])} />
                      ) : (
                        "-"
                      ),
                    },
                  ]
                : [
                    {
                      title: "Total revenue orders",
                      value: (
                        <>
                          {orderpage_data[0] ? "$" : "-"}
                          {orderpage_data[0] ? (
                            <CountUp end={Number(orderpage_data[0])} />
                          ) : (
                            ""
                          )}
                        </>
                      ),
                    },
                    {
                      title: "orders in progress",
                      value: orderpage_data[1] ? (
                        <CountUp end={Number(orderpage_data[1])} />
                      ) : (
                        "-"
                      ),
                    },
                    {
                      title: "waiting for pickup",
                      value: orderpage_data[2] ? (
                        <CountUp end={Number(orderpage_data[2])} />
                      ) : (
                        "-"
                      ),
                    },
                  ]
            }
            last_card={
              type === "report"
                ? ""
                : {
                    title: "Order",
                    click: () => {
                      setadd_order_modal(true);
                    },
                  }
            }
          />
          <div className="order_table_body">
            <SearchAndFilterBar
              placeholder="Search for Number, Date, Name, Notes,..."
              change_search={search}
              handlechange_search={setsearch}
              handleclick_filter={setshow_filter_modal}
              // type={type}
            />
            {/* <div className="search_and_filter_bar">
              <div className="search_input">
                <img src={search_img} />
                <input placeholder="Search for Number, Date, Name, Notes,..." />
              </div>
              <div className="filter_img_div">
                <img
                  src={filter_img}
                  onClick={() => setshow_filter_modal(true)}
                />
              </div>
            </div> */}
            {/* { */}
            <div className="filter_btn_group">
              {/* {filter_options.current_active == "0" && (
                <div className="filter_card">
                  <div>Orders</div>
                  <Close
                    white={true}
                    type="m"
                    clickhandel={() => {
                      setfiler_options({
                        ...filter_options,
                        // current_active:0,
                      });
                    }}
                  />
                </div>
              )} */}
              {filter_options.current_active == "1" && (
                <div className="filter_card">
                  <div>CANCELLED ORDER</div>
                  <Close
                    white={true}
                    type="m"
                    clickhandel={() => {
                      setfiler_options({
                        ...filter_options,
                        current_active: 0,
                      });
                    }}
                  />
                </div>
              )}
              {filter_options.current_active == "2" && (
                <div className="filter_card">
                  <div>COMPLETED ORDER</div>
                  <Close
                    white={true}
                    type="m"
                    clickhandel={() => {
                      setfiler_options({
                        ...filter_options,
                        current_active: 0,
                      });
                    }}
                  />
                </div>
              )}
              {filter_options.status !== "" && filter_options.status !== 0 && (
                <div className="filter_card">
                  <div>{order_statuses[filter_options.status]}</div>
                  <Close
                    white={true}
                    type="m"
                    clickhandel={() => {
                      setfiler_options({
                        ...filter_options,
                        status: 0,
                      });
                    }}
                  />
                </div>
              )}
              {filter_options.date !== "" && (
                <div className="filter_card">
                  <div>{filter_options.date}</div>
                  <Close
                    white={true}
                    type="m"
                    clickhandel={() => {
                      setfiler_options({
                        ...filter_options,
                        date: "",
                      });
                    }}
                  />
                </div>
              )}
              {type === "report" && filter_options.category !== 0 && (
                <div className="filter_card">
                  {/* <div> */}
                  <CategoryFilter
                    txt={filter_category_name}
                    setfiler_options={setfiler_options}
                  />
                  {/* {() => filter_category_name.split(" > ")} test */}
                  {/* {filter_category} */}
                  {/* {filter_options.category}
                    {filter_options.sub_category}
                    {filter_options.item} */}
                  {/* </div> */}
                  {/* <Close
                    white={true}
                    type="m"
                    clickhandel={() => {
                      setfiler_options({
                        ...filter_options,
                        category: 0,
                        sub_category: 0,
                        item: 0,
                      });
                    }}
                  /> */}
                </div>
              )}
            </div>

            <TempDraftOrdersTable
              current_active={filter_options.current_active}
              filter_category={filter_category}
              setdata={setdata}
              setshow_navbar={setshow_navbar}
              order_statuses={order_statuses}
              status={filter_options.status}
              filter_date={filter_options.date}
              setview_order={setview_order}
              setloader={setloader}
              setorderpage_data={setorderpage_data}
              setclientmini_data={setclientmini_data}
              search={search}
              type={type}
            />
            {/* <Table
              cust_class={
                "order_table " +
                (filter_options.paid[0] != false ||
                filter_options.paid[1] != false ||
                filter_options.status !== "" ||
                filter_options.date !== ""
                  ? " order_table_mini "
                  : "")
              }
              type="type1"
              table_head_data={[
                "NAME",
                "PAYMENT",
                "STATUS",
                "PAYMENT STATUS",
                "ORDER Created",
              ]}
              table_body_data={[
                ...order_table.map((el, i) => {
                  if (!el.add_order_data) return [];
                  return [
                    el.name,
                    <>
                      {el.payment == "" ? (
                        <div fcolor="red">no CC in file</div>
                      ) : (
                        `**** **** ${el.payment}`
                      )}
                    </>,
                    order_status[el.status],
                    <div fcolor={el.pay_status == 0 ? "green" : "red"}>
                      {pay_status[el.pay_status]}
                    </div>,
                    <>
                      {new Date(el.order_created)
                        .toString()
                        .split(" ")
                        .splice(1, 3)
                        .join(" ")}
                      <img
                        className="table_action"
                        src={edit_order_table_item}
                        onClick={() => {
                          setview_order(el);
                          navigate("/view-order");
                        }}
                      />
                    </>,
                  ];
                }),
              ]}
            /> */}
          </div>
        </div>
        {show_filter_modal && (
          <OrdersFilterModal
            setshow_filter_modal={setshow_filter_modal}
            filter_options={filter_options}
            setfiler_options={setfiler_options}
            order_statuses={order_statuses_filtered}
            type={type}
            pricelist={pricelist}
          />
        )}
      </div>
      {add_order_modal && type !== "report" && (
        <AddOrderModal
          setdata={setdata}
          setadd_order_modal={setadd_order_modal}
          clientmini_data={clientmini_data}
          setloader={setloader}
        />
      )}
    </>
  );
};

const CategoryFilter = ({ txt, setfiler_options }) => {
  let temp_txt_arr = txt.split(" > ");
  if (temp_txt_arr.length == 3) {
    return (
      <div
        style={{
          height: "100%",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            columnGap: 10,
          }}
        >
          {temp_txt_arr[0]}

          <div
            style={{
              height: "calc(100% - 10px)",
              // border: "1px solid var(--black)",
              borderRadius: 10,
              padding: "0px 10px",
              display: "flex",
              alignItems: "center",
              columnGap: 10,
              background: "var(--white)",
              color: "var(--black)",
            }}
          >
            {temp_txt_arr[1]}
            {temp_txt_arr[2].toLowerCase() !== "all" && (
              <div
                style={{
                  height: "calc(100% - 5px)",
                  // border: "1px solid var(--black)",
                  borderRadius: 10,
                  padding: "0px 10px",
                  display: "flex",
                  alignItems: "center",
                  columnGap: 10,
                  background: "var(--black)",
                  color: "var(--white)",
                }}
              >
                {temp_txt_arr[2]}
                {temp_txt_arr[2].toLowerCase() !== "all" && (
                  <Close
                    style={{ margin: 0, "--svg_current_color": "#fff" }}
                    type="m"
                    clickhandel={() => {
                      setfiler_options((prev) => ({
                        ...prev,
                        item: 0,
                      }));
                    }}
                  />
                )}
              </div>
            )}
            <Close
              style={{ margin: 0, "--svg_current_color": "#000" }}
              type="m"
              clickhandel={() => {
                setfiler_options((prev) => ({
                  ...prev,
                  sub_category: 0,
                  item: 0,
                }));
              }}
            />
          </div>
          <Close
            style={{ margin: 0, "--svg_current_color": "#fff" }}
            white={true}
            type="m"
            clickhandel={() => {
              setfiler_options((prev) => ({
                ...prev,
                category: 0,
                sub_category: 0,
                item: 0,
              }));
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          columnGap: 10,
        }}
      >
        {temp_txt_arr[0]}
        {temp_txt_arr[1]?.toLowerCase() !== "all" && (
          <div
            style={{
              height: "calc(100% - 10px)",
              // border: "1px solid var(--black)",
              borderRadius: 10,
              padding: "0px 10px",
              display: "flex",
              alignItems: "center",
              columnGap: 10,
              background: "var(--white)",
              color: "var(--black)",
            }}
          >
            {temp_txt_arr[1]}
            {temp_txt_arr[1]?.toLowerCase() !== "all" && (
              <Close
                style={{ margin: 0, "--svg_current_color": "#000" }}
                type="m"
                clickhandel={() => {
                  setfiler_options((prev) => ({
                    ...prev,
                    sub_category: 0,
                    item: 0,
                  }));
                }}
              />
            )}
          </div>
        )}
        <Close
          style={{ margin: 0, "--svg_current_color": "#fff" }}
          white={true}
          type="m"
          clickhandel={() => {
            setfiler_options((prev) => ({
              ...prev,
              category: 0,
              sub_category: 0,
              item: 0,
            }));
          }}
        />
      </div>
    </div>
  );
};
