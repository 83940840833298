import React, { useState } from "react";
import { Close } from "../../../Component/Common/Images/Images";
import "./tempcancelmodalStyle.scss";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { base_url } from "../../../API/API";

export const TempCancelModal = ({ cancel, order_selected }) => {
  const current_store = useSelector((state) => state);

  return (
    <div
      className="tempcancelmodal"
      id="close_modal"
      onClick={(e) => {
        e.target.id == "close_modal" && cancel();
      }}
    >
      <div>
        <div className="body">
          <div>
            Are you sure you want
            <br /> to
            <strong>&nbsp; delete &nbsp;</strong>
            this order?
          </div>
          <div
            className="btn"
            type="type2"
            onClick={() => {
              axios
                .post(
                  `${base_url}cancel-order`,
                  {
                    order_id: order_selected.order_id,
                  },
                  {
                    headers: {
                      Authorization: current_store.user.token,
                    },
                  }
                )
                .then((res) => res.data)
                .then((res) => {
                  if (res.error) {
                    // setapi_error(res.error)
                    return;
                  }
                  window.location.reload();
                  cancel();
                });
            }}
          >
            Confirm
          </div>
          <div className="btn" type="type2" onClick={cancel}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};
