import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { edit_vendors, mobile_convert_display } from "../../../API/API";
import {
  Close,
  Eye,
  MenuDots,
  Pencil,
  Trash,
} from "../../Common/Images/Images";
import { Table } from "../../Common/Table/Table";
import { VendorCashDelete } from "./VendorCashDelete/VendorCashDelete";
import {
  CashInOutView,
  VendorCashInOutView,
} from "./VendorCashInOutView/VendorCashInOutView";
import { VendorCashInOutViewEdit } from "./VendorCashInOutViewEdit/VendorCashInOutViewEdit";
import "./vendortablesidepanelStyle.scss";

export const VendorTableSidepanel = ({
  vendor_reports,
  selected_vendor,
  setloader,
  vendor_sidepanel_nav_to,
  setvendor_sidepanel_nav_to,
  setvendor_table,
  setvendor_table_mini,
  setreport_data_after_edit,
}) => {
  // useEffect(() => {
  //   // setvendor_sidepanel_nav_to("view");
  // }, [selected_vendor]);

  return (
    <div className="vendortablesidepanel">
      <VendorNav
        // setvendor_sidepanel_nav_to={setvendor_sidepanel_nav_to}
        // vendor_sidepanel_nav_to={vendor_sidepanel_nav_to}
        {...{ vendor_sidepanel_nav_to, setvendor_sidepanel_nav_to }}
      />
      {
        [
          {
            is: "view",
            show: (
              <VendorView
                setloader={setloader}
                vendor_data={selected_vendor}
                type="view"
                setvendor_sidepanel_nav_to={setvendor_sidepanel_nav_to}
              />
            ),
          },
          {
            is: "edit",
            show: (
              <VendorView
                setvendor_table_mini={setvendor_table_mini}
                setvendor_table={setvendor_table}
                setloader={setloader}
                vendor_data={selected_vendor}
                type="edit"
                setvendor_sidepanel_nav_to={setvendor_sidepanel_nav_to}
              />
            ),
          },
          {
            is: "cash",
            show: (
              <VendorCash
                setloader={setloader}
                vendor_data={selected_vendor}
                type="edit"
                vendor_reports={vendor_reports}
                setreport_data_after_edit={setreport_data_after_edit}
              />
            ),
          },
        ].filter((el) => el.is === vendor_sidepanel_nav_to)[0]?.show
      }
    </div>
  );
};
const VendorNav = ({ setvendor_sidepanel_nav_to, vendor_sidepanel_nav_to }) => {
  return (
    <div className="vendor_nav">
      {[
        vendor_sidepanel_nav_to === "edit"
          ? {
              title: "Edit",
              is: (is) => ["edit"].includes(is),
              handel: () => setvendor_sidepanel_nav_to("view"),
            }
          : {
              title: "View",
              is: (is) => ["view"].includes(is),
              handel: () => setvendor_sidepanel_nav_to("view"),
            },
        {
          title: "Cash",
          is: (is) => ["cash"].includes(is),
          handel: () => setvendor_sidepanel_nav_to("cash"),
        },
      ].map((el) => (
        <div
          style={{
            color: el.is(vendor_sidepanel_nav_to) ? "var(--black)" : "",
          }}
          onClick={() => el.handel()}
        >
          {el.title}
        </div>
      ))}
    </div>
  );
};

const VendorView = ({
  vendor_data,
  type,
  setvendor_sidepanel_nav_to,
  setloader,
  setvendor_table,
  setvendor_table_mini,
}) => {
  const [current_vendor_data, setcurrent_vendor_data] = useState({
    name: vendor_data.name,
    add: vendor_data.add,
    mobile: vendor_data.mobile,
    note: vendor_data.note,
  });
  const navigate = useNavigate();
  const current_store = useSelector((state) => state);
  useEffect(() => {
    setcurrent_vendor_data({
      name: vendor_data.name,
      add: vendor_data.add,
      mobile: vendor_data.mobile,
      note: vendor_data.note,
    });
  }, [vendor_data]);

  const [save_btn, setsave_btn] = useState(false);
  useEffect(() => {
    if (
      current_vendor_data.name !== "" &&
      current_vendor_data.add !== "" &&
      current_vendor_data.mobile !== ""
      //  &&
      // current_vendor_data.note !== ""
    ) {
      if (
        current_vendor_data.name !== vendor_data.name ||
        current_vendor_data.add !== vendor_data.add ||
        current_vendor_data.mobile !== vendor_data.mobile ||
        current_vendor_data.note !== vendor_data.note
      ) {
        setsave_btn(true);
      } else {
        setsave_btn(false);
      }
    } else {
      setsave_btn(false);
    }
  }, [current_vendor_data]);
  // console.log(current_vendor_data, vendor_data);
  if (type === "view") {
    return (
      <div className="vendorviewedit">
        <div>
          <div className="title">Name</div>
          <input value={vendor_data.name} readOnly />
          <div className="title">Mobile</div>
          <input value={mobile_convert_display(vendor_data.mobile)} readOnly />
          <div className="title">Address</div>
          <input value={vendor_data.add} readOnly />
          <div className="title">Note</div>
          <input value={vendor_data.note} readOnly />
          {setvendor_sidepanel_nav_to ? (
            <div
              className="btn"
              type="type2"
              onClick={() => setvendor_sidepanel_nav_to("edit")}
            >
              Edit
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
  if (type === "edit") {
    return (
      <div className="vendorviewedit">
        <div>
          <div className="title">Name</div>
          <input
            value={current_vendor_data.name}
            onChange={({ target }) =>
              setcurrent_vendor_data((prev) => ({
                ...prev,
                name: target.value,
              }))
            }
          />
          <div className="title">Mobile</div>
          <input
            type="number"
            value={current_vendor_data.mobile}
            onChange={({ target }) =>
              setcurrent_vendor_data((prev) => ({
                ...prev,
                mobile: target.value,
              }))
            }
          />
          <div className="title">Address</div>
          <input
            value={current_vendor_data.add}
            onChange={({ target }) =>
              setcurrent_vendor_data((prev) => ({ ...prev, add: target.value }))
            }
          />
          <div className="title">Note</div>
          <input
            value={current_vendor_data.note}
            onChange={({ target }) =>
              setcurrent_vendor_data((prev) => ({
                ...prev,
                note: target.value,
              }))
            }
          />

          <div
            className="btn"
            type={save_btn ? "type2" : "type1"}
            onClick={() => {
              if (!save_btn) {
                return;
              }
              setloader(true);
              // console.log("edit vendor", {
              //   name: current_vendor_data.name,
              //   mobile: current_vendor_data.mobile,
              //   address: current_vendor_data.add,
              //   notes: current_vendor_data.note,
              //   id: vendor_data.id,
              // });
              edit_vendors(
                {
                  name: current_vendor_data.name,
                  mobile: current_vendor_data.mobile,
                  address: current_vendor_data.add,
                  notes: current_vendor_data.note,
                  id: vendor_data.id,
                },
                current_store.user.token,
                (data) => {
                  if (!data.status) {
                    setloader(false);
                    return navigate("/");
                  }
                  setvendor_table([
                    ...data.data.vendors.map((el) => ({
                      id: el.id,
                      name: el.name,
                      add: el.address,
                      mobile: el.mobile_no,
                      note: el.notes,
                    })),
                  ]);
                  setloader(false);
                  setvendor_sidepanel_nav_to("view");
                  setvendor_table_mini(false);
                }
              );
            }}
          >
            Save
          </div>
        </div>
      </div>
    );
  }
  return "err";
};

const convert_cashdate = (date) => {
  let temp_date = new Date(date);
  return `${temp_date.getDate() <= 9 ? "0" : ""}${temp_date.getDate()}/${
    temp_date.getMonth() + 1 <= 9 ? "0" : ""
  }${temp_date.getMonth() + 1}/${temp_date.getFullYear()}`;
};
const convert_cashtime = (date) => {
  let temp_date = new Date(date);
  return `${
    temp_date.getHours() > 12 ? temp_date.getHours() - 12 : temp_date.getHours()
  }:${temp_date.getMinutes()} ${temp_date.getHours() > 12 ? "PM" : "AM"}`;
};
const VendorCash = ({
  vendor_data,
  vendor_reports,
  setloader,
  setreport_data_after_edit,
}) => {
  // console.log(vendor_data, vendor_reports);
  const [cash_inout, setcash_inout] = useState("");
  const [current_menu, setcurrent_menu] = useState("");
  const [current_report, setcurrent_report] = useState({});
  return (
    <div className="vendorcash">
      <VendorCashHeader
        value={vendor_reports
          .filter((el) => el.vendor_id === vendor_data.id)
          .reduce((prev, el) => (prev += el.amount ? Number(el.amount) : 0), 0)}
        cashin={() => setcash_inout("cashin")}
        cashout={() => setcash_inout("cashout")}
      />
      {(cash_inout == "cashin" || cash_inout == "cashout") && (
        <VendorCashInOutView
          data={vendor_data}
          // show={cash_inout}
          // close={() => setcash_inout("")}
          // data={{
          //   date: "{date}",
          //   time: "{time}",
          //   img: "",
          //   name: "{name}",
          //   amount: "{amount}",
          //   description: "{desc}",
          //   id: 1,
          //   credited: true,
          // }}
          type={cash_inout}
          close={() => setcash_inout("")}
          setloader={setloader}
        />
      )}
      {(cash_inout == "cashview" || cash_inout == "cashedit") && (
        <VendorCashInOutViewEdit
          data={vendor_data}
          report_data={current_report}
          type={cash_inout}
          close={() => setcash_inout("")}
          setloader={setloader}
          setreport_data_after_edit={setreport_data_after_edit}
        />
      )}
      {cash_inout == "cashdelete" && (
        <VendorCashDelete
          setreport_data_after_edit={setreport_data_after_edit}
          current_report={current_report}
          setloader={setloader}
          close={() => setcash_inout("")}
        />
      )}
      {vendor_reports.filter((el) => el.vendor_id === vendor_data.id).length ==
      0 ? (
        <div
          style={{
            height: "calc(100% - 100px)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          No reports
        </div>
      ) : (
        <Table
          cust_class="vendorcash_table"
          type="type1"
          table_head_data={["Amount", "Date", "time"]}
          table_body_data={[
            ...vendor_reports
              .filter((el) => el.vendor_id === vendor_data.id)
              .map((el) => [
                el.amount ? (
                  el.amount > 0 ? (
                    <div style={{ color: "var(--green)" }}>{el.amount}</div>
                  ) : (
                    <div style={{ color: "var(--red)" }}>{el.amount}</div>
                  )
                ) : (
                  "-"
                ),
                <>
                  {el.date ? convert_cashdate(el.date) : "-"}
                  <br />
                  {el.date ? convert_cashtime(el.date) : "-"}
                </>,
                el.description || "-",
                <div className="vendorcash_menu">
                  <MenuDots
                    clickhandel={() => {
                      setcurrent_menu((prev) =>
                        prev === "current_seleted_" + el.id
                          ? ""
                          : "current_seleted_" + el.id
                      );
                    }}
                  />
                  {"current_seleted_" + el.id === current_menu && (
                    <div className="vendorcash_menu_main">
                      <div className="header">
                        <Close
                          type="m"
                          clickhandel={() => setcurrent_menu("")}
                        />
                      </div>
                      <div className="body">
                        <div
                          onClick={() => {
                            setcash_inout("cashview");
                            setcurrent_report({ ...el });
                            setcurrent_menu("");
                          }}
                        >
                          <Eye /> View
                        </div>
                        <div
                          onClick={() => {
                            setcash_inout("cashedit");
                            setcurrent_report({ ...el });
                            setcurrent_menu("");
                          }}
                        >
                          <Pencil edit={true} /> Edit
                        </div>
                        <div
                          onClick={() => {
                            setcash_inout("cashdelete");
                            setcurrent_report({ ...el });
                            setcurrent_menu("");
                          }}
                        >
                          <Trash /> Delete
                        </div>
                      </div>
                    </div>
                  )}
                </div>,
              ]),
          ]}
        />
      )}
    </div>
  );
};
const VendorCashHeader = ({ value, cashin, cashout }) => {
  return (
    <div className="vendorheader">
      <div className="vendorcash_value">
        <div>Total Cash</div>
        <div>{value ? "$" + value : "-"}</div>
      </div>
      <div className="vendorcash_inout">
        <div onClick={() => cashin()}>+ Cash in</div>
        <div onClick={() => cashout()}>- Cash Out</div>
      </div>
    </div>
  );
};
