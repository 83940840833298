import React from "react";
import "./switcherStyle.scss";

export const Switcher = ({ active, setactive, style, custClass }) => {
  let cust_style = {
    height: 16,
    width: 28,
    ...style,
  };
  return (
    <div
      className={
        "switcher_main" +
        (custClass ? ` ${custClass}` : "") +
        (active ? " active" : "")
      }
      style={{
        "--switcher_height": cust_style.height || 0,
        "--switcher_width": cust_style.width || 0,
      }}
      onClick={(e) => setactive(e)}
    >
      <div className="switcher_blob"></div>
    </div>
  );
};
