import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { base64tolink, editcashlist } from "../../../API/API";
import { Camera, Close } from "../../Common/Images/Images";
import "./cashViewEditModal.scss";

export const CashViewEditModal = ({ type, data, close, setloader }) => {
  const [currentdatetime, setcurrentdatetime] = useState([
    data.date,
    data.time,
  ]);
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();

  const [receipt, setreceipt] = useState(data.img);
  const [name, setname] = useState(data.name);
  const [amount, setamount] = useState(data.amount);
  const [description, setdescription] = useState(data.description);
  useEffect(() => {
    // let tempcurrent = new Date();
    // // let tempcurrent_arr = new Date(tempcurrent).toString().split(' ');
    // setcurrentdatetime(
    //     [
    //         `${tempcurrent.getDate() < 10 && 0}${tempcurrent.getDate()}/${tempcurrent.getMonth() + 1 < 10 && 0}${tempcurrent.getMonth() + 1}/${tempcurrent.getFullYear()}`,
    //         `${tempcurrent.getHours() > 12 ? tempcurrent.getHours() - 12 : tempcurrent.getHours()}:${tempcurrent.getMinutes()} ${tempcurrent.getHours() > 12 ? 'PM' : 'AM'}`
    //     ]
    // )
    setTimeout(() => {
      let receipt_img = document.getElementById("receipt_img");
      let receipt_img_rect = receipt_img.getBoundingClientRect();
      if (receipt_img_rect.height > receipt_img_rect.width) {
        receipt_img.style.height = "100%";
        receipt_img.style.width = "auto";
      } else {
        receipt_img.style.width = "100%";
        receipt_img.style.height = "auto";
      }
    }, 10);
  }, []);
  const edit_cash = () => {
    if (type != "edit") {
      return;
    }
    if (
      data.name == name &&
      data.amount == amount &&
      data.img == receipt &&
      data.description == description
    ) {
      return;
    }
    editcashlist(
      {
        id: data.id,
        full_name: name,
        img: receipt,
        amount: amount,
        description: description,
        dateTime: data.full_datetime,
      },
      current_store.user.token,
      (data) => {
        if (!data.status) {
          return navigate("/404");
        }
        window.location.reload();
      }
    );
  };

  return (
    <div className="cashvieweditmodal">
      <div>
        <div className="header">
          <Close clickhandel={close} />
        </div>
        <div className="body">
          <div className="left_body">
            {type == "view" ? (
              <div className="name_view">{name}</div>
            ) : (
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => {
                  setname(e.target.value);
                }}
              />
            )}
            <div className="current_date_time">
              <div>{currentdatetime[0]}</div>
              <div>{currentdatetime[1]}</div>
            </div>
            {type == "edit" && (
              <label
                className="receipt"
                style={receipt ? { borderColor: "var(--black)" } : {}}
              >
                <Camera />
                Receipt
                <input
                  type="file"
                  accept=".jpeg,.png"
                  onChange={(e) => {
                    setloader(true);
                    var reader = new FileReader();
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = function () {
                      base64tolink(
                        {
                          base_64: reader.result.split(",")[1],
                          name: `cash_img_${data.id}.jpeg`,
                        },
                        current_store.user.token,
                        (data) => {
                          if (!data.status) {
                            setloader(false);
                            return navigate("/");
                          }
                          setloader(false);
                          setreceipt(data.link + "?" + new Date());
                          setTimeout(() => {
                            let receipt_img =
                              document.getElementById("receipt_img");
                            let receipt_img_rect =
                              receipt_img.getBoundingClientRect();
                            if (
                              receipt_img_rect.height > receipt_img_rect.width
                            ) {
                              receipt_img.style.height = "100%";
                              receipt_img.style.width = "auto";
                            } else {
                              receipt_img.style.width = "100%";
                              receipt_img.style.height = "auto";
                            }
                          }, 10);
                        }
                      );
                    };
                  }}
                />
              </label>
            )}
            {/* {receipt ? ( */}
            <div
              style={
                type == "view"
                  ? {
                      height: "204px",
                      textAlign: "center",
                      border: "1px solid var(--black)",
                      borderBottomLeftRadius: "var(--border-radius-4)",
                      borderBottomRightRadius: "var(--border-radius-4)",
                      overflow: "hidden",
                      margin: "auto 0 0 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  : {
                      height: "204px",
                      textAlign: "center",
                      border: "1px solid var(--black)",
                      borderBottomLeftRadius: "var(--border-radius-4)",
                      borderBottomRightRadius: "var(--border-radius-4)",
                      overflow: "hidden",
                      margin: "-4px 0 0 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }
              }
            >
              {receipt == "" ? (
                "No Image"
              ) : (
                <img src={receipt} id="receipt_img" />
              )}
            </div>
            {/* ) : (
              <div style={{ margin: "auto 0 auto 0" }}>No Image</div>
            )} */}
          </div>
          <div className="right_body">
            {type == "view" ? (
              <div
                className="amount_view"
                fcolor={data.credited ? "green" : "red"}
              >
                <div>
                  {data.credited ? "+" : "-"} ${" "}
                  {Math.abs(Number(data.amount)).toFixed(2)}
                </div>
              </div>
            ) : (
              <div
                className={
                  "amount" + (data.credited ? " amount_green" : " amount_red")
                }
              >
                {/* {amount != "" && (
                  <div> {data.credited ? "+" : "-"}&nbsp;$&nbsp;</div>
                )} */}
                {/* <label
                  style={
                    amount != ""
                      ? {
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          height: "unset",
                          width: "unset",
                        }
                      : {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }
                  }
                  htmlFor="amount_inp"
                >
                  {amount != "" ? amount : "Amount"}
                </label> */}
                <input
                  id="amount_inp"
                  // style={amount != '' ? { width: 'fit-content' } : {}}
                  style={{
                    border: 0,
                    background: "transparent",
                    // width: "0",
                    padding: "0",
                  }}
                  type="number"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => {
                    setamount(e.target.value);
                  }}
                />
              </div>
            )}
            <textarea
              style={type == "view" ? { pointerEvents: "none", border: 0 } : {}}
              rows={Math.floor(description.length / 12) + 1}
              type="text"
              placeholder="Description"
              value={description}
              onChange={(e) => {
                type == "edit" && setdescription(e.target.value);
              }}
            />
            {type == "cashin" ? (
              <div
                className="cashinout_btn btn"
                type={
                  name != "" &&
                  amount != "" &&
                  description != "" &&
                  receipt != ""
                    ? "type2"
                    : "type1"
                }
              >
                Add Cash In
              </div>
            ) : type == "cashout" ? (
              <div
                className="cashinout_btn btn"
                type={
                  name != "" &&
                  amount != "" &&
                  description != "" &&
                  receipt != ""
                    ? "type2"
                    : "type1"
                }
              >
                Add Cash Out
              </div>
            ) : type == "edit" ? (
              <div
                className="cashinout_btn btn"
                type={
                  data.name == name &&
                  data.amount == amount &&
                  data.img == receipt &&
                  data.description == description
                    ? "type1"
                    : // : name != "" ||
                      //   amount != "" ||
                      //   receipt != "" ||
                      //   description != ""
                      // ? "type1"
                      "type2"
                }
                onClick={edit_cash}
              >
                Save
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="footer"></div>
      </div>
    </div>
  );
};
