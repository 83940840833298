import React from "react";

export const ClientMenu = ({
  client_menu,
  setclient_menu,
  to_show_table_details,
  setto_show_table_details,
}) => {
  return (
    <div className="right_div_menu">
      <div
        className={client_menu == "view" ? "menu_active" : ""}
        onClick={() => {
          setclient_menu("view");
          // setto_show_table_details('view')
        }}
      >
        {to_show_table_details == "edit" ? "EDIT" : "VIEW"}
      </div>
      <div
        className={client_menu == "orderhistory" ? "menu_active" : ""}
        // style={{ cursor: "no-drop" }}
        onClick={() => {
          setclient_menu("orderhistory");
          setto_show_table_details("view");
        }}
      >
        ORDER HISTORY
      </div>
      <div
        className={client_menu == "analytics" ? "menu_active" : ""}
        // style={{ cursor: "no-drop" }}
        onClick={() => {
          setclient_menu("analytics");
          setto_show_table_details("view");
        }}
      >
        ANALYTICS
      </div>
      <div
        className={client_menu == "futureprediction" ? "menu_active" : ""}
        // style={{ cursor: "no-drop" }}
        onClick={() => {
          setclient_menu("futureprediction");
          setto_show_table_details("view");
        }}
      >
        FUTURE PREDICTIONS
      </div>
    </div>
  );
};
