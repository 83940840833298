import React from "react";
import "./accordiancardsStyle.scss";

export const AccordianCards = ({ data, add_obj, card_style }) => {
  return (
    <div className="accordiancards">
      {data.map((el) => (
        <div
          className="cards"
          style={card_style ? { ...card_style } : {}}
          onClick={el.onviewedit}
        >
          <div>{el.name}</div>
          <div>{el.cost}</div>
        </div>
      ))}
      {add_obj && add_obj.card}
    </div>
  );
};
