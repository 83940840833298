import React, { useEffect, useState } from "react";
// import DatePicker from "react-datepicker";
import { sub } from "date-fns";

import { DateRange, DefinedRange } from "react-date-range";
import "react-datepicker/dist/react-datepicker.css";
// import "./custdaterangepickerStyle.css";
import "./custDateRangePickerStyle.scss";

const quarter_arr = [
  [0, 2],
  [0, 2],
  [0, 2],
  [3, 5],
  [3, 5],
  [3, 5],
  [6, 8],
  [6, 8],
  [6, 8],
  [9, 11],
  [9, 11],
  [9, 11],
];
const halfyear_arr = [
  [0, 5],
  [0, 5],
  [0, 5],
  [0, 5],
  [0, 5],
  [0, 5],
  [6, 11],
  [6, 11],
  [6, 11],
  [6, 11],
  [6, 11],
  [6, 11],
];
const calender_options = [
  {
    option: "Today",
    func: (setdate) => {
      setdate([
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ]);
    },
  },
  {
    option: "This month",
    func: (setdate) => {
      const current_date = new Date();
      setdate([
        {
          startDate: new Date(
            current_date.toString().replace(current_date.getDate(), 1)
          ),
          endDate: current_date,
          key: "selection",
        },
      ]);
    },
  },
  {
    option: "Last month",
    func: (setdate) => {
      const current_date = new Date();
      const current_first_date = new Date(
        current_date.getFullYear(),
        current_date.getMonth()
      );
      // let last_month_first_date = new Date(
      //   current_first_date.getMonth() == 0
      //     ? current_first_date.getFullYear() - 1
      //     : current_first_date.getFullYear(),
      //   current_first_date.getMonth() == 0
      //     ? 11
      //     : current_first_date.getMonth() - 1
      // );
      setdate([
        {
          startDate: sub(current_first_date, { months: 1 }),
          endDate: sub(current_first_date, { days: 1 }),
          key: "selection",
        },
      ]);
    },
  },
  {
    option: "This quarter",
    func: (setdate) => {
      const current_date = new Date();
      const in_quarter = quarter_arr[current_date.getMonth()];
      setdate([
        {
          startDate: new Date(current_date.getFullYear(), in_quarter[0]),
          endDate: current_date,
          // endDate: sub(
          //   new Date(current_date.getFullYear(), in_quarter[1] + 1),
          //   { days: 1 }
          // ),
          key: "selection",
        },
      ]);
    },
  },
  {
    option: "Last quarter",
    func: (setdate) => {
      const current_date = new Date();
      const in_quarter = quarter_arr[current_date.getMonth()];
      setdate([
        {
          startDate: sub(new Date(current_date.getFullYear(), in_quarter[0]), {
            months: 3,
          }),
          endDate: sub(
            new Date(current_date.getFullYear(), in_quarter[1] + 1),
            { days: 1, months: 3 }
          ),
          key: "selection",
        },
      ]);
    },
  },
  {
    option: "This half year",
    func: (setdate) => {
      const current_date = new Date();
      const in_halfyear = halfyear_arr[current_date.getMonth()];
      setdate([
        {
          startDate: new Date(current_date.getFullYear(), in_halfyear[0]),
          endDate: current_date,
          key: "selection",
        },
      ]);
    },
  },
  {
    option: "Last half year",
    func: (setdate) => {
      const current_date = new Date();
      const in_halfyear = halfyear_arr[current_date.getMonth()];
      setdate([
        {
          startDate: sub(new Date(current_date.getFullYear(), in_halfyear[0]), {
            months: 6,
          }),
          endDate: sub(new Date(current_date.getFullYear(), in_halfyear[1]), {
            days: 1,
            months: 5,
          }),
          key: "selection",
        },
      ]);
    },
  },
  {
    option: "This year",
    func: (setdate) => {
      const current_date = new Date();
      // const in_halfyear = halfyear_arr[current_date.getMonth()];
      setdate([
        {
          startDate: new Date(current_date.getFullYear(), 0),
          endDate: current_date,
          key: "selection",
        },
      ]);
    },
  },
  {
    option: "Last year",
    func: (setdate) => {
      const current_date = new Date();
      // const in_halfyear = halfyear_arr[current_date.getMonth()];
      setdate([
        {
          startDate: sub(new Date(current_date.getFullYear(), 0), {
            months: 12,
          }),
          endDate: sub(new Date(current_date.getFullYear(), 0), { days: 1 }),
          key: "selection",
        },
      ]);
    },
  },
];

export const CustDateRangePicker = ({ id, custclass, date, setdate, data }) => {
  const [state, setState] = useState([
    {
      startDate: date[0],
      endDate: date[1],
      key: "selection",
    },
  ]);
  const [select_option, setselect_option] = useState("");
  const [menu, setmenu] = useState(false);
  const check_orders = () => {
    // setdate()
    let temp_data = data.og_arr.sort(
      (a, b) =>
        new Date(b.order_date).valueOf() - new Date(a.order_date).valueOf()
    );

    // setdate([
    //   new Date(temp_data[temp_data.length - 1].order_date),
    //   new Date(temp_data[0].order_date),
    // ]);
    setState([
      {
        startDate: new Date(temp_data[temp_data.length - 1].order_date),
        endDate: new Date(temp_data[0].order_date),
        key: "selection",
      },
    ]);
  };
  useEffect(() => {
    if (data.og_arr && data.og_arr.length > 0) {
      check_orders();
    }
  }, []);

  useEffect(() => {
    setdate([state[0].startDate, state[0].endDate]);
  }, [state]);
  //   useEffect(() => {
  //     // if(date+)
  //     if (date[0] === date[1]) {
  //       setState([
  //         {
  //           startDate: date[0],
  //           endDate: date[0],
  //           key: "selection",
  //         },
  //       ]);
  //     }
  //   }, [date]);
  const handel_option_select = (txt, id) => {
    setselect_option(txt);
    //     for(i=0; i<calender_options.length; i++){
    // if(calender_options[i].options==)
    //     }
    calender_options[id].func(setState);
    setmenu(false);
  };
  return (
    <>
      {/* <DatePicker
                id={id || ''}
                className={custclass || ''}
                selectsRange={true}
                startDate={date[0]}
                endDate={date[1]}
                onChange={(update) => {
                    setdate(update);
                }}
                isClearable={true}
                placeholderText="Click to select a date"

            /> */}
      <div className={"cust_date_picker" + (menu ? " active" : "")}>
        <div
          className={"menu" + (menu ? " active" : "")}
          onClick={() => {
            setmenu(!menu);
            if (!menu) {
              setselect_option("");
            }
          }}
        >
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className="calender_flex">
          <div className={"calenderoptions" + (menu ? " active" : "")}>
            {calender_options.map((el, i) => (
              <div
                key={i}
                className={select_option === el.option ? "active" : ""}
                onClick={() => {
                  handel_option_select(el.option, i);
                }}
              >
                {el.option}
              </div>
            ))}
          </div>
          {/* <div
        onClick={() => {
          setState([
            {
              startDate: new Date(),
              endDate: new Date(),
              key: "selection",
            },
          ]);
        }}
        className={
          "reset_btn" +
          (new Date(date[0]) - new Date(date[1]) === 0 ? " disabled" : "")
        }
        startdate={date[0]}
        enddate={date[1]}
        >
        Reset
      </div> */}
          {/* <DefinedRange
        onChange={(item) => setState([item.selection])}
        ranges={state}
      /> */}
          <DateRange
            className={custclass}
            // editableDateInputs={true}
            onChange={(item) => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
            // months={2}
            // direction="horizontal"
          />
        </div>
      </div>
    </>
  );
};
