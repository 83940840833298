import React, { useState } from "react";
import "./addordermodalStyle.scss";
import { useNavigate } from "react-router-dom";
import arrow_down from "../../../img/miscellaneous/arrow_down.png";
import { Close } from "../../Common/Images/Images";
import { CustDropdown } from "../../Custom/CustDropdown/CustDropdown";
import {
  createorder,
  getclient_byid,
  getorderbyid,
  mobile_convert_display,
} from "../../../API/API";
import { useSelector } from "react-redux";
import { CustDropdownWithSearch } from "../../Custom/CustDropdownWithSearch/CustDropdownWithSearch";

export const AddOrderModal = ({
  setdata,
  setadd_order_modal,
  clientmini_data,
  setloader,
}) => {
  const current_store = useSelector((state) => state);
  const [dropdown_arr, setdropdown_arr] = useState([
    //     {
    //     order_id: "#ORDERtest1",
    //     status_order_data: ['04-04-2022 13:00', '04-04-2022 14:00', '04-04-2022 15:00', '04-04-2022 16:00', '04-04-2022 17:00'],
    //     name: "Ken Kent",
    //     address: "247 West 87th Street NY 10024",
    //     phone: "+1 (646) 525-6754",
    //     payment: "7981",
    //     status: 1,
    //     pay_status: 0,
    //     order_created: "2022-04-04",
    //     desc: "",
    // },
    // {
    //     order_id: "#ORDERtest2",
    //     status_order_data: ['04-04-2022 13:00', '04-04-2022 14:00', '04-04-2022 15:00', '04-04-2022 16:00', '04-04-2022 17:00'],
    //     name: "Jim Newton",
    //     address: "247 West 87th Street NY 10024",
    //     phone: "+1 (646) 525-6754",
    //     payment: "7981",
    //     status: 0,
    //     pay_status: 1,
    //     order_created: "2022-04-04",
    //     desc: "1 Pants",
    // }
    ...clientmini_data.map((el) => {
      return {
        id: el.id,
        name: el.full_name,
        img: el.profile_photo_link,
      };
    }),
    //   .sort((a, b) => a.name - b.name),
  ]);
  const [dropdown_select, setdropdown_select] = useState("");
  const navigate = useNavigate();
  return (
    <div
      className="addordermodal"
      closemodal="yes"
      onClick={(e) => {
        if (e.target.getAttribute("closemodal") == "yes") {
          setadd_order_modal(false);
        }
      }}
    >
      <div>
        <div className="header">
          <Close clickhandel={() => setadd_order_modal(false)} />
        </div>
        <div className="body">
          <div className="title">Client</div>
          <CustDropdownWithSearch
            custid="cust1"
            value={dropdown_select}
            defaultplaceholder="Select Client"
            dropdown_arr={[
              ...dropdown_arr.map((el, i) => {
                return { key: i, id: el.id, value: el.name, img: el.img };
              }),
            ]}
            setvalue={(data) => {
              setdropdown_select(
                // dropdown_arr?.filter((el) => el.id == data)[0]
                data
              );
            }}
          />
          {/* <div
            className="cust_dropdown"
            id="cust_dropdown"
            onMouseOut={(e) => {
              if (
                e.target.id != "cust_dropdown" ||
                e.target.id != "main_dropdown"
              )
                return;
              // document.getElementById('cust_dropdown').className = 'cust_dropdown ';
            }}
            onClick={(e) => {
              if (e.target.id != "cust_dropdown") return;
              document.getElementById("cust_dropdown").className =
                document.getElementById("cust_dropdown").classList.length > 1
                  ? "cust_dropdown"
                  : "cust_dropdown cust_dropdown_active";
            }}
          >
            {dropdown_select !== ""
              ? dropdown_arr[dropdown_select].name
              : "Select Client"}
            <img src={arrow_down} />
            <div className="main_dropdown" id="main_dropdown">
              {dropdown_arr.map((el, i) => (
                <div
                  onClick={() => {
                    setdropdown_select(i);
                    document.getElementById("cust_dropdown").className =
                      "cust_dropdown";
                  }}
                >
                  {el.name}
                </div>
              ))}
            </div>
          </div> */}
        </div>
        <div className="footer">
          <div
            className="btn"
            type={dropdown_select !== "" ? "type2" : "type1"}
            onClick={() => {
              // navigate("/add-order?id=123");
              // return;
              setloader(true);
              createorder(
                {
                  client_id: clientmini_data[dropdown_select].id,
                  address: clientmini_data[dropdown_select].address || "no",
                  apt: clientmini_data[dropdown_select].apt || "no",
                  zipcode: clientmini_data[dropdown_select].zipcode || "no",
                },
                current_store.user.token,
                (data) => {
                  // data.data.order_id
                  // setTimeout(() => {
                  getorderbyid(
                    data.data.order_id,
                    current_store.user.token,
                    (data) => {
                      getclient_byid(
                        data.data.sql_data.client_id,
                        current_store.user.token,
                        (data1) => {
                          // add date to status_order_data w.r.t below array
                          const order_statuses = [
                            // "All",
                            "Pickup Requested",
                            "Driver Enroute to Pickup",
                            "Picked Up",
                            "Customer Charged",
                            "Deliver Clothes",
                            "Driver Enroute to Dropoff",
                            // "Order Cancelled",
                            "Order Completed",
                          ];
                          // return;

                          setdata({
                            order_id: data.data.sql_data.order_id,
                            notes: data.data.sql_data.notes,
                            name: data.data.sql_data.client_name,
                            address:
                              // "",
                              data.data.sql_data.address,
                            phone:
                              // data.data.sql_data.mobile ||
                              mobile_convert_display(data.data.sql_data.mobile),
                            payment: data.data.sql_data.last_4,
                            status_order_data: [
                              ...data.data.status_logs,
                              // null,
                            ],
                            // status_order_data: [],
                            order_created: data.data.sql_data.order_date,
                            card_list: data1.data.cards,
                            client_id: data.data.sql_data.client_id,
                            charged: data.data.sql_data.charged,
                            delivered: data.data.sql_data.delivered,
                            client_particulars: data.data.sql_data
                              .client_particulars || {
                              others: "0",
                              laundry: "0",
                              drycleaning: "0",
                            },
                            file_data: {
                              ...(["undefined", ""].indexOf(
                                data.data.file_data
                              ) != -1
                                ? {}
                                : JSON.parse(data.data.file_data)),
                            },
                          });
                          setloader(false);
                          navigate("/add-order");
                        }
                      );
                    }
                  );
                  // }, 100);
                }
              );
              // if (dropdown_select === "") return;
              // setdata({
              //   ...dropdown_arr[dropdown_select],
              // });
              // navigate("/add-order");
            }}
          >
            Start Order
          </div>
        </div>
      </div>
    </div>
  );
};
