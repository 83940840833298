import React, { useEffect, useRef, useState } from "react";
import "./pricelistaccordiancardmodalStyle.scss";

import { Close, Plus, TrashIconBlack } from "../../../Common/Images/Images";
import { CustDropdown } from "../../../Custom/CustDropdown/CustDropdown";
import { pricelist } from "../../../dummydata";

export const PricelistAccordianCardModal = ({
  pricelist_subsection,
  order_item_cards,
  setorder_item_cards,
  card_data,
  setacc_card_modal,
  discountlist_current,
}) => {
  const [name, setname] = useState("");
  const [check_weight, setcheck_weight] = useState(false);
  const [price, setprice] = useState("");
  const [weight, setweight] = useState(-1);
  const [select_addon, setselect_addon] = useState("");
  const [select_addon_sub, setselect_addon_sub] = useState("");
  const [select_addon_price, setselect_addon_price] = useState(0);
  const [addon_list, setaddon_list] = useState([]);
  const [show_item_discount, setshow_item_discount] = useState(false);
  const [show_item_discount_value, setshow_item_discount_value] = useState(0);
  const [total, settotal] = useState(0);

  // useEffect(() => {
  //   setselect_addon_sub("");
  // }, [select_addon]);
  useEffect(() => {
    let temp_total = (
      Number(addon_list.reduce((prev, curr) => prev + Number(curr.price), 0)) +
      Number(price) -
      Number(show_item_discount ? show_item_discount_value : 0)
    ).toFixed(2);
    settotal(temp_total < 0 ? Number(0).toFixed(2) : temp_total);
  }, [addon_list, price, show_item_discount, show_item_discount_value]);

  useEffect(() => {
    setshow_item_discount(
      discountlist_current.discount_value
        .map((el) => (card_data.title + "").includes(el))
        .includes(true)
    );
  }, []);

  useEffect(() => {
    if (select_addon_sub !== "") {
      // setselect_addon_sub("");
      setselect_addon_price(
        pricelist_subsection[select_addon].acc_body[select_addon_sub][1]
      );
    }
  }, [select_addon_sub]);
  useEffect(() => {
    if (discountlist_current.is_percentage) {
      setshow_item_discount_value(
        (Number(price) * (Number(discountlist_current.amount) / 100)).toFixed(2)
      );
    } else {
      setshow_item_discount_value(
        // (Number(price) * (Number(discountlist_current.amount) / 100)).toFixed(2)
        Number(discountlist_current.amount).toFixed(2)
      );
    }
  }, [price]);

  const additem = () => {
    if (price != "") {
      setorder_item_cards([
        ...order_item_cards,
        {
          item_name: card_data.title == "cust" ? name : card_data.title,
          item_price: price,
          item_weight: weight,
          addon_items: addon_list,
          discount_value: show_item_discount ? show_item_discount_value : 0,

          total: total,
        },
      ]);
      setacc_card_modal(false);
      return;
    }
    // setorder_item_cards([
    //   ...order_item_cards,
    //   { item_name: card_data.title, item_price: Array.isArray(card_data.price) ? card_data.price[0].split(' ')[0] : card_data.price },
    // ]);
    setacc_card_modal(false);
  };
  useEffect(() => {
    if (card_data.title == "cust") return;
    setcheck_weight(Array.isArray(card_data.price));
    setprice(
      Array.isArray(card_data.price)
        ? card_data.price[0].split(" ")[0]
        : card_data.price
    );

    setweight(
      Array.isArray(card_data.price)
        ? card_data.price[0].split(" ")[2].slice(0, -2)
        : -1
    );
  }, []);
  useEffect(() => {
    if (weight != -1) {
      let min_weight = card_data.price[0].split(" ")[2].slice(0, -2);
      let multiplier = card_data.price[1].split(" ")[0];
      setprice(
        (
          Number(card_data.price[0].split(" ")[0]) +
          (weight - min_weight > 0 ? multiplier * (weight - min_weight) : 0)
        ).toFixed(2)
      );
    }
  }, [weight]);
  return (
    <div className="pricelistaccordiancardmodal">
      <div>
        <div className="modal_close">
          <Close
            clickhandel={() => {
              setacc_card_modal(false);
            }}
          />
        </div>
        {card_data.title == "cust" ? (
          ""
        ) : (
          <div className="pricelistaccordiancardmodal_header">
            <div>
              {card_data?.title.split("%ITEMS_NAME_DIVIDER%").join(" > ")}
            </div>
            <div>
              {/* {card_data?.title
                ? discountlist_current.discount_value.map((el) =>
                    el.match(card_data.title)
                  )
                : ""} */}
            </div>
          </div>
        )}
        <div className="pricelistaccordiancardmodal_body">
          {card_data.price == "cust" ? (
            <div style={{ marginBottom: "14px" }}>
              <div className="body_title">Name</div>
              <div className="cust_input">
                <input
                  className="w_50"
                  type="text"
                  placeholder="Insert Name"
                  id="cust_price"
                  value={name}
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="price">
              $
              {Array.isArray(card_data.price) ? (
                <>
                  {card_data.price[0]}
                  <br />+{card_data.price[1]}
                </>
              ) : (
                card_data.price
              )}
            </div>
          )}
          <div>
            <div
              className="body_title"
              style={
                addon_list.length > 0 || show_item_discount
                  ? {
                      display: "flex",
                      width: addon_list.length > 0 ? "100%" : "75%",
                    }
                  : {}
              }
            >
              {addon_list.length > 0 || show_item_discount ? (
                <>
                  <div style={{ width: "33%" }}>Price</div>
                  {show_item_discount && (
                    <div style={{ width: "33%" }}>Discount</div>
                  )}
                  {addon_list.length > 0 && (
                    <div style={{ width: "33%" }}>Modifier Price</div>
                  )}
                  <div style={{ width: "33%" }}>Total</div>
                </>
              ) : (
                <div>Price</div>
              )}
            </div>
            <div
              className="cust_input"
              style={
                addon_list.length > 0 || show_item_discount
                  ? { width: addon_list.length > 0 ? "100%" : "75%" }
                  : {}
              }
            >
              <div>$</div>
              <input
                className="w_50"
                type="number"
                placeholder="Insert Price"
                id="cust_price"
                value={price}
                onChange={(e) => {
                  setprice(e.target.value);
                }}
              />
              {addon_list.length > 0 || show_item_discount ? (
                <>
                  {show_item_discount && (
                    <>
                      <div style={{ margin: "0 5px 0 5px" }}>-</div>
                      <div>$</div>
                      <input
                        className="w_50"
                        type="number"
                        placeholder="Insert Price"
                        id="cust_price"
                        value={show_item_discount_value}
                        // onChange={(e) => {
                        //   setprice(e.target.value);
                        // }}
                      />
                    </>
                  )}
                  {addon_list.length > 0 && (
                    <>
                      <div style={{ margin: "0 5px 0 5px" }}>+</div>
                      <div>$</div>
                      <input
                        className="w_50"
                        type="number"
                        placeholder=""
                        // id="cust_price"
                        value={
                          // Number(
                          //   addon_list.reduce(
                          //     (prev, curr) => prev + Number(curr.price),
                          //     0
                          //   )
                          // ) + Number(price)
                          addon_list
                            .reduce(
                              (prev, curr) => prev + Number(curr.price),
                              0
                            )
                            .toFixed(2)
                        }
                        readOnly
                      />
                    </>
                  )}
                  <div style={{ margin: "0 5px 0 5px" }}>=</div>
                  <div>$</div>
                  <input
                    className="w_50"
                    type="number"
                    placeholder=""
                    // id="cust_price"
                    value={total}
                    readOnly
                  />
                </>
              ) : (
                ""
              )}
            </div>
          </div>
          {check_weight && (
            <div className="pricelistaccordiancardmodal_weight">
              <div className="body_title">Weight</div>
              <div className="cust_input">
                <input
                  value={weight}
                  onChange={(e) => {
                    setweight(e.target.value);
                  }}
                  className="w_50"
                  type="number"
                  placeholder="Insert Weight"
                  id="cust_price"
                />
                <div>lb</div>
              </div>
            </div>
          )}
          <div className="add_ons_group">
            <div className="body_title">Add On's</div>
            <div
              className="addon_view"
              active={(addon_list.length > 0).toString()}
            >
              {addon_list.length > 0 && (
                <div className="table_header">
                  <div>Category</div>
                  <div>Sub-category</div>
                  <div>Price</div>
                  <div>Action</div>
                </div>
              )}
              {addon_list.map((el, i) => (
                <div>
                  <div>{el.addon_title}</div>
                  <div>{el.addon_sub}</div>
                  <div>$ {el.price}</div>
                  <div>
                    <TrashIconBlack
                      clickhandel={() => {
                        let temp_arr = [...addon_list];
                        temp_arr = [
                          ...[...temp_arr].slice(0, i),
                          ...[...temp_arr].slice(i + 1, temp_arr.length),
                        ];
                        setaddon_list(temp_arr);
                      }}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="add_on_flex">
              <div>
                <CustDropdown
                  custid="current_open_add_order_modal"
                  dropdown_arr={[
                    // { key: "0", value: "a" },
                    // { key: "1", value: "b" },
                    // { key: "2", value: "c" },
                    // { key: "3", value: "d" },
                    ...pricelist_subsection.map((el, i) => ({
                      key: i,
                      value: el.title,
                    })),
                  ]}
                  defaultplaceholder="select one.."
                  value={select_addon}
                  setvalue={(data) => {
                    setselect_addon_sub("");
                    setselect_addon(data);
                  }}
                />
              </div>
              {select_addon !== "" && (
                <div>
                  <CustDropdown
                    custid="current_open_add_order_modal1s"
                    dropdown_arr={[
                      // { key: "0", value: "a" },
                      // { key: "1", value: "b" },
                      // { key: "2", value: "c" },
                      // { key: "3", value: "d" },
                      ...pricelist_subsection[select_addon].acc_body.map(
                        (el, i) => ({
                          key: i,
                          value: el[0],
                        })
                      ),
                    ]}
                    defaultplaceholder="select one.."
                    value={select_addon_sub}
                    setvalue={setselect_addon_sub}
                  />
                </div>
              )}
              {select_addon !== "" && select_addon_sub !== "" && (
                <>
                  <div className="price_inp">
                    <div
                      className="price_action_btn"
                      type="add"
                      onClick={() =>
                        setselect_addon_price((prev) => Number(prev) + 1)
                      }
                    ></div>
                    <div>$</div>
                    <input
                      type="number"
                      value={Number(select_addon_price).toFixed(2)}
                      // value={
                      //   pricelist_subsection[select_addon].acc_body[
                      //     select_addon_sub
                      //   ][1]
                      // }
                      readOnly={true}
                      // onChange={({target})=>setselect_addon_price(target.value)}
                    />
                    <div
                      className={
                        "price_action_btn" +
                        (Number(select_addon_price) === 0 ? " disable" : "")
                      }
                      type="subtract"
                      onClick={() =>
                        setselect_addon_price((prev) =>
                          prev === 0 ? 0 : Number(prev) - 1
                        )
                      }
                    ></div>
                  </div>
                  <div
                    className="add_btn"
                    onClick={() => {
                      setaddon_list((prev) => [
                        ...prev,
                        {
                          addon_title: pricelist_subsection[select_addon].title,
                          addon_sub:
                            pricelist_subsection[select_addon].acc_body[
                              select_addon_sub
                            ][0],
                          price: Number(select_addon_price),
                        },
                      ]);
                      setselect_addon_price(0);
                      setselect_addon_sub("");
                      setselect_addon("");
                    }}
                  >
                    <Plus white={true} />
                  </div>
                </>
              )}
            </div>
            {show_item_discount && (
              <div style={{ margin: "14px 0" }}>
                <div className="body_title">Item Discount</div>
                <div className="cust_input">
                  <input
                    className="w_50"
                    type="number"
                    placeholder="Insert Discount"
                    id="cust_discount"
                    value={show_item_discount_value}
                    onChange={(e) => {
                      setshow_item_discount_value(e.target.value);
                    }}
                  />
                </div>
              </div>
            )}
            {/* <div>{pricelist_subsection.map((el) => el.title)}</div> */}
          </div>
        </div>
        <div className="pricelistaccordiancardmodal_footer">
          <div
            className="btn"
            type={
              price == "" || (card_data.title == "cust" && name == "")
                ? "type1"
                : "type2"
            }
            onClick={() => {
              if (price == "" || (card_data.title == "cust" && name == ""))
                return;
              additem();
            }}
          >
            Add item
          </div>
        </div>
      </div>
    </div>
  );
};
