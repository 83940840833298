import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  checktoken,
  getitems,
  get_discount,
  update_discount,
} from "../../API/API";
import {
  Close,
  Eye,
  Loading_Icon,
  MenuDots,
  Pencil,
  Plus,
  Tick,
  TrashIconBlack,
} from "../../Component/Common/Images/Images";
import { SearchAndFilterBar } from "../../Component/Common/SearchAndFilterBar/SearchAndFilterBar";
import { Table } from "../../Component/Common/Table/Table";
import { AddDiscountModal } from "../../Component/Discount/AddDiscountModal";
import { EditDiscountModal } from "../../Component/Discount/EditDiscountModal copy";
import { Switcher } from "../../Component/Switcher/Switcher";
import "./discountStyle.scss";

export const Discount = ({ setshow_navbar, setloader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const current_store = useSelector((state) => state);

  const [og_item_list, setog_item_list] = useState([]);
  const [current_item, setcurrent_item] = useState({});
  const [discount_add_modal, setdiscount_add_modal] = useState(false);
  const [search, setsearch] = useState("");
  const [discount_edit_modal, setdiscount_edit_modal] = useState(false);
  const [is_active_api_loading, setis_active_api_loading] = useState(false);

  const [discount_list, setdiscount_list] = useState([
    // {
    //   id: 1,
    //   discount_txt: "SUMMER22",
    //   is_active: true,
    //   is_deleted: false,
    //   is_for: {
    //     main: [0],
    //     sub_main: [0],
    //     items: [0],
    //   },
    //   amount: "",
    //   is_percentage: false,
    //   desc: "10% Off on dry cleaning",
    // },
    // {
    //   id: 2,
    //   discount_txt: "SUMMER22_1",
    //   is_active: true,
    //   is_deleted: false,
    //   is_for: {
    //     main: [2],
    //     sub_main: [2],
    //     items: [2],
    //   },
    //   amount: "",
    //   is_percentage: false,
    //   desc: "20% Off on dry cleaning",
    // },
    // {
    //   id: 3,
    //   discount_txt: "SUMMER22_2",
    //   is_active: false,
    //   is_deleted: false,
    //   is_for: {},
    //   amount: "",
    //   is_percentage: false,
    //   desc: "30% Off on dry cleaning",
    // },
  ]);
  useEffect(() => {
    let local_token = localStorage.getItem("jc_pos_token");
    if (local_token) {
      checktoken(local_token, (data) => {
        if (data.status) {
          dispatch({
            type: "login",
            payload: { token: local_token, admin: data.admin },
          });
          return navigate("/discount");
        }
      });
    }
  }, [current_store.user.token]);

  useEffect(() => {
    setshow_navbar(true);
    setloader(true);
    getitems("", current_store.user.token, (data) => {
      setog_item_list([
        ...data.filter((el) => !el.special_cost && el.is_addon !== 1),
      ]);
      setloader(false);
      get_discount("", current_store.user.token, (discount_data) => {
        setdiscount_list([
          ...discount_data.data.filter((el) => el.is_deleted === false),
        ]);
      });
    });
  }, []);
  return (
    <div>
      <div className="pagetitle">
        <div>Discounts</div>
      </div>
      <div className="discount_body">
        <div className="discount_search_and_addbtn">
          <SearchAndFilterBar
            placeholder={"Search for Descount"}
            filter_show={"no"}
            handlechange_search={(txt) => {
              setsearch(txt);
            }}
          />
          <div
            className="add_discount"
            onClick={() => setdiscount_add_modal(true)}
          >
            <div className="add"></div>
            Discount
          </div>
        </div>
        <Table
          cust_class={"discount_table"}
          type="type1"
          table_head_data={[
            "ACTIVE PROMOTION",
            "DESCRIPTION",
            // "ACTIVE?",
            "ACTION",
          ]}
          table_body_data={[
            ...discount_list
              .filter((el) =>
                search === "active"
                  ? el.is_active
                  : el.discount_txt.match(search) || el.desc.match(search)
              )
              .map((el) => [
                el.discount_txt,
                el.desc,
                <>
                  {/* <div
                  style={{
                    border: `1px solid var(${
                      el.is_active ? "--green" : "--red"
                    })`,
                    height: 17,
                    width: 17,
                  }}
                >
                  {el.is_active ? (
                    <Tick
                      style={{
                        height: 10,
                        width: 10,
                        "--svg_current_color": `var(--green )`,
                      }}
                    />
                  ) : (
                    <Close
                      style={{
                        height: 10,
                        width: 10,
                        "--svg_current_color": `var(--red)`,
                      }}
                    />
                  )}
                </div> */}
                  <div>
                    {is_active_api_loading ? (
                      <Loading_Icon style={{ width: "30px" }} />
                    ) : (
                      <Switcher
                        active={!el.is_active}
                        setactive={() => {
                          setis_active_api_loading(true);
                          let temp_discount_list = [...discount_list];
                          temp_discount_list = temp_discount_list.map((el1) => {
                            if (el1.id === el.id) {
                              return {
                                ...el1,
                                is_active: !el1.is_active,
                              };
                            } else {
                              return {
                                ...el1,
                              };
                            }
                          });

                          update_discount(
                            {
                              data: JSON.stringify([...temp_discount_list]),
                            },
                            current_store.user.token,
                            (data) => {
                              setdiscount_list([...temp_discount_list]);
                              // setloader(false);
                              // close();
                              setis_active_api_loading(false);
                            }
                          );
                        }}
                        style={{
                          height: 15,
                          width: 30,
                        }}
                      />
                    )}
                  </div>
                  <Pencil
                    edit={true}
                    custstyle={{
                      height: 15,
                      width: 15,
                    }}
                    clickhandel={() => {
                      setdiscount_edit_modal(true);
                      setcurrent_item({ ...el });
                    }}
                  />
                  {is_active_api_loading ? (
                    <Loading_Icon style={{ width: "30px" }} />
                  ) : (
                    <TrashIconBlack
                      style={{ height: 17 }}
                      clickhandel={() => {
                        setis_active_api_loading(true);
                        let temp_discount_list = [...discount_list];
                        temp_discount_list = temp_discount_list.map((el1) => {
                          if (el1.id === el.id) {
                            return {
                              ...el1,
                              is_deleted: true,
                            };
                          } else {
                            return {
                              ...el1,
                            };
                          }
                        });

                        update_discount(
                          {
                            data: JSON.stringify([...temp_discount_list]),
                          },
                          current_store.user.token,
                          (data) => {
                            // console.log(temp_discount_list);
                            // setdiscount_list([]);
                            // setTimeout(() => {
                            setdiscount_list([
                              ...temp_discount_list.filter(
                                (el) => el.is_deleted === false
                              ),
                            ]);
                            // }, 10);
                            // setloader(false);
                            // close();
                            setis_active_api_loading(false);
                          }
                        );
                      }}
                    />
                  )}
                </>,
                //   <MenuDots />
              ]),
          ]}
        />
      </div>
      {discount_add_modal && (
        <AddDiscountModal
          og_item_list={og_item_list}
          discount_list={discount_list}
          setdiscount_list={setdiscount_list}
          setloader={setloader}
          close={() => setdiscount_add_modal(false)}
        />
      )}
      {discount_edit_modal && (
        <EditDiscountModal
          current_item={current_item}
          og_item_list={og_item_list}
          discount_list={discount_list}
          setloader={setloader}
          setdiscount_list={setdiscount_list}
          close={() => setdiscount_edit_modal(false)}
        />
      )}
    </div>
  );
};
