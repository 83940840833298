import React, { useEffect, useState } from "react";
import "./faqStyle.scss";
import { SearchAndFilterBar } from "../../Component/Common/SearchAndFilterBar/SearchAndFilterBar";
import {
  Close,
  MenuDots,
  Pencil,
  Plus,
  Trash,
} from "../../Component/Common/Images/Images";
import { AddFAQ } from "../../Component/FAQ/AddFAQ/AddFAQ";
import { logDOM } from "@testing-library/react";
import { RTE } from "../../Component/Common/Test/Rich text editor/RTE";
import { checktoken, get_faq, update_faq } from "../../API/API";
import { EditFAQ } from "../../Component/FAQ/EditFAQ/EditFAQ";
import { DeleteFAQ } from "../../Component/FAQ/Delete/DeleteFAQ";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { og_faq } from "../../Component/FAQ/AddFAQ/og_faq_data";

const get_txt = (rte_obj) => {
  let str1 = "";
  rte_obj.map((el1) => {
    let str2 = "";
    el1.children.map((el2) => {
      if (el2.text) {
        str2 += el2.text + "";
      } else if (el2.type == "list-item") {
        let str3 = "";
        el2.children.map((el3) => (str3 += el3.text.trim() + ""));
        str2 += str3.trim() + "";
      }
    });
    str1 += str2.trim() + " ";
  });
  return str1.trim();
};

export const FAQ = ({ setshow_navbar, setloader }) => {
  const [faq_list, setfaq_list] = useState([]);
  const [filtered_faq_list, setfiltered_faq_list] = useState([]);
  const [faq_list_text, setfaq_list_text] = useState([]);
  const [faq_search, setfaq_search] = useState("");
  const [current_menu, setcurrent_menu] = useState("");
  const [faq_modal, setfaq_modal] = useState(false);
  const [faq_edit_modal, setfaq_edit_modal] = useState([false, {}]);
  const [faq_delete_modal, setfaq_delete_modal] = useState([false, {}]);
  // const [editing, setediting] = useState(false);
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [is_admin, setis_admin] = useState(false);
  useEffect(() => {
    setshow_navbar(true);

    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("jc_pos_token");
      if (local_token) {
        setloader(true);
        checktoken(local_token, (data) => {
          if (!data.status) {
            return navigate("/");
          }
          setis_admin(data.admin);
          dispatch({
            type: "login",
            payload: { token: local_token, admin: data.admin },
          });
          setloader(false);
        });
        return;
      } else {
        return navigate("/");
      }
    } else {
      // return navigate("/");
      setis_admin(current_store.user.admin);
      setloader(true);

      get_faq({ type: 2 }, "", (data) => {
        setloader(false);
        setfaq_list(data.data);
      });
    }
  }, [current_store.user.token]);
  useEffect(() => {
    if (faq_list.length > 0) {
      setfaq_list_text(
        faq_list.map((el) => ({
          ...el,
          que: get_txt(el.que),
          ans: get_txt(el.ans),
        }))
      );
    }
    setfiltered_faq_list([...faq_list]);
    setfaq_search("");
  }, [faq_list]);

  if (!current_store.user.admin) {
    // navigate("/404");
    return "";
  }
  return (
    <div className="faq">
      <img
        style={{ display: "none" }}
        src="https://app.juliettecleaners.com/static/media/logo.def22475f2efb356957a.png"
      />
      <div className="pagetitle">
        <div>FAQ</div>
      </div>
      <div className="faq_body">
        {/* <div className="faq_body_left"> */}
        <div className="faq_header">
          <SearchAndFilterBar
            placeholder="Search for Question or Answers"
            filter_show="no"
            change_search={faq_search}
            handlechange_search={(data) => {
              setfaq_search(data);

              setfiltered_faq_list([]);
              setTimeout(() => {
                setfiltered_faq_list(
                  faq_list.filter(
                    (el, i) =>
                      faq_list_text[i]?.que.match(data) ||
                      faq_list_text[i]?.ans.match(data)
                  )
                );
              }, 0);
            }}
          />
          <div
            className="btn"
            type="type2"
            onClick={() => {
              setfaq_modal(true);
              // update_faq(
              //   {
              //     // type: 2,
              //     data: JSON.stringify([...og_faq]),
              //   },
              //   current_store.user.token,
              //   (data) => {
              //     // setloader(false);
              //     // setfaq_list([]);
              //     // setTimeout(() => {
              //     //   setfaq_list([...temp_faq]);
              //     // }, 0);
              //     // close();
              //   }
              // );
            }}
          >
            <Plus white={true} custStyle={{ width: "20px", height: "20px" }} />
            <div>Q&A</div>
          </div>
        </div>
        <div className="question_answer_table">
          <div className="row heading">
            <div className="col1">Questions</div>
            <div className="col2">Answers</div>
          </div>
          {filtered_faq_list.length == 0 && "No data"}
          {filtered_faq_list
            .filter((el) => el.is_deleted != true)

            .map((el) => {
              return (
                <div className="row body">
                  <div className="col1">
                    <RTE msg={el.que} read_only={true} />
                  </div>
                  <div className="col2">
                    <RTE msg={el.ans} read_only={true} />
                  </div>
                  <div className="col3">
                    <MenuDots
                      clickhandel={() => {
                        setcurrent_menu(
                          current_menu == el.id + 1 ? "" : el.id + 1
                        );
                      }}
                    />

                    {
                      <div
                        className="menu_main"
                        active={(el.id + 1 === current_menu).toString()}
                      >
                        <div>
                          <div className="header">
                            <Close
                              type="sm"
                              clickhandel={() => {
                                setcurrent_menu("");
                              }}
                            />
                          </div>
                          <div className="body">
                            <div
                              onClick={() => {
                                setfaq_edit_modal([true, { ...el }]);
                              }}
                            >
                              <Pencil
                                edit={true}
                                custstyle={{ width: "15px", height: "15px" }}
                              />
                              <div>Edit</div>
                            </div>
                            <div
                              onClick={() => {
                                setfaq_delete_modal([true, { ...el }]);
                              }}
                            >
                              <Trash
                                custstyle={{ width: "14px", height: "16px" }}
                              />
                              <div>Delete</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              );
              // <div className="row body">
              //   <div className="col1">{el.que}</div>
              //   <div className="col2">
              //     {el.ans}
              //     <div className="menu">
              //       <MenuDots
              //         clickhandel={() => {
              //           setcurrent_menu(el.id);
              //         }}
              //       />
              //     </div>
              //     <div
              //       className="menu_main"
              //       active={(el.id === current_menu).toString()}
              //     >
              //       <div>
              //         <div className="header">
              //           <Close
              //             type="sm"
              //             clickhandel={() => {
              //               setcurrent_menu("");
              //             }}
              //           />
              //         </div>
              //         <div className="body">
              //           <div>
              //             <Pencil
              //               edit={true}
              //               custstyle={{ width: "15px", height: "15px" }}
              //             />
              //             <div>Edit</div>
              //           </div>
              //           <div>
              //             <Trash
              //               custstyle={{ width: "14px", height: "16px" }}
              //             />
              //             <div>Delete</div>
              //           </div>
              //         </div>
              //       </div>
              //     </div>
              //   </div>
              // </div>
            })}
        </div>
        {/* </div>
        <div className="faq_body_right"> */}

        {/* </div> */}
      </div>
      {faq_modal && (
        <AddFAQ
          faq_list={faq_list}
          setfaq_list={setfaq_list}
          setloader={setloader}
          close={() => {
            setfaq_modal(false);
          }}
        />
      )}
      {faq_edit_modal[0] && (
        <EditFAQ
          current_data={faq_edit_modal[1]}
          faq_list={faq_list}
          setloader={setloader}
          setfaq_list={setfaq_list}
          close={() => {
            setfaq_edit_modal([false, {}]);
          }}
        />
      )}
      {faq_delete_modal[0] && (
        <DeleteFAQ
          current_data={faq_delete_modal[1]}
          faq_list={faq_list}
          setloader={setloader}
          setfaq_list={setfaq_list}
          close={() => {
            setfaq_delete_modal([false, {}]);
          }}
        />
      )}
    </div>
  );
};
