import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  add_vendor_report,
  base64tolink,
  update_vendor_report,
} from "../../../../API/API";
import { Camera, Close } from "../../../Common/Images/Images";
// import { addcashlist, base64tolink } from "../../../API/API";
// import { Camera, Close } from "../../Common/Images/Images";
import "./vendorcashvieweditmodal.scss";

export const VendorCashInOutViewEdit = ({
  type,
  close,
  setloader,
  data,
  report_data,
  setreport_data_after_edit,
}) => {
  // console.log(report_data);
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();
  const [currentdatefull, setcurrentdatefull] = useState("");
  const [currentdatetime, setcurrentdatetime] = useState([,]);
  const [receipt, setreceipt] = useState("");
  // const [name, setname] = useState("");
  const [amount_ispositive, setamount_ispositive] = useState(true);
  const [amount, setamount] = useState("");
  const [description, setdescription] = useState("");

  // useEffect(() => {

  // }, []);

  const addcash = () => {
    if (amount == "") {
      return;
    }
    update_vendor_report(
      {
        id: report_data.id,
        vendor_id: data.id,
        img: receipt,
        amount: amount * (amount_ispositive ? 1 : -1),
        description: description,
        dateTime: report_data.date,
      },
      current_store.user.token,
      (data) => {
        // console.log(data);
        if (!data.status) {
          return navigate("/404");
        }
        close();
        setreport_data_after_edit(data.data.cashData);
        // window.location.reload();
        return;
      }
    );
  };
  useEffect(() => {
    if (type == "cashview" || type == "cashedit") {
      setamount(Math.abs(Number(report_data.amount)));
      if (Number(report_data.amount) < 0) {
        setamount_ispositive(false);
      }
      setreceipt(report_data.img);
      setdescription(report_data.description);
      let tempcurrent = new Date(report_data.date);
      setcurrentdatefull(tempcurrent);
      let [date, month, year] = [
        tempcurrent.getDate(),
        tempcurrent.getMonth() + 1,
        tempcurrent.getFullYear(),
      ];
      let [hrs, mins, unit] = [
        tempcurrent.getHours(),
        tempcurrent.getMinutes(),
        "AM",
      ];
      if (date <= 9) {
        date = `0${date}`;
      }
      if (month <= 9) {
        month = `0${month}`;
      }
      if (hrs > 12) {
        hrs = `${hrs - 12}`;
        unit = "PM";
      }
      if (hrs <= 9) {
        hrs = `0${hrs}`;
      }
      if (mins <= 9) {
        mins = `0${mins}`;
      }
      // console.log(`${date}/${month}/${year}`, `${hrs}:${mins} ${unit}`);
      setcurrentdatetime([
        `${date}/${month}/${year}`,
        `${hrs}:${mins} ${unit}`,
      ]);
    }
  }, [type, report_data]);

  return (
    <div className="vendorcashinoutvieweditmodal">
      <div>
        <div className="header">
          {/* {type} */}
          <Close clickhandel={close} />
        </div>
        <div className="body">
          <div className="left_body">
            <div className="current_date_time">
              <div>{currentdatetime[0]}</div>
              <div>{currentdatetime[1]}</div>
            </div>
            <input
              type="text"
              placeholder="Name"
              value={data.name}
              // onChange={(e) => {
              //   setname(e.target.value);
              // }}
              readOnly
            />
            <div
              className={
                "amount" +
                (!amount_ispositive ? " amount_red" : " amount_green")
              }
            >
              {amount != "" && (
                <div>
                  {amount_ispositive ? "+" : type == "cashout" ? "-" : ""}
                  &nbsp;$&nbsp;
                </div>
              )}
              <input
                id="amount_inp"
                style={amount != "" ? { textAlign: "left" } : { padding: 0 }}
                // style={{ width: "0", padding: "0" }}
                type="number"
                placeholder="Placeholder Amount"
                value={amount}
                onChange={(e) => {
                  setamount(e.target.value);
                }}
                readOnly={type === "cashview"}
              />
            </div>
            <textarea
              rows={Math.floor(description.length / 21) + 1}
              type="text"
              placeholder="Notes"
              value={description}
              onChange={(e) => {
                setdescription(e.target.value);
              }}
              readOnly={type === "cashview"}
            />

            <label
              className="receipt"
              style={receipt ? { borderColor: "var(--black)" } : {}}
            >
              <Camera />
              Receipt
              {type === "cashedit" && (
                <input
                  type="file"
                  accept=".jpeg,.png"
                  onChange={(e) => {
                    var reader = new FileReader();
                    setloader(true);
                    reader.readAsDataURL(e.target.files[0]);
                    reader.onload = function () {
                      // temp_arr.push(reader.result)
                      base64tolink(
                        {
                          base_64: reader.result.split(",")[1],
                          name: `vendorcash_img_${new Date()
                            .valueOf()
                            .toString()}.jpeg`,
                        },
                        current_store.user.token,
                        (data) => {
                          if (!data.status) {
                            setloader(false);
                            return navigate("/");
                          }
                          setloader(false);
                          setreceipt(data.link + "?" + new Date());
                          setTimeout(() => {
                            let receipt_img =
                              document.getElementById("receipt_img1");
                            let receipt_img_rect =
                              receipt_img.getBoundingClientRect();
                            if (
                              receipt_img_rect.height > receipt_img_rect.width
                            ) {
                              receipt_img.style.height = "100%";
                              receipt_img.style.width = "auto";
                            } else {
                              receipt_img.style.width = "100%";
                              receipt_img.style.height = "auto";
                            }
                          }, 100);
                        }
                      );
                    };
                  }}
                />
              )}
            </label>
            <div
              style={{
                height: "250px",
                textAlign: "center",
                border: "1px solid var(--black)",
                borderBottomLeftRadius: "var(--border-radius-4)",
                borderBottomRightRadius: "var(--border-radius-4)",
                overflow: "hidden",
                margin: "-4px 0 0 0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {receipt ? <img src={receipt} id="receipt_img1" /> : "No Image"}
            </div>
            {type == "cashedit" ? (
              <div
                className="cashinout_btn btn"
                type={
                  Math.abs(Number(amount)) ==
                    Math.abs(Number(report_data.amount)) &&
                  description == report_data.description &&
                  receipt == report_data.img
                    ? "type1"
                    : "type2"
                }
                style={{
                  fontSize: "14px",
                  lineHeight: "18px",
                }}
                onClick={() => {
                  if (
                    Math.abs(Number(amount)) ==
                      Math.abs(Number(report_data.amount)) &&
                    description == report_data.description &&
                    receipt == report_data.img
                  ) {
                    return;
                  } else {
                    addcash();
                  }
                }}
              >
                Save
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
