import React, { useState } from "react";
import "./pricelistaccordian.scss";
import { PricelistAccordianCardModal } from "./PricelistAccordianCardModal/PricelistAccordianCardModal";
import arrow_down from "../../../img/miscellaneous/accordian_arrow_down.png";
import add_item_icon from "../../../img/cards/add_items_icon.svg";

import { pricelist } from "../../../Component/dummydata";
export const PricelistAccordian = ({
  pricelist1,
  pricelist_subsection,
  order_item_cards,
  setorder_item_cards,
  discountlist_current,
}) => {
  const [showing_custom_pricelist, setshowing_custom_pricelist] = useState([]);
  const [showing_custom_pricelist_modal, setshowing_custom_pricelist_modal] =
    useState(false);
  const [acc_card_modal, setacc_card_modal] = useState(false);
  const [card_data, setcard_data] = useState({});
  const toggelaccordian = (acc_id, child) => {
    let current_acc_body = document.getElementById(acc_id);
    let arrow_id = document.getElementById(`arrow_${acc_id}`);
    let current_acc_body_getheight = document.getElementById(
      `${acc_id}_getheight`
    );
    if (current_acc_body.style.height == "") {
      current_acc_body.style.height = `${current_acc_body_getheight.clientHeight}px`;
      arrow_id.className = "arrow active";
      if (child) {
        setTimeout(() => {
          current_acc_body.style.height = "fit-content";
        }, 400);
      }
    } else {
      current_acc_body.style.height = `${current_acc_body_getheight.clientHeight}px`;
      arrow_id.className = "arrow ";
      if (child) {
        setTimeout(() => {
          current_acc_body.style.height = "";
        }, 0);
      } else {
        current_acc_body.style.height = ``;
      }
    }
  };
  const setting_modal_and_data = (data) => {
    setacc_card_modal(true);
    setcard_data({ ...data });
  };
  return (
    <div className="pricelistaccordian">
      {pricelist1.map((el, i) => {
        if (el.is_addon === 1) {
          return "";
        }
        if (el.child) {
          return (
            <div className="accordian_main">
              <div
                className="accordian_title"
                onClick={() =>
                  toggelaccordian(
                    el.title.toLowerCase().split(" ").join(""),
                    true
                  )
                }
              >
                {el.title}
                <div
                  className="arrow"
                  id={`arrow_${el.title.toLowerCase().split(" ").join("")}`}
                >
                  <img src={arrow_down} />
                </div>
              </div>
              <div
                className="accordian_mainbody"
                id={el.title.toLowerCase().split(" ").join("")}
              >
                <div
                  id={`${el.title.toLowerCase().split(" ").join("")}_getheight`}
                >
                  {el.child.map((el1) => (
                    <div className="accordian_main">
                      <div
                        className="accordian_title pt"
                        onClick={() =>
                          toggelaccordian(
                            `${el.title
                              .toLowerCase()
                              .split(" ")
                              .join("")}_${el1.acc_title
                              .toLowerCase()
                              .split(" ")
                              .join("")}`
                          )
                        }
                      >
                        {el1.acc_title} ({el1.acc_body.length})
                        <div
                          className="arrow"
                          id={`arrow_${el.title
                            .toLowerCase()
                            .split(" ")
                            .join("")}_${el1.acc_title
                            .toLowerCase()
                            .split(" ")
                            .join("")}`}
                        >
                          <img src={arrow_down} />
                        </div>
                      </div>
                      <div
                        className="accordian_body"
                        id={`${el.title
                          .toLowerCase()
                          .split(" ")
                          .join("")}_${el1.acc_title
                          .toLowerCase()
                          .split(" ")
                          .join("")}`}
                      >
                        <div
                          className="accordian_body_sub"
                          id={`${el.title
                            .toLowerCase()
                            .split(" ")
                            .join("")}_${el1.acc_title
                            .toLowerCase()
                            .split(" ")
                            .join("")}_getheight`}
                        >
                          {el1.acc_body.map((el2) => (
                            <div
                              onClick={() =>
                                setting_modal_and_data({
                                  title: `${el.title}%ITEMS_NAME_DIVIDER%${el1.acc_title}%ITEMS_NAME_DIVIDER%${el2[0]}`,
                                  price: el2[1],
                                })
                              }
                            >
                              <div>{el2[0]}</div>
                              <div>${el2[1]}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          );
        } else {
          return (
            <div className="accordian_main">
              <div
                className="accordian_title"
                onClick={() =>
                  toggelaccordian(el.title.toLowerCase().split(" ").join(""))
                }
              >
                {el.title}
                {/* ({el.acc_body.length}) */}
                <div
                  className="arrow"
                  id={`arrow_${el.title.toLowerCase().split(" ").join("")}`}
                >
                  <img src={arrow_down} />
                </div>
              </div>
              <div
                className="accordian_body"
                id={el.title.toLowerCase().split(" ").join("")}
              >
                <div
                  className="accordian_body_sub"
                  id={`${el.title.toLowerCase().split(" ").join("")}_getheight`}
                >
                  {el.acc_body.map((el1) => (
                    <>
                      <div
                        onClick={() => {
                          setting_modal_and_data({
                            title: `${el.title}%ITEMS_NAME_DIVIDER%${el1[0]}`,
                            price: el1[2] ? [el1[1], el1[2]] : el1[1],
                          });
                        }}
                      >
                        <div>{el1[0]}</div>
                        <div>${el1[1].split(" ")[0]}</div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          );
        }
      })}
      <div>
        {showing_custom_pricelist.map((el) => (
          <div
            onClick={() => {
              setting_modal_and_data({
                title: `${el.title}`,
                price: el.price[2] ? [el.price[1], el.price[2]] : el.price[1],
              });
            }}
          >
            <div>{el.title}</div>
            <div>{el.price[2] ? [el.price[1], el.price[2]] : el.price[1]}</div>
          </div>
        ))}
        <div
          className="other_card_data"
          onClick={() => {
            setting_modal_and_data({
              price: "cust",
              title: "cust",
            });
            // setshowing_custom_pricelist_modal(true)
            // setting_modal_and_data({})

            // setshowing_custom_pricelist([...showing_custom_pricelist,])
          }}
        >
          <img src={add_item_icon} />
        </div>

        {/* {
          showing_custom_pricelist_modal ?
            <div className="showing_custom_pricelist_modal">
              <div>
                <div className="header">
                  <img
                    className="close"
                    onClick={() => { setshowing_custom_pricelist_modal(false) }}
                    src={close_icon}
                  />
                </div>
                <div className="body">

                  <div className="cust_switch">
                    <input type='checkbox' id='custom_pricelist_type'
                      checked={custom_pricelist_type}
                      onChange={() => {
                        if (custom_pricelist_type) {
                          setcustom_pricelist_data({
                            ...custom_pricelist_data,
                            price: [...custom_pricelist_data.price[0], '', '']
                          })
                        }
                        setcustom_pricelist_type(!custom_pricelist_type);
                      }} />
                    <label htmlFor="custom_pricelist_type">per lb</label>
                  </div>
                  <div><input type='text' placeholder="enter label name" /></div>
                  {
                    custom_pricelist_type &&
                    <>
                      <div className="singleprice_cust"><input type='number' placeholder="enter label price" />
                        <div>
                          for
                        </div>
                        <input type='number' placeholder="enter weight" />
                        <div>
                          lb
                        </div>
                      </div>
                    </>
                  }
                  <div className="singleprice_cust">
                    <input type='number' placeholder="enter label price for single item" style={{ width: '100%' }} />
                    <div>$</div>
                  </div>

                </div>
                <div className="footer">
                  <div className="btn" type='type1'>Add</div>
                </div>
              </div>
            </div> 
            : ''
        } */}
      </div>
      {acc_card_modal && (
        <PricelistAccordianCardModal
          pricelist_subsection={pricelist_subsection}
          order_item_cards={order_item_cards}
          setorder_item_cards={setorder_item_cards}
          card_data={card_data}
          setacc_card_modal={setacc_card_modal}
          discountlist_current={discountlist_current}
        />
      )}
    </div>
  );
};
