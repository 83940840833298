import React, { useEffect, useState } from "react";
import "./navbarStyle.scss";
import { ReactComponent as Order_icon } from "../../../img/navbarIcons/order_icon.svg";
import { ReactComponent as Items_icon } from "../../../img/navbarIcons/items_icon.svg";
import { ReactComponent as Discount_icon } from "../../../img/navbarIcons/discount_icon.svg";
import { ReactComponent as Draft_order_icon } from "../../../img/navbarIcons/draft_order_icon.svg";
import { ReactComponent as Client_icon } from "../../../img/navbarIcons/client_icon.svg";
import { ReactComponent as Cash_icon } from "../../../img/navbarIcons/cash_icon.svg";
import { ReactComponent as Faq_icon } from "../../../img/navbarIcons/faq_icon.svg";
import { ReactComponent as Gear_icon } from "../../../img/navbarIcons/gear_icon.svg";
import { ReactComponent as Logout_icon } from "../../../img/navbarIcons/logout_icon.svg";
import { ReactComponent as Vendor_icon } from "../../../img/navbarIcons/vendor_icon.svg";
import { ReactComponent as Team_icon } from "../../../img/navbarIcons/team_icon.svg";
import { ReactComponent as Reports_icon } from "../../../img/navbarIcons/reports_icon.svg";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { navbar_status } from "../../../App";

export const Navbar = ({
  show,
  current_client_page_showing,
  setcurrent_client_page_showing,
}) => {
  const current_store = useSelector((state) => state);

  const navigate = useNavigate();
  const [current_pathname, setcurrent_pathname] = useState("");
  const dispatch = useDispatch();
  useEffect(() => {
    setcurrent_pathname(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <div className="navbar" id="navbar" shownavbar={show}>
      <div className="navbar_main">
        <div
          className="menu"
          onClick={() => {
            document.getElementById("navbar").classList.toggle("navbar_active");
          }}
        >
          <div className="menu_bar bar1"></div>
          <div className="menu_bar bar2"></div>
          <div className="menu_bar bar3"></div>
        </div>
        {navbar_status.client && (
          <div
            className={
              "nav_tab" +
              (current_pathname == "/clients" ? " nav_tab_active_stroke" : "")
            }
            onClick={() => {
              if (current_client_page_showing !== "default") {
                setcurrent_client_page_showing("default");
              }
              navigate("/clients");
            }}
            // onClick={() => navigate("/")}
          >
            <div className="svg_icon">
              <Client_icon />
            </div>
            <div className="nav_tab_txt">Clients</div>
          </div>
        )}
        {navbar_status.team && current_store.user.admin && (
          <div
            className={
              "nav_tab" +
              (current_pathname == "/team" ? " nav_tab_active_fill" : "")
            }
            onClick={() => navigate("./team")}
          >
            <div className="svg_icon">
              <Team_icon />
            </div>
            <div className="nav_tab_txt">Team</div>
          </div>
        )}
        {navbar_status.orders && (
          <div
            className={
              "nav_tab" +
              (current_pathname == "/orders" ? " nav_tab_active_fill" : "")
            }
            onClick={() => navigate("./orders")}
          >
            <div className="svg_icon">
              <Order_icon />
            </div>
            <div className="nav_tab_txt">Orders</div>
          </div>
        )}
        {navbar_status.items && (
          <div
            className={
              "nav_tab" +
              (current_pathname == "/items" ? " nav_tab_active_stroke" : "")
            }
            onClick={() => navigate("./items")}
          >
            <div className="svg_icon">
              <Items_icon />
            </div>
            <div className="nav_tab_txt">Items</div>
          </div>
        )}
        {navbar_status.discount && (
          <div
            className={
              "nav_tab" +
              (current_pathname == "/discount" ? " nav_tab_active_fill" : "")
            }
            onClick={() => navigate("./discount")}
          >
            <div className="svg_icon">
              <Discount_icon />
            </div>
            <div className="nav_tab_txt">Discount</div>
          </div>
        )}
        {/* <div
          className={
            "nav_tab" +
            (current_pathname == "/draft-orders" ? " nav_tab_active_fill" : "")
          }
          onClick={() => navigate("./draft-orders")}
          >
          <div className="svg_icon">
            <Draft_order_icon />
            </div>
          <div className="nav_tab_txt">Draft Orders</div>
        </div> */}
        {navbar_status.cash && (
          <div
            className={
              "nav_tab" +
              (current_pathname == "/cash" ? " nav_tab_active_stroke" : "")
            }
            onClick={() => navigate("./cash")}
          >
            <div className="svg_icon">
              <Cash_icon />
            </div>
            <div className="nav_tab_txt">Cash</div>
          </div>
        )}
        {navbar_status.vendor && current_store.user.admin && (
          <div
            className={
              "nav_tab" +
              (current_pathname == "/vendor" ? " nav_tab_active_stroke" : "")
            }
            onClick={() => navigate("./vendor")}
          >
            <div className="svg_icon">
              <Vendor_icon />
            </div>
            <div className="nav_tab_txt">Vendors</div>
          </div>
        )}
        {navbar_status.reports && current_store.user.admin && (
          <div
            className={
              "nav_tab" +
              (current_pathname == "/reports" ? " nav_tab_active_fill" : "")
            }
            onClick={() => navigate("./reports")}
          >
            <div className="svg_icon">
              <Reports_icon />
            </div>
            <div className="nav_tab_txt">Reports</div>
          </div>
        )}
        {navbar_status.faq && current_store.user.admin && (
          <div
            className={
              "nav_tab" +
              (current_pathname == "/faq" ? " nav_tab_active_fill" : "")
            }
            onClick={() => navigate("./faq")}
          >
            <div className="svg_icon">
              <Faq_icon />
            </div>
            <div className="nav_tab_txt">FAQ</div>
          </div>
        )}
        <div
          className="nav_tab logout"
          onClick={() => {
            localStorage.setItem("jc_pos_token", "");
            dispatch({
              type: "logout",
            });
            navigate("/");
          }}
        >
          <div className="svg_icon">
            <Logout_icon />
          </div>
          <div className="nav_tab_txt">Logout</div>
        </div>
      </div>
    </div>
  );
};
