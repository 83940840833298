import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checktoken, get_vendors, mobile_convert_display } from "../../API/API";
import { Cards } from "../../Component/Common/Cards/Cards";
import {
  Close,
  Eye,
  MenuDots,
  Pencil,
  Plus,
  Trash,
} from "../../Component/Common/Images/Images";
import { SearchAndFilterBar } from "../../Component/Common/SearchAndFilterBar/SearchAndFilterBar";
import { Table } from "../../Component/Common/Table/Table";
import { AddVendor } from "../../Component/Vendors/Add_Vendor/AddVendor";
import { Delete_Vendor } from "../../Component/Vendors/Delete_Vendor/Delete_Vendor";
import { EditVendor } from "../../Component/Vendors/Edit_Vendor/EditVendor";
import { VendorTableSidepanel } from "../../Component/Vendors/VendorTableSidepanel/VendorTableSidepanel";
import { ViewVendor } from "../../Component/Vendors/View_Vendor/ViewVendor";
import "./vendorStyle.scss";

const vendor_dummy = [
  {
    name: "Ken Kent",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Leather",
  },
  {
    name: "Jim Newton",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Bridal",
  },
  {
    name: "Ken Kent",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Silk",
  },
  {
    name: "Jim Newton",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Leather",
  },
  {
    name: "Ken Kent",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Bridal",
  },
  {
    name: "Jim Newton",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Silk",
  },
  {
    name: "Ken Kent",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Bridal",
  },
  {
    name: "Jim Newton",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Silk",
  },
  {
    name: "Ken Kent",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Leather",
  },
  {
    name: "Jim Newton",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Bridal",
  },
  {
    name: "Ken Kent",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Leather",
  },
  {
    name: "Jim Newton",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Bridal",
  },
  {
    name: "Ken Kent",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Leather",
  },
  {
    name: "Jim Newton",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Bridal",
  },
  {
    name: "Ken Kent",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Leather",
  },
  {
    name: "Jim Newton",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Bridal",
  },
  {
    name: "Ken Kent",
    add: "56th East 7th NY 2678",
    mobile: 6465256754,
    note: "Leather",
  },
];

export const Vendor = ({ setshow_navbar, setloader }) => {
  const [vendor_table, setvendor_table] = useState([
    // ...vendor_dummy
  ]);
  const [menu_showing, setmenu_showing] = useState("");
  const [vendor_reports, setvendor_reports] = useState([]);
  const [vendor_table_mini, setvendor_table_mini] = useState(false);
  const [search, setsearch] = useState("");
  const [selected_vendor, setselected_vendor] = useState({});
  const [add_modal, setadd_modal] = useState(false);
  const [view_modal, setview_modal] = useState(false);
  const [edit_modal, setedit_modal] = useState(false);
  const [delete_modal, setdelete_modal] = useState(false);
  const [vendor_sidepanel_nav_to, setvendor_sidepanel_nav_to] = useState("");
  const [vendor_cards, setvendor_cards] = useState([0, 0, 0]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const current_store = useSelector((state) => state);

  useEffect(() => {
    setshow_navbar(true);
    if (current_store.user.token == "") {
      let local_token = localStorage.getItem("jc_pos_token");
      if (local_token) {
        setloader(true);
        checktoken(local_token, (data) => {
          if (!data.status) {
            return navigate("/");
          }
          //   setis_admin(data.admin);
          dispatch({
            type: "login",
            payload: { token: local_token, admin: data.admin },
          });
          setloader(false);
        });
        return;
      } else {
        return navigate("/");
      }
    } else {
      // return navigate("/");
      //   setis_admin(current_store.user.admin);
      //   setloader(true);
      setloader(true);
      get_vendors("", current_store.user.token, (data) => {
        if (!data.status) {
          setloader(false);
          return navigate("/");
        }
        // console.log(data.data);
        setvendor_reports([...data.data.vendor_report]);
        setvendor_table(
          data.data.vendors.map((el) => ({
            id: el.id,
            name: el.name,
            add: el.address,
            mobile: el.mobile_no,
            note: el.notes,
          }))
        );
        setloader(false);
      });
    }
  }, [current_store.user.token]);
  useEffect(() => {
    setshow_navbar(true);
  }, []);
  useEffect(() => {
    let [positive_amount, negative_amount] = [0, 0];
    // console.log(selected_vendor, vendor_reports );
    if (Object.keys(selected_vendor).length == 0) {
      vendor_reports.map((el) => {
        // console.log(el.amount);
        if (el.amount === null) {
          return;
        }
        if (el.amount < 0) {
          negative_amount += Math.abs(el.amount);
        } else {
          positive_amount += Math.abs(el.amount);
        }
      });
    } else {
      vendor_reports
        .filter((el) => el.vendor_id === selected_vendor.id)
        .map((el) => {
          // console.log(el.amount);
          if (el.amount === null) {
            return;
          }
          if (el.amount < 0) {
            negative_amount += Math.abs(el.amount);
          } else {
            positive_amount += Math.abs(el.amount);
          }
        });
    }
    // console.log([positive_amount, negative_amount]);
    setvendor_cards([
      negative_amount,
      positive_amount,
      negative_amount - positive_amount,
    ]);
  }, [vendor_reports, selected_vendor]);

  const setreport_data_after_edit = (arr) => {
    // console.log(arr);
    setvendor_reports([...arr]);
    // setvendor_sidepanel_nav_to("view");
  };
  // console.log(vendor_table);
  const new_format = true;
  return (
    <div className="vendor">
      <div
        className="pagetitle"
        onClick={() => {
          setvendor_sidepanel_nav_to("");
          setvendor_table_mini(false);
          setselected_vendor({});
        }}
      >
        <div>Vendors</div>
      </div>
      <div className="vendor_body">
        {new_format && (
          <Cards
            card_arr={[
              {
                title: "Total Invoices Raised",
                value: (
                  <div style={{ color: "var(--red)" }}>
                    $<CountUp end={vendor_cards[0]} />
                  </div>
                ),
              },
              {
                title: "Total Payments made",
                // value: `$${vendor_cards[1]}`,
                value: (
                  <div style={{ color: "var(--green)" }}>
                    $
                    <CountUp end={vendor_cards[1]} />
                  </div>
                ),
              },
              {
                title: "Current Outstanding",
                // value: `$${vendor_cards[2]}`,
                value: (
                  <div
                    style={{
                      color:
                        vendor_cards[2] < 0 ? "var(--green)" : "var(--red)",
                    }}
                  >
                    $
                    <CountUp end={Math.abs(vendor_cards[2])} />
                  </div>
                ),
              },
            ]}
            last_card={{
              title: "Vendor",
              click: () => setadd_modal(true),
            }}
          />
        )}
        <div>
          <div className="vendor_search_and_add">
            <SearchAndFilterBar
              placeholder="Search for Number, Date, Name, Mail,..."
              filter_show="no"
              handlechange_search={(data) => {
                setsearch(data);
                // handle_client_table_search(data);
              }}
            />
            {/* <div
              className="add_vendor_btn"
              onClick={() => {
                setadd_modal(true);
                // setaddteammodal(true);
              }}
            >
              <Plus white={true} custStyle={{ height: 25, width: 25 }} />
              Vendor
            </div> */}
          </div>
          <div className="vendor_table_to_view">
            <Table
              cust_class={
                "vendor_table" + (vendor_table_mini ? " vendor_table_mini" : "")
              }
              type="type1"
              table_head_data={["NAME", "MOBILE", "NOTE", ""]}
              table_body_data={[
                ...vendor_table
                  .filter(
                    (el) =>
                      el.name.toLowerCase().match(search.toLowerCase()) ||
                      el.note.toLowerCase().match(search.toLowerCase()) ||
                      el.mobile.toLowerCase().match(search.toLowerCase())
                  )
                  .map((el, i) => {
                    return [
                      <div
                        className={
                          "name" +
                          (selected_vendor.id === el.id ? " active" : "")
                        }
                        onClick={() => {
                          if (vendor_table_mini) {
                            setselected_vendor({ ...el });
                          }
                        }}
                      >
                        {el.name}
                      </div>,
                      mobile_convert_display(el.mobile),
                      el.note,
                      <div className="vendor_table_menudots">
                        <MenuDots clickhandel={() => setmenu_showing(i + 1)} />
                        <div
                          className={
                            "vendor_table_menu" +
                            (i + 1 === menu_showing ? " active" : "")
                          }
                        >
                          <div className="header">
                            <Close
                              type="sm"
                              clickhandel={() => setmenu_showing("")}
                            />
                          </div>
                          <div className="body">
                            <div
                              className="items"
                              onClick={() => {
                                setselected_vendor({ ...el });
                                setvendor_table_mini(true);
                                // setview_modal(true);
                                setvendor_sidepanel_nav_to("view");
                                setmenu_showing("");
                              }}
                            >
                              <div>
                                <Eye />
                              </div>
                              <div>View</div>
                            </div>
                            <div
                              className="items"
                              onClick={() => {
                                setselected_vendor({ ...el });
                                setvendor_table_mini(true);
                                // setedit_modal(true);
                                setvendor_sidepanel_nav_to("edit");
                                setmenu_showing("");
                              }}
                            >
                              <div>
                                <Pencil edit={true} />
                              </div>
                              <div>Edit</div>
                            </div>
                            <div
                              className="items"
                              onClick={() => {
                                setselected_vendor({ ...el });
                                setdelete_modal(true);
                                // vendor_sidepanel_nav_to('delete')
                                setmenu_showing("");
                              }}
                            >
                              <div>
                                <Trash />
                              </div>
                              <div>Delete</div>
                            </div>
                          </div>
                        </div>
                      </div>,
                    ];
                  }),
              ]}
            />
            <div className="vendor_modal_options">
              <VendorTableSidepanel
                vendor_reports={vendor_reports}
                setvendor_table_mini={setvendor_table_mini}
                setvendor_table={setvendor_table}
                setloader={setloader}
                selected_vendor={selected_vendor}
                vendor_sidepanel_nav_to={vendor_sidepanel_nav_to}
                setvendor_sidepanel_nav_to={setvendor_sidepanel_nav_to}
                setreport_data_after_edit={setreport_data_after_edit}
              />
            </div>
          </div>
        </div>
      </div>
      {add_modal && (
        <AddVendor
          setloader={setloader}
          setvendor_table={setvendor_table}
          close={() => {
            setadd_modal(false);
          }}
        />
      )}
      {view_modal && (
        <ViewVendor
          data={selected_vendor}
          close={() => {
            setview_modal(false);
          }}
        />
      )}
      {edit_modal && (
        <EditVendor
          setloader={setloader}
          setvendor_table={setvendor_table}
          data={selected_vendor}
          close={() => {
            setedit_modal(false);
          }}
        />
      )}
      {delete_modal && (
        <Delete_Vendor
          setloader={setloader}
          data={selected_vendor}
          setvendor_table={setvendor_table}
          close={() => {
            setdelete_modal(false);
          }}
        />
      )}
    </div>
  );
};
