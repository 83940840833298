import { type } from "@testing-library/user-event/dist/type";
import React, { useEffect, useState } from "react";
import { Close } from "../../Common/Images/Images";
import { CustDatePicker } from "../../Custom/CustDatePicker/CustDatePicker";
import "./clientfilterStyle.scss";

export const ClientFilter = ({
  client_table_filter,
  setclient_table_filter,
  close,
}) => {
  const [filters, setfilters] = useState({
    is_archived: 0,
    date: "",
  });
  useEffect(() => {
    setfilters({
      ...client_table_filter,
    });
  }, [client_table_filter]);

  const handel_addfilter = () => {};
  // console.log(client_table_filter);
  return (
    <div className="clientfilter">
      <div className="clientfilter_main">
        <div className="header">
          <Close
            clickhandel={() => {
              close();
            }}
          />
        </div>
        <div className="body">
          <div
            className="cust_inp_checkbox"
            onClick={() => {
              setfilters((prev) => ({
                ...prev,
                is_archived: prev.is_archived === 0 ? 1 : 0,
              }));
            }}
          >
            <div
              className={
                "cust_checkbox" + (filters.is_archived === 1 ? " active" : "")
              }
            ></div>
            <div className="checkbox_title">Archived</div>
          </div>
          {/* <div
            className="cust_inp_checkbox"
            onClick={() => {
              setclient_table_filter((prev) => ({ ...prev, is_archived: 0 }));
            }}
          >
            <div
              className={
                "cust_checkbox" +
                (client_table_filter.is_archived === 0 ? " active" : "")
              }
            ></div>
            <div className="checkbox_title">Un Archived</div>
          </div> */}
          <div className="date_selector">
            <div className="title">Date</div>
            {/* <Calendar /> */}
            <CustDatePicker
              defaultplaceholder="Choose Date"
              value={filters.date}
              custid="cust1"
              setvalue={(data) => {
                setfilters((prev) => ({ ...prev, date: data }));
              }}
            />
          </div>
        </div>
        <div className="footer">
          <div
            className="btn"
            type={
              filters.is_archived !== client_table_filter.is_archived ||
              filters.date !== client_table_filter.date
                ? "type2"
                : "type1"
            }
            onClick={() => {
              setclient_table_filter({
                ...filters,
              });
            }}
          >
            Add filter
          </div>
        </div>
      </div>
    </div>
  );
};
