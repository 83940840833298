import React from "react";
import { Close } from "../../../Component/Common/Images/Images";
import "./pickupfailedStyle.scss";

export const FailedModal = ({ close, to_show }) => {
  return (
    <div className="pickupfailed">
      {/* <div className="main"> */}
      <div className="header">
        <Close clickhandel={() => close()} />
      </div>
      <div className="body">
        {to_show.map((el) => (
          <div className="menu_items" onClick={el.handel}>
            {el.ele}
            <div>{el.name}</div>
          </div>
        ))}
        {/* <div className="btn" type="type2">
            Re-Start Pickup
          </div>
          <div className="btn" type="type2">
            Complete Pickup
          </div>
          <div className="btn" type="type2">
            Delete
          </div> */}
      </div>
      {/* </div> */}
    </div>
  );
};
