import React, { useState } from "react";
import "./addorderimagesStyle.scss";

import { AddOrderImagesModal } from "./AddOrderImagesModal/AddOrderImagesModal";

import image_add_img from "../../../img/miscellaneous/close_square_add.png";
import camera from "../../../img/miscellaneous/camera.png";
import delete_img_note from "../../../img/miscellaneous/trash.png";
import { base64tolink, save_items } from "../../../API/API";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const AddOrderImages = ({
  data,
  order_item_cards,
  setadd_order_current_showing,
  images,
  setimages,
  globaldiscount,
  setglobaldiscount,
  discount_switch,
  setdiscount_switch,
  discount_switch_value,
  setdiscount_switch_value,
  sub_total,
  loader,
  setloader,
  setsubmit_btn,
  discountlist_current,
}) => {
  const [current_image_index, setcurrent_image_index] = useState("");
  const [image_modal, setimage_modal] = useState(false);
  const [show_discount_desc, setshow_discount_desc] = useState(false);
  const navigate = useNavigate();
  const current_store = useSelector((state) => state);
  const add_img = (current, i) => {
    if (current.target.files.length) {
      let temp_arr = [];
      setloader(true);
      Object.values(current.target.files).map((el, img_num) => {
        var reader = new FileReader();
        reader.readAsDataURL(el);
        reader.onload = function () {
          base64tolink(
            {
              base_64: reader.result.split(",")[1],
              name:
                data.order_id +
                "_" +
                i +
                "_" +
                (img_num + 1) +
                "-" +
                new Date().getTime() +
                ".jpeg",
            },
            current_store.user.token,
            (data) => {
              if (!data.status) {
                // navigate("/orders");
                return;
              }
              temp_arr.push(data.link);
              if (current.target.files.length == temp_arr.length) {
                let temp_images = [...images];
                temp_images[i].img = [...temp_arr];
                setimages([...temp_images]);
                setloader(false);
              }
            }
          );
        };
      });
    }
  };
  const handle_change_note = (data, i) => {
    let temp_images = [...images];
    temp_images[i].note = data;
    setimages([...temp_images]);
  };

  return (
    <div className="addorderimages">
      <div className="addorderimages_title">Customer Image/Note</div>
      <div className="addorderimages_group">
        {images.map((el, i) => {
          if (i == 0 && el.img.length == 0 && el.notes.length == 0) {
            return (
              <div div className="images_set_main_body">
                <div className="addorderimages_img_set">
                  <div className="images">
                    <img src="" />
                    <div
                      className="images_overlay"
                      onClick={() => {
                        // setimage_modal(true)
                        // setcurrent_image_index(i)
                      }}
                    >
                      0
                    </div>
                  </div>
                </div>
                <textarea
                  // value={el.notes}
                  value="No Note"
                />
              </div>
            );
          }
          return (
            <>
              {i == 1 ? (
                <div className="addorderimages_title">Add Image/Note</div>
              ) : (
                ""
              )}
              <div div className="images_set_main_body">
                <div className="addorderimages_img_set">
                  {el.img.length > 0 ? (
                    <div className="images">
                      <img src={el.img[0]} />
                      <div
                        className="images_overlay"
                        onClick={() => {
                          setimage_modal(true);
                          setcurrent_image_index(i);
                        }}
                      >
                        {el.img.length}
                      </div>
                    </div>
                  ) : (
                    <>
                      <input
                        type="file"
                        accept=".jpg,.png"
                        id={"add_img" + i}
                        onChange={(e) => add_img(e, i)}
                        multiple
                      />
                      <label htmlFor={"add_img" + i}>
                        <img className="camera" src={camera} />
                      </label>
                    </>
                  )}
                </div>
                {/* not completed check mtlp EDITMODAL.JS line 725  */}
                {/* <label
                                    className=" t-white"
                                    mouse="pointer"
                                    htmlFor="addphoto"
                                    onClick={() => { }}
                                    >
                                    + Add photo
                                </label> */}
                <textarea
                  value={el.note || el.notes}
                  placeholder="Add Note"
                  style={
                    i == 0
                      ? {
                          // pointerEvents: "none",
                        }
                      : {}
                  }
                  onChange={(e) => {
                    if (i == 0) return;
                    handle_change_note(e.target.value, i);
                  }}
                />
                <div className="delete_img_note">
                  <img
                    src={delete_img_note}
                    style={{ visibility: i == 0 ? "hidden" : "visible" }}
                    onClick={() => {
                      if (i == 0) return;
                      let temp_images = [...images];
                      temp_images.splice(i, 1);
                      setimages([...temp_images]);
                    }}
                  />
                </div>
              </div>
            </>
          );
        })}
        <div className="addorderimages_icon">
          <img
            src={image_add_img}
            onClick={() => {
              setimages([...images, { img: [], note: "" }]);
            }}
          />
        </div>
      </div>
      <div className="global_discount">
        <div>
          <div className="addorderimages_title">Add Discount</div>
          <div className="discount_group">
            {/* <input type="checkbox" id="globaldiscountswitchdollar_to_percent" /> */}
            <div
              className="discountinput_group"
              show={discount_switch ? "dollar" : "percentage"}
            >
              <div>$</div>
              <input
                placeholder="Amount"
                type="number"
                value={discount_switch_value}
                onChange={(e) => {
                  setdiscount_switch_value(e.target.value);
                }}
              />
              <div>%</div>
            </div>
            <div className="switch_convert_dollar_and_percent">
              <div
                className="main_bg"
                onClick={() => {
                  setdiscount_switch(!discount_switch);
                }}
              >
                <div
                  className="slider_btn"
                  show={discount_switch ? "dollar" : "percentage"}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="customer_discount_code">
            {
              data.discount_code ? (
                <>
                  <div>Customer Added Code:</div>
                  <div className="discount_txt">{data.discount_code}</div>
                  {discountlist_current.discount_value.length > 0 && (
                    <div
                      className="discount_info"
                      onClick={() => setshow_discount_desc(true)}
                      onMouseLeave={() => setshow_discount_desc(false)}
                    >
                      i
                    </div>
                  )}
                  {show_discount_desc && (
                    <>
                      <div className="discount_info_modal">
                        <div>
                          <div>Description</div>
                          <div>{discountlist_current.desc}</div>
                        </div>
                        <div>
                          <div>Amount</div>
                          <div>
                            {!discountlist_current.is_percentage && "$"}
                            {discountlist_current.is_percentage
                              ? Number(discountlist_current.amount)
                              : Number(discountlist_current.amount).toFixed(2)}
                            {discountlist_current.is_percentage && "%"}
                          </div>
                        </div>
                      </div>
                      <div className="discount_info_modal_arrow"></div>
                    </>
                  )}
                </>
              ) : (
                <></>
              )
              // 'no discount code'
            }
          </div>
          {/* <div className='addorderimages_title'>
                        Customer Discount
                    </div>
                    <div className='custdropdown_discount_code'>
                        <div className='custdropdown_discount_code_body' show={discount_switch ? 'dollar' : 'percentage'}>
                            <div >
                                $
                            </div>
                            <input placeholder='Choose Amount' type='number' value={discount_switch_value} onChange={(e) => { setdiscount_switch_value(e.target.value) }} />
                            <div >
                                %
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
      <div className="image_btn_group">
        <div
          className="btn"
          type="type2"
          onClick={() => setadd_order_current_showing("add_order_menu_tags")}
        >
          Add another Tag
        </div>
        <div
          className="btn"
          type="type3"
          onClick={() => {
            save_items(
              {
                id: data.order_id,
                data: JSON.stringify({
                  items: order_item_cards.map((el) => el.item_name),
                }),
              },
              current_store.user.token,
              (data) => {}
            );
            setsubmit_btn(true);
            setadd_order_current_showing("add_order_menu_status");
          }}
        >
          Submit Order
        </div>
      </div>
      {image_modal && (
        <AddOrderImagesModal
          data={data}
          images={images}
          current_image_index={current_image_index}
          setimage_modal={setimage_modal}
          setimages={setimages}
          loader={loader}
          setloader={setloader}
        />
      )}
    </div>
  );
};
