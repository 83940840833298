import React, { useEffect, useState } from "react";
import { useRoutes } from "react-router-dom";
import { Navbar } from "../Component/Common/Navbar/Navbar";
import { DraftOrders } from "../Pages/DraftOrders/DraftOrders";
import { Login } from "../Pages/Login/Login";
import { Orders } from "../Pages/Orders/Orders";
import { AddOrder } from "../Pages/AddOrder/AddOrder";
import "./layoutStyle.scss";
import { Clients } from "../Pages/Clients/Clients";
import { Test } from "../Component/Common/Test/Test";
import { TempDraftOrders } from "../Pages/TempDraftOrders/TempDraftOrders";
import { Loading } from "../Component/Common/Loader/Loading";
import { ViewOrder } from "../Pages/ViewOrder/ViewOrder";
import { Items } from "../Pages/Items/Items";
import { Cash } from "../Pages/Cash/Cash";
import { TempOrders } from "../Pages/TempOrders/Orders";
import { useSelector } from "react-redux";
import { navbar_status } from "../App";
import { Error } from "../Pages/404/Error";
import { FAQ } from "../Pages/FAQ/FAQ";
import { Vendor } from "../Pages/Vendor/Vendor";
import { Discount } from "../Pages/Discount/Discount";
import { Team } from "../Pages/Team/Team";
import { Test_Page } from "../Pages/Test/Test";
// import { Items } from "../Pages/Items/Items";

export const Layout = () => {
  const [show_navbar, setshow_navbar] = useState(false);
  const [loader, setloader] = useState(false);
  const [data, setdata] = useState("");

  const [view_order, setview_order] = useState({});
  const [current_client_page_showing, setcurrent_client_page_showing] =
    useState("");
  // console.log(current_client_page_showing);
  const current_store = useSelector((state) => state);
  const routes = [
    {
      path: "/",
      element: <Login setshow_navbar={setshow_navbar} setloader={setloader} />,
    },
    // {
    //   path: "/testdraft-orders",
    //   // element: <DraftOrders setdata={setdata} setshow_navbar={setshow_navbar} />,
    //   element: <TempDraftOrders setdata={setdata} setshow_navbar={setshow_navbar} />,
    // },
    // {
    //   path: "/draft-orders",
    //   // element: (
    //   //   <DraftOrders setdata={setdata} setshow_navbar={setshow_navbar} />
    //   // ),
    //   element: (
    //     <TempDraftOrders setdata={setdata} setshow_navbar={setshow_navbar} />
    //   ),
    // },

    ...[
      [
        "/clients",
        navbar_status.client,
        <Clients
          setcurrent_client_page_showing={setcurrent_client_page_showing}
          current_client_page_showing={current_client_page_showing}
          setview_order={setview_order}
          setdata={setdata}
          setshow_navbar={setshow_navbar}
          setloader={setloader}
        />,
      ],
      [
        "/team",
        navbar_status.team,
        <Team setshow_navbar={setshow_navbar} setloader={setloader} />,
      ],
      [
        "/items",
        navbar_status.items,
        <Items setshow_navbar={setshow_navbar} setloader={setloader} />,
      ],
      [
        "/discount",
        navbar_status.discount,
        <Discount setshow_navbar={setshow_navbar} setloader={setloader} />,
      ],
      [
        "/orders",
        navbar_status.orders,
        <TempOrders
          // <Orders
          setdata={setdata}
          setview_order={setview_order}
          setshow_navbar={setshow_navbar}
          setloader={setloader}
          type="order"
        />,
      ],
      [
        "/cash",
        navbar_status.cash,
        <Cash
          data={view_order}
          setshow_navbar={setshow_navbar}
          setloader={setloader}
        />,
      ],
      [
        "/vendor",
        navbar_status.vendor,
        <Vendor setshow_navbar={setshow_navbar} setloader={setloader} />,
      ],
      [
        "/faq",
        navbar_status.faq,
        <FAQ setshow_navbar={setshow_navbar} setloader={setloader} />,
      ],
      [
        "/reports",
        navbar_status.reports,
        <TempOrders
          // <Orders
          setdata={setdata}
          setview_order={setview_order}
          setshow_navbar={setshow_navbar}
          setloader={setloader}
          type="report"
        />,
      ],
      [
        "/add-order",
        navbar_status.add_orders,
        <AddOrder
          data={data}
          setdata={setdata}
          setshow_navbar={setshow_navbar}
          loader={loader}
          setloader={setloader}
        />,
      ],
      [
        "/view-order",
        navbar_status.view_orders,
        <ViewOrder data={view_order} setshow_navbar={setshow_navbar} />,
      ],
      // [
      //   "/test",
      //   true,
      //   <Test_Page setshow_navbar={setshow_navbar} setloader={setloader} />,
      // ],
    ].map((el) => {
      return {
        path: el[0],
        element: el[1] ? el[2] : <Error />,
      };
    }),
    // {
    //   path: "/clients",
    //   element: navbar_status.client ? (
    //     <Clients
    //       setdata={setdata}
    //       setshow_navbar={setshow_navbar}
    //       setloader={setloader}
    //     />
    //   ) : (
    //     <Error />
    //   ),
    // },
    // {
    //   path: "/items",
    //   element: navbar_status.items ? (
    //     <Items setshow_navbar={setshow_navbar} setloader={setloader} />
    //   ) : (
    //     <Error />
    //   ),
    // },
    // {
    //   path: "/orders",
    //   element: navbar_status.orders ? (
    //     <TempOrders
    //       // <Orders
    //       setdata={setdata}
    //       setview_order={setview_order}
    //       setshow_navbar={setshow_navbar}
    //       setloader={setloader}
    //     />
    //   ) : (
    //     <Error />
    //   ),
    // },
    // // no direct navigate to these pages below
    // {
    //   path: "/add-order",
    //   element: navbar_status.add_orders ? (
    //     <AddOrder
    //       data={data}
    //       setshow_navbar={setshow_navbar}
    //       loader={loader}
    //       setloader={setloader}
    //     />
    //   ) : (
    //     <Error />
    //   ),
    // },
    // {
    //   path: "/view-order",
    //   element: navbar_status.view_orders ? (
    //     <ViewOrder data={view_order} setshow_navbar={setshow_navbar} />
    //   ) : (
    //     <Error />
    //   ),
    // },
    // {
    //   path: "/cash",
    //   element: navbar_status.cash ? (
    //     <Cash
    //       data={view_order}
    //       setshow_navbar={setshow_navbar}
    //       setloader={setloader}
    //     />
    //   ) : (
    //     <Error />
    //   ),
    // },
    // {
    //   path: "/temp-order",
    //   element: <Test setshow_navbar={setshow_navbar} />,
    // },
    // {
    //   path: "/test",
    //   element: <Test setshow_navbar={setshow_navbar} />,
    // },
    {
      path: "/*",
      element: <Error />,
    },
  ];
  const route = useRoutes(routes);
  // useEffect(() => {
  //   setshow_navbar(false);
  // }, [route]);
  return (
    <div className="layout">
      {loader && <Loading />}
      <Navbar
        show={show_navbar.toString()}
        current_client_page_showing={current_client_page_showing}
        setcurrent_client_page_showing={setcurrent_client_page_showing}
      />
      {route}
    </div>
  );
};
