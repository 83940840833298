import React, { useEffect, useRef, useState } from "react";
import { update_faq } from "../../../API/API";
import { Close, Trash } from "../../Common/Images/Images";
import { RTE } from "../../Common/Test/Rich text editor/RTE";
import { Preview } from "../Preview/Preview";

import "./addFAQStyle.scss";
import { FaqIcons } from "./Faq_icons/FaqIcons";

import { ReactComponent as Email_icon } from "../../../img/faq_icons/email_icon.svg";
import { ReactComponent as Call_icon } from "../../../img/faq_icons/call_icon.svg";
import { ReactComponent as Message_icon } from "../../../img/faq_icons/message_icon.svg";
import { useSelector } from "react-redux";

export const faq_link_icons = [
  { title: "email", svg: <Email_icon /> },
  { title: "call", svg: <Call_icon /> },
  { title: "msg", svg: <Message_icon /> },
];

export const AddFAQ = ({ close, faq_list, setfaq_list, setloader }) => {
  const current_store = useSelector((state) => state);
  const [question, setquestion] = useState(
    // "WHAT'S THE DIFFERENCE BETWEEN WASHED & PRESSED AND DRY CLEANED FOR MEN'S SHIRTS?"
    ""
  );
  const [ans, setans] = useState("");
  const [link_inp, setlink_inp] = useState({
    img: "",
    href: "",
    txt: "",
  });
  const [link_btn, setlink_btn] = useState([]);
  const [save_btn, setsave_btn] = useState(false);
  const [preview, setpreview] = useState(false);
  // const getwidth = useRef();
  // const [current_focus, setcurrent_focus] = useState("");
  useEffect(() => {
    if (question == "" || ans == "") {
      setsave_btn(false);
    } else {
      if (
        question.length > 0 &&
        ans.length > 0 &&
        ans[0].children[0].text?.trim().length > 0 &&
        question[0].children[0].text?.trim().length > 0
      ) {
        setsave_btn(true);
      } else {
        setsave_btn(false);
      }
    }
  }, [question, ans]);

  return (
    <div
      className="add_faq"
      // onClick={() => {
      //   setcurrent_focus("");
      // }}
    >
      <div
      // style={
      //   preview
      //     ? {
      //         height: `calc(100vh - 40px)`,
      //         width: `calc(100vw - 40px)`,
      //       }
      //     : {}
      // }
      // ref={getwidth}
      >
        <div className="header">
          <Close
            white={preview}
            clickhandel={() => {
              if (preview) {
                setpreview(false);
              } else {
                close();
              }
            }}
          />
        </div>
        <div className="body">
          <div className="title">Add Q&A</div>
          <div>
            <div className="ques">
              <div className="ques_q">Q: </div>
              <RTE cust_placeholder="Add Question" setelement={setquestion} />
            </div>
            <div className="ans">
              <div className="ans_a">A: </div>
              <RTE cust_placeholder="Add Answer" setelement={setans} />
            </div>
            <div className="link_btn_container">
              {link_btn.map((el, i) => (
                <div key={i} className="link_btn_items">
                  <div className="link_btn">
                    {el.img && (
                      <div className="link_img">
                        {
                          faq_link_icons.filter(
                            (el1) => el.img === el1.title
                          )[0].svg
                        }
                      </div>
                    )}
                    <a href={el.href} target="_blank">
                      <div>{el.txt}</div>
                    </a>
                  </div>
                  <div
                    className="link_btn_delete"
                    onClick={() => {
                      let temp_links = [...link_btn];
                      temp_links = [
                        ...temp_links.slice(0, i),
                        ...temp_links.slice(i + 1, temp_links.length),
                      ];
                      setlink_btn([...temp_links]);
                    }}
                  >
                    <Trash />
                  </div>
                </div>
              ))}
              <div className="add_link_btn">
                <div className="icon_selector">
                  <FaqIcons link_inp={link_inp} setlink_inp={setlink_inp} />
                </div>
                <div>
                  <input
                    type="input"
                    placeholder="Enter Link Txt"
                    style={{ width: "200px" }}
                    value={link_inp.txt}
                    onChange={({ target }) => {
                      setlink_inp({
                        ...link_inp,
                        txt: target.value,
                      });
                    }}
                  />
                </div>
                <div>
                  <input
                    type="input"
                    placeholder="Enter Link"
                    style={{ width: "200px" }}
                    value={link_inp.href}
                    onChange={({ target }) => {
                      setlink_inp({
                        ...link_inp,
                        href: target.value,
                      });
                    }}
                  />
                </div>
                <div
                  className="add_link"
                  style={
                    link_inp.href == "" || link_inp.txt == ""
                      ? {
                          opacity: 0.2,
                        }
                      : {
                          opacity: 1,
                        }
                  }
                  onClick={() => {
                    if (link_inp.href == "" || link_inp.txt == "") {
                      return;
                    }
                    setlink_btn((prev) => [
                      ...prev,
                      {
                        img: link_inp.img,
                        txt: link_inp.txt,
                        href: link_inp.href,
                      },
                    ]);
                    setlink_inp({
                      img: "",
                      txt: "",
                      href: "",
                    });
                  }}
                >
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div
            className="btn"
            type={save_btn ? "type2" : "type1"}
            onClick={() => {
              if (!save_btn) return;
              setloader(true);
              update_faq(
                {
                  // type: 2,
                  data: JSON.stringify([
                    ...faq_list,
                    {
                      id: faq_list.length,
                      que: question,
                      ans: ans,
                      links: link_btn,
                      is_deleted: false,
                    },
                  ]),
                },
                current_store.user.token,
                (data) => {
                  setloader(false);
                  setfaq_list([
                    ...faq_list,
                    {
                      id: faq_list.length,
                      que: question,
                      ans: ans,
                      links: link_btn,
                      is_deleted: false,
                    },
                  ]);
                  close();
                }
              );
            }}
          >
            Save
          </div>
          <div
            className="btn"
            type={save_btn ? "type2" : "type1"}
            onClick={() => save_btn && setpreview(true)}
          >
            Preview
          </div>
        </div>
        <Preview
          preview={preview}
          question={question}
          answer={ans}
          links={link_btn}
        />
      </div>
    </div>
  );
};
