import React from 'react'
import { Close } from '../../Common/Images/Images'
import './cashMenuStyle.scss'
import { ReactComponent as View_Icon } from '../../../img/miscellaneous/eye_icon.svg'
import { ReactComponent as Edit_Icon } from '../../../img/miscellaneous/edit_icon.svg'

export const CashMenu = ({ close, view, edit }) => {
    return (
        <div className='cashmenu'>
            <div className='header'>
                <Close type='sm' clickhandel={close} />
            </div>
            <div className='body'>
                <div onClick={view}>
                    <View_Icon />
                    <div>View</div>
                </div>
                <div onClick={edit}>
                    <Edit_Icon />
                    <div>Edit</div>
                </div>
            </div>
        </div>
    )
}
