import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { edit_vendors } from "../../../API/API";
import { Close } from "../../Common/Images/Images";
import "./addvendorStyle.scss";

export const EditVendor = ({ close, data, setvendor_table, setloader }) => {
  const [vendor_details, setvendor_details] = useState({
    naem: "",
    number: "",
    address: "",
    note: "",
  });
  const navigate = useNavigate();
  const current_store = useSelector((state) => state);
  useEffect(() => {
    setvendor_details({
      name: data.name,
      number: data.mobile,
      address: data.add,
      note: data.note,
    });
  }, [data]);
  return (
    <div className="add_vendor">
      <div>
        <div className="header">
          <Close clickhandel={() => close()} />
        </div>
        <div className="body">
          {[
            {
              title: "Name",
              input: {
                type: "text",
                placeholder: "Insert Name",
                value: vendor_details.name,
                update_value: ({ target }) =>
                  setvendor_details((prev) => ({
                    ...prev,
                    name: target.value,
                  })),
              },
            },
            {
              title: "Number",
              input: {
                type: "number",
                placeholder: "Insert Number",
                value: vendor_details.number,
                update_value: ({ target }) => {
                  setvendor_details((prev) => ({
                    ...prev,
                    number: target.value,
                  }));
                },
              },
            },
            {
              title: "Address",
              input: {
                type: "text",
                placeholder: "Insert Address",
                value: vendor_details.address,
                update_value: ({ target }) =>
                  setvendor_details((prev) => ({
                    ...prev,
                    address: target.value,
                  })),
              },
            },
          ].map((el) => (
            <div>
              <div className="title">{el.title}</div>
              <div className="cust_input">
                <input
                  type={el.input.type}
                  placeholder={el.input.placeholder}
                  value={el.input.value}
                  onChange={el.input.update_value}
                />
              </div>
            </div>
          ))}
          <div>
            <div className="title">Note</div>
            <div className="cust_input with_textarea">
              <textarea
                type="text"
                placeholder='Insert Note;
Like "Leather, Bridal"'
                value={vendor_details.note}
                onChange={({ target }) =>
                  setvendor_details((prev) => ({ ...prev, note: target.value }))
                }
              />
            </div>
          </div>
        </div>
        <div className="action_btn_group">
          <div
            className="btn"
            type={
              vendor_details.name === "" ||
              vendor_details.number === "" ||
              vendor_details.address === "" ||
              vendor_details.note === "" ||
              (vendor_details.name === data.name &&
                vendor_details.number === data.mobile &&
                vendor_details.address === data.add &&
                vendor_details.note === data.note)
                ? "type1"
                : "type2"
            }
            onClick={() => {
              if (
                vendor_details.name === "" ||
                vendor_details.number === "" ||
                vendor_details.address === "" ||
                vendor_details.note === "" ||
                (vendor_details.name === data.name &&
                  vendor_details.number === data.mobile &&
                  vendor_details.address === data.add &&
                  vendor_details.note === data.note)
              ) {
                return;
              } else {
                setloader(true);
                edit_vendors(
                  {
                    name: vendor_details.name,
                    mobile: vendor_details.number,
                    address: vendor_details.address,
                    notes: vendor_details.note,
                    id: data.id,
                  },
                  current_store.user.token,
                  (data) => {
                    if (!data.status) {
                      setloader(false);
                      return navigate("/");
                    }
                    setvendor_table([
                      ...data.data.vendors.map((el) => ({
                        id: el.id,
                        name: el.name,
                        add: el.address,
                        mobile: el.mobile_no,
                        note: el.notes,
                      })),
                    ]);
                    setloader(false);
                    close();
                  }
                );
              }
            }}
          >
            Save Vendor
          </div>
        </div>
      </div>
    </div>
  );
};
