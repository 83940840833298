import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setitems } from "../../../API/API";
import { Close } from "../../Common/Images/Images";
import { CustDropdown } from "../../Custom/CustDropdown/CustDropdown";
import { DeleteModal } from "../DeleteModal/DeleteModal";
import "./addsubcategorymodalStyle.scss";

// Note
//      type0 = needs to be edited and errors
//      type1 = adding subcategory
//      type2 = adding products for category = true and subcategory = false
//      type3 = adding products for category = true and subcategory = true
//      type4 = adding products to categoy which has minimum price with minimum weight and cost/lb

export const ViewEditSubcategoryModal = ({
  og_item_list,
  setog_item_list,
  data,
  item1,
  item2,
  type,
  cancel,
  item_array_id,
  setloader,
}) => {
  const current_store = useSelector((state) => state);

  const [selectedsubcategory, setselectedsubcategory] = useState("");
  const [product_name, setproduct_name] = useState("");
  const [description, setdescription] = useState("");
  const [cost, setcost] = useState("");
  const [min_weight, setmin_weight] = useState("");
  const [cost_per_lb, setcost_per_lb] = useState("");
  const [add_btn, setadd_btn] = useState(false);

  const [deletemodal, setdeletemodal] = useState(false);
  const [edit_btn, setedit_btn] = useState(false);
  const [temp_data, settemp_data] = useState({
    product_name: "",
    description: "",
    cost: "",
    min_weight: "",
    cost_per_lb: "",
  });

  useEffect(() => {
    if (data.has_child) {
      if (type === 1) {
        settemp_data({
          ...temp_data,
          product_name: item1.name,
          // cost: item1.cost,
        });
        setproduct_name(item1.name);
        // setcost(item1.cost);
      }
      if (type === 3) {
        settemp_data({
          ...temp_data,
          product_name: item1.name,
          description: item2.name,
          cost: item2.cost,
        });
        setproduct_name(item1.name);
        setdescription(item2.name);
        setcost(item2.cost);
      }
    } else {
      if (type === 1) {
        settemp_data({
          ...temp_data,
          product_name: item1.name,
          // cost: item1.cost,
        });
        setproduct_name(item1.name);
        // setcost(item1.cost);
      }
      if (type === 2) {
        settemp_data({
          ...temp_data,
          description: item1.name,
          cost: item1.cost,
        });
        setdescription(item1.name);
        setcost(item1.cost);
      }
      if (type === 4) {
        settemp_data({
          ...temp_data,
          description: item1.name,
          cost_per_lb: item1.cost.per_cost,
          min_weight: item1.cost.min_weight,
          cost: item1.cost.min_cost,
        });
        setdescription(item1.name);
        setcost_per_lb(item1.cost.per_cost);
        setmin_weight(item1.cost.min_weight);
        setTimeout(() => {
          setcost(item1.cost.min_cost);
        }, 0);
      }
    }
  }, []);

  useEffect(() => {
    if (type === 1) {
      if (product_name.trim().length == 0) {
        setadd_btn(false);
      } else {
        setadd_btn(true);
      }
    }
    if (type === 2) {
      if (description.trim().length == 0 || cost == "") {
        setadd_btn(false);
      } else {
        setadd_btn(true);
      }
    }
    if (type === 3) {
      if (description.trim().length == 0 || cost == "") {
        setadd_btn(false);
      } else {
        setadd_btn(true);
      }
    }
    if (type === 4) {
      if (
        description.trim().length === 0 ||
        cost == "" ||
        cost == 0 ||
        min_weight == "" ||
        min_weight == 0 ||
        cost_per_lb == "" ||
        cost_per_lb == 0
      ) {
        setadd_btn(false);
      } else {
        setadd_btn(true);
      }
    }
    // setselectedsubcategory()
  }, [product_name, description, cost, min_weight, cost_per_lb]);
  useEffect(() => {
    if (type === 4 && cost_per_lb !== "" && min_weight != "") {
      setcost(cost_per_lb * min_weight);
    }
  }, [cost_per_lb, min_weight]);

  return (
    <div className="viewedititemcategorymodal">
      <div>
        <div className="head">
          <Close clickhandel={cancel} />
          {/* {type} */}
        </div>
        <div className="body">
          <div>
            <div className="title">Category</div>
            <div className="cust_input input">
              <input value={data.name} readOnly />
            </div>
            {type === 0 && <>needs revision not completed</>}
            {type === 1 && (
              <>
                <div className="title">Product</div>
                <div className="cust_input input">
                  <input
                    type="text"
                    placeholder="insert product"
                    value={temp_data.product_name}
                    // onChange={(e) => {
                    //   setproduct_name(e.target.value);
                    // }}
                  />
                </div>
              </>
            )}
            {type === 2 && (
              <>
                {data.has_child && (
                  <>
                    <div className="title">Product</div>
                    <div className="cust_input input">
                      <input
                        type="text"
                        placeholder="insert product"
                        value={temp_data.product_name}
                        readOnly
                        // onChange={(e) => {
                        //   setproduct_name(e.target.value);
                        // }}
                      />
                    </div>
                  </>
                )}
                <div className="title">Description</div>
                <div className="cust_input input">
                  <input
                    type="text"
                    placeholder="insert description"
                    value={temp_data.description}
                    readOnly
                    // onChange={(e) => {
                    //   setdescription(e.target.value);
                    // }}
                  />
                </div>
                <div className="title">Cost</div>
                <div className="cust_input input">
                  <input
                    type="number"
                    placeholder="insert cost"
                    value={temp_data.cost}
                    readOnly
                    // onChange={(e) => {
                    //   setcost(Math.abs(e.target.value));
                    // }}
                  />
                </div>
              </>
            )}
            {type === 3 && (
              <>
                <div className="title">Product</div>
                <div className="cust_input input">
                  <input
                    type="text"
                    placeholder="insert product"
                    value={item1.name}
                    readOnly
                  />
                </div>
                <div className="title">Description</div>
                <div className="cust_input input">
                  <input
                    type="text"
                    placeholder="insert description"
                    value={temp_data.description}
                    readOnly

                    // onChange={(e) => {
                    //   setdescription(e.target.value);
                    // }}
                  />
                </div>
                <div className="title">Cost</div>
                <div className="cust_input input">
                  <input
                    type="number"
                    placeholder="insert cost"
                    value={temp_data.cost}
                    readOnly

                    // onChange={(e) => {
                    //   setcost(Math.abs(e.target.value));
                    // }}
                  />
                </div>
              </>
            )}
            {type === 4 && (
              <>
                <div className="title">Description</div>
                <div className="cust_input input">
                  <input
                    type="text"
                    placeholder="insert description"
                    value={temp_data.description}
                    readOnly

                    // onChange={(e) => {
                    //   setdescription(e.target.value);
                    // }}
                  />
                </div>
                <div className="title">Cost per lb</div>
                <div className="cust_input input input_padding_5">
                  <div>$</div>
                  <input
                    type="number"
                    placeholder="insert cost"
                    value={temp_data.cost_per_lb}
                    readOnly

                    // onChange={(e) => {
                    //   setcost_per_lb(Math.abs(e.target.value));
                    // }}
                  />
                  <div>/ lb</div>
                </div>
                <div className="title">Minimum weight</div>
                <div className="cust_input input">
                  <input
                    type="number"
                    placeholder="insert cost"
                    value={temp_data.min_weight}
                    readOnly

                    // onChange={(e) => {
                    //   setmin_weight(Math.abs(e.target.value));
                    // }}
                  />
                  <div>lb</div>
                </div>
                {temp_data.min_weight != "" && temp_data.min_weight != 0 && (
                  <>
                    <div className="title">
                      Cost for{" "}
                      {temp_data.min_weight == ""
                        ? 0
                        : Number(temp_data.min_weight)}{" "}
                      lb Weight
                    </div>
                    <div className="cust_input input input_padding_5">
                      <div>$</div>
                      <input
                        type="number"
                        placeholder="insert cost"
                        value={temp_data.cost}
                        readOnly

                        // onChange={(e) => {
                        //   setcost(Math.abs(e.target.value));
                        // }}
                      />
                    </div>
                  </>
                )}
              </>
            )}

            {/* <div className='cust_input input'> */}
            {/* <CustDropdown
                        custid={'dropdown1'}
                        value={selectedsubcategory}
                        custclass={'border_black'}
                        defaultplaceholder={'select product'}
                        dropdown_arr={[...data.item_subcategory.map((el, i) => { return { key: i, value: el.name } })]}
                        // tempclose={}
                        setvalue={setselectedsubcategory}
                      /> */}
            {/* </div> */}
          </div>
          <div className={edit_btn ? "active" : ""}>
            {/* <div className="title">Category</div>
            <div className="cust_input input">
              <input value={data.name} readOnly />
            </div> */}
            <div className="title">Editable Values</div>

            {type === 0 && <>needs revision not completed</>}
            {type === 1 && (
              <>
                <div className="title">Product</div>
                <div className="cust_input input">
                  <input
                    type="text"
                    placeholder="insert product"
                    value={product_name}
                    onChange={(e) => {
                      setproduct_name(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
            {type === 2 && (
              <>
                {/* {data.has_child && (
                  <>
                    <div className="title">Product</div>
                    <div className="cust_input input">
                      <input
                        type="text"
                        placeholder="insert product"
                        value={product_name}
                        readOnly
                        // onChange={(e) => {
                        //   setproduct_name(e.target.value);
                        // }}
                      />
                    </div>
                  </>
                )} */}
                <div className="title">Description</div>
                <div className="cust_input input">
                  <input
                    type="text"
                    placeholder="insert description"
                    value={description}
                    onChange={(e) => {
                      setdescription(e.target.value);
                    }}
                  />
                </div>
                <div className="title">Cost</div>
                <div className="cust_input input">
                  <input
                    type="number"
                    placeholder="insert cost"
                    value={cost}
                    onChange={(e) => {
                      setcost(Math.abs(e.target.value));
                    }}
                  />
                </div>
              </>
            )}
            {type === 3 && (
              <>
                {/* <div className="title">Product</div>
                <div className="cust_input input">
                  <input
                    type="text"
                    placeholder="insert product"
                    value={data.items[item_array_id].name}
                  />
                </div> */}
                <div className="title">Description</div>
                <div className="cust_input input">
                  <input
                    type="text"
                    placeholder="insert description"
                    value={description}
                    onChange={(e) => {
                      setdescription(e.target.value);
                    }}
                  />
                </div>
                <div className="title">Cost</div>
                <div className="cust_input input">
                  <input
                    type="number"
                    placeholder="insert cost"
                    value={cost}
                    onChange={(e) => {
                      setcost(Math.abs(e.target.value));
                    }}
                  />
                </div>
              </>
            )}
            {type === 4 && (
              <>
                <div className="title">Description</div>
                <div className="cust_input input">
                  <input
                    type="text"
                    placeholder="insert description"
                    value={description}
                    onChange={(e) => {
                      setdescription(e.target.value);
                    }}
                  />
                </div>
                <div className="title">Cost per lb</div>
                <div className="cust_input input input_padding_5">
                  <div>$</div>
                  <input
                    type="number"
                    placeholder="insert cost"
                    value={cost_per_lb}
                    onChange={(e) => {
                      setcost_per_lb(Math.abs(e.target.value));
                    }}
                  />
                  <div>/ lb</div>
                </div>
                <div className="title">Minimum weight</div>
                <div className="cust_input input">
                  <input
                    type="number"
                    placeholder="insert cost"
                    value={min_weight}
                    onChange={(e) => {
                      setmin_weight(Math.abs(e.target.value));
                    }}
                  />
                  <div>lb</div>
                </div>
                {/* {min_weight != "" && min_weight != 0 && ( */}
                <>
                  <div className="title">
                    Cost for {min_weight == "" ? 0 : Number(min_weight)} lb
                    Weight
                  </div>
                  <div className="cust_input input input_padding_5">
                    <div>$</div>
                    <input
                      type="number"
                      placeholder="insert cost"
                      value={cost}
                      onChange={(e) => {
                        setcost(Math.abs(e.target.value));
                      }}
                    />
                  </div>
                </>
                {/* )} */}
              </>
            )}
          </div>
        </div>
        <div className="footer">
          {/* {false && (
            <div
              className="btn"
              type={add_btn ? "type2" : "type1"}
              onClick={() => {
                // if (!add_btn) {
                //   return;
                // }
                let temp_arr = [...og_item_list];
                switch (type) {
                  case 1:
                    temp_arr[data.id] = {
                      ...temp_arr[data.id],
                      items: [
                        ...temp_arr[data.id].items,
                        {
                          id: temp_arr[data.id].items.length,
                          name: product_name,
                          items: [],
                        },
                      ],
                    };
                    break;
                  case 2:
                    temp_arr[data.id] = {
                      ...temp_arr[data.id],
                      items: [
                        ...temp_arr[data.id].items,
                        {
                          item_id: temp_arr[data.id].items.length,
                          name: description,
                          cost: cost,
                        },
                      ],
                    };
                    break;
                  case 3:
                    temp_arr[data.id] = {
                      ...temp_arr[data.id],
                      items: [
                        ...temp_arr[data.id].items.map((el, i) => {
                          if (i == item_array_id) {
                            return {
                              ...el,
                              items: [
                                ...el.items,
                                {
                                  item_id: el.items.length,
                                  name: description,
                                  cost: cost,
                                },
                              ],
                            };
                          }
                          return el;
                        }),
                      ],
                    };
                    break;
                  // test case 4
                  case 4:
                    temp_arr[data.id] = {
                      ...temp_arr[data.id],
                      items: [
                        ...temp_arr[data.id].items,
                        {
                          item_id: temp_arr[data.id].items.length,
                          name: description,
                          cost: {
                            // cost for min_weight
                            min_cost: cost,
                            // minimum weight to be considered
                            min_weight: min_weight,
                            // extra weight cost / lb
                            per_cost: cost_per_lb,
                          },
                        },
                      ],
                    };
                    break;

                  default:
                    break;
                }
                //   return;

                // setitems(temp_arr, current_store.user.token, (data) => {
                // });
                // cancel()
              }}
            >
              Replace
            </div>
          )} */}
          <div
            className="btn"
            type="type2"
            onClick={() => {
              setedit_btn(!edit_btn);
            }}
          >
            {edit_btn ? "View" : "Edit"}
          </div>
          <div
            className="btn"
            type="type5"
            onClick={() => setdeletemodal(true)}
          >
            Delete
          </div>
          {edit_btn && (
            <div
              className="btn animate"
              type="type3"
              onClick={() => {
                let temp_arr = [...og_item_list];
                setloader(true);
                switch (type) {
                  case 1:
                    temp_arr[data.id] = {
                      ...temp_arr[data.id],
                      items: [
                        ...temp_arr[data.id].items.map((el1, i) => {
                          if (item1.id == el1.id) {
                            return {
                              ...el1,
                              name: product_name,
                            };
                          }
                          return el1;
                        }),
                      ],
                    };
                    break;
                  case 2:
                    temp_arr[data.id] = {
                      ...temp_arr[data.id],
                      items: [
                        ...temp_arr[data.id].items.map((el1, i) => {
                          if (item1.item_id == el1.item_id) {
                            return {
                              ...el1,
                              name: description,
                              cost: cost,
                            };
                          }
                          return el1;
                        }),
                      ],
                    };
                    break;
                  case 3:
                    temp_arr[data.id] = {
                      ...temp_arr[data.id],
                      items: [
                        ...temp_arr[data.id].items.map((el, i) => {
                          if (el.id == item1.id) {
                            return {
                              ...el,
                              items: [
                                ...el.items.map((el1) => {
                                  if (el1.item_id == item2.item_id) {
                                    return {
                                      ...el1,
                                      name: description,
                                      cost: cost,
                                    };
                                  }
                                  return el1;
                                }),
                              ],
                            };
                          }
                          return el;
                        }),
                      ],
                    };
                    break;
                  // test case 4
                  case 4:
                    temp_arr[data.id] = {
                      ...temp_arr[data.id],
                      items: [
                        ...temp_arr[data.id].items.map((el1) => {
                          if (el1.item_id == item1.item_id) {
                            return {
                              ...el1,
                              name: description,
                              cost: {
                                // cost for min_weight
                                min_cost: cost,
                                // minimum weight to be considered
                                min_weight: min_weight,
                                // extra weight cost / lb
                                per_cost: cost_per_lb,
                              },
                            };
                          }
                          return el1;
                        }),
                      ],
                    };
                    break;
                  default:
                    break;
                }
                setitems(temp_arr, current_store.user.token, (data) => {
                  setog_item_list([...temp_arr]);
                  setloader(false);
                  cancel();
                });
              }}
            >
              Save
            </div>
          )}
        </div>
      </div>
      {deletemodal && (
        // || !true
        <DeleteModal
          close={() => {
            setdeletemodal(false);
          }}
          deletefunc={() => {
            setloader(true);
            let temp_arr = [...og_item_list];
            switch (type) {
              case 1:
                temp_arr[data.id] = {
                  ...temp_arr[data.id],
                  items: [
                    ...temp_arr[data.id].items
                      // .filter((el1, i) => item1.id != el1.id)
                      .map((el2) =>
                        el2.id == item1.id
                          ? {
                              ...el2,
                              // id: el2.id - 1,
                              delete_status: 1,
                            }
                          : el2
                      ),
                  ],
                };
                break;
              case 2:
                temp_arr[data.id] = {
                  ...temp_arr[data.id],
                  items: [
                    ...temp_arr[data.id].items
                      // .filter((el1) => item1.item_id != el1.item_id)
                      .map((el2, i) =>
                        el2.item_id == item1.item_id
                          ? {
                              ...el2,
                              // item_id: el2.item_id - 1,
                              delete_status: 1,
                            }
                          : el2
                      ),
                  ],
                };
                break;
              case 3:
                temp_arr[data.id] = {
                  ...temp_arr[data.id],
                  items: [
                    ...temp_arr[data.id].items.map((el, i) => {
                      if (el.id == item1.id) {
                        return {
                          ...el,
                          items: [
                            ...el.items
                              // .filter((el1) => el1.item_id != item2.item_id)
                              .map((el2) =>
                                el2.item_id == item2.item_id
                                  ? {
                                      ...el2,
                                      // item_id: el2.item_id - 1,
                                      delete_status: 1,
                                    }
                                  : el2
                              ),
                          ],
                        };
                      }
                      return el;
                    }),
                  ],
                };
                break;
              // test case 4
              case 4:
                temp_arr[data.id] = {
                  ...temp_arr[data.id],
                  items: [
                    ...temp_arr[data.id].items
                      // .filter((el1) => el1.item_id != item1.item_id)
                      .map((el2) =>
                        el2.item_id == item1.item_id
                          ? {
                              ...el2,
                              // item_id: el2.item_id - 1,
                              delete_status: 1,
                            }
                          : el2
                      ),
                  ],
                };
                break;

              default:
                break;
            }
            setitems(temp_arr, current_store.user.token, (data) => {
              setog_item_list([...temp_arr]);
              setloader(false);
              setdeletemodal(false);
              cancel();
            });
            // let temp_og = [...og_item_list];
            // let resetted_og = [
            //   ...temp_og
            //     .filter((el) => el.id !== current_item.category_id)
            //     .map((el) => {
            //       if (el.id > current_item.category_id) {
            //         return {
            //           ...el,
            //           id: el.id - 1,
            //         };
            //       }
            //       return el;
            //     }),
            // ];
          }}
        />
      )}
    </div>
  );
};
