import React from "react";
import { useSelector } from "react-redux";
import { delete_vendor_report } from "../../../../API/API";
import { Close } from "../../../Common/Images/Images";
// import { update_faq } from "../../../API/API";
// import { Close } from "../../Common/Images/Images";
import "./vendorcashdeleteStyle.scss";

export const VendorCashDelete = ({
  close,
  current_data,
  faq_list,
  setfaq_list,
  setloader,
  current_report,
  setreport_data_after_edit,
}) => {
  const current_store = useSelector((state) => state);
  return (
    <div className="vendorcashdelete_delete">
      <div>
        <div className="header" onClick={() => close()}>
          <Close />
        </div>
        <div className="title">
          Do you really want to
          <br />
          delete this?
        </div>
        <div className="btn_group">
          <div
            className="btn"
            type="type4"
            onClick={() => {
              setloader(true);
              // let temp_faq = [...faq_list];
              // temp_faq = temp_faq.map((el) => {
              //   if (el.id !== current_data.id) {
              //     return el;
              //   } else {
              //     return {
              //       ...el,
              //       is_deleted: true,
              //     };
              //   }
              // });
              // setreport_data_after_edit
              // update_faq(
              //   {
              //     data: JSON.stringify([...temp_faq]),
              //   },
              //   current_store.user.token,
              //   (data) => {
              //     setfaq_list([]);
              //     setTimeout(() => {
              //       setfaq_list([...temp_faq]);
              //       close();
              //       setloader(false);
              //     }, 0);
              //   }
              // );
              delete_vendor_report(
                { id: current_report.id },
                current_store.user.token,
                (data) => {
                  // console.log("delete vendor report ", data);
                  if (!data.status) {
                    return;
                  }
                  setloader(false);
                  setreport_data_after_edit(data.data.cashData);
                  close();
                  // setreport_data_after_edit
                  //
                  // return window.location.reload()
                }
              );
            }}
          >
            Delete
          </div>
          <div className="btn" type="type4" onClick={() => close()}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};
