import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setitems } from "../../../API/API";
import { Accordian } from "../../Common/Accordian/Accordian";
import { AccordianCards } from "../../Common/Accordian/AccordianCards";
import { ArrowLeft, Eye, Plus } from "../../Common/Images/Images";
import { AddSubcategoryModal } from "../AddSubcategoryModal/AddSubcategoryModal";
import { ViewEditSubcategoryModal } from "../ViewEditSubcategoryModal/ViewEditSubcategoryModal";

export const ItemSubCategory = ({
  setloader,
  og_item_list,
  setog_item_list,
  selected_sub_category,
  cancel,
  add_subcategory,
  show_deleted_items,
}) => {
  const current_store = useSelector((state) => state);

  const [sub_category, setsub_category] = useState({});
  const [toggel_addsubcategorymodal, settoggel_addsubcategorymodal] =
    useState(false);
  const [toggel_vieweditsubcategorymodal, settoggel_vieweditsubcategorymodal] =
    useState(false);
  const [modal_type, setmodal_type] = useState(0);
  const [item1, setitem1] = useState({});
  const [item2, setitem2] = useState({});
  const [subitem_array_id, setsubitem_array_id] = useState("");
  useEffect(() => {
    setsub_category(
      ...og_item_list.filter((el) => el.id == selected_sub_category)
    );
  }, [og_item_list]);
  return (
    <div className="itemsubcategory">
      <div className="header">
        <div className="go_back" onClick={cancel}>
          <ArrowLeft svg_type="type2" />
          {sub_category.name}
        </div>
        {/* <Eye
          type="m"
          clickhandel={(e) => {
            e.stopPropagation();
            setitem1({ ...sub_category });
            setmodal_type(1);
            settoggel_vieweditsubcategorymodal(true);

            // viewedit
          }}
          style={{
            position: "absolute",
            top: 0,
            bottom: 0,
            right: "80px",
            margin: "auto",
          }}
        /> */}
        {sub_category.has_child && (
          <div
            className="add_subcategory"
            onClick={() => {
              settoggel_addsubcategorymodal(true);
              setsubitem_array_id("");
              setmodal_type(1);
            }}
          >
            <Plus white={true} />
          </div>
        )}
      </div>
      <div className="body">
        {sub_category.has_child && (
          <>
            {sub_category.items
              .filter((el) => el.delete_status !== 1 || !show_deleted_items)
              .map((el, i) => (
                <Accordian
                  viewedit={() => {
                    setmodal_type(1);
                    setitem1({ ...el });
                    settoggel_vieweditsubcategorymodal(true);
                  }}
                  show_client_status={el.show_client_status}
                  client_show_toggel={() => {
                    setloader(true);
                    let temp_arr = [...og_item_list];
                    temp_arr[selected_sub_category] = {
                      ...temp_arr[selected_sub_category],
                      items: [
                        ...temp_arr[selected_sub_category].items
                          // .filter((el1, i) => el.id != el1.id)
                          .map((el2) =>
                            el2.id == el.id
                              ? {
                                  ...el2,
                                  show_client_status:
                                    el2.show_client_status === 1 ? 0 : 1,
                                }
                              : el2
                          ),
                      ],
                    };
                    setitems(temp_arr, current_store.user.token, (data) => {
                      // setitem_list([...temp_items]);
                      setog_item_list([...temp_arr]);
                      setloader(false);
                      // cancel();
                    });
                    // setog_item_list([...temp_arr]);
                  }}
                  acc_obj={{
                    name: `${el.name} (${el.items.length})`,
                    // add_subcategory: <div className=''>add_sub_category</div>,
                    id: el.id,
                    count_child: el.items.length,
                    acc_body: (
                      <>
                        <AccordianCards
                          data={[
                            ...el.items
                              .filter(
                                (el) =>
                                  el.delete_status !== 1 || !show_deleted_items
                              )
                              .map((el1) => {
                                return {
                                  name: el1.name,
                                  cost: `$${Number(el1.cost).toFixed(2)}`,
                                  // el:{
                                  //   ...el
                                  // }
                                  onviewedit: () => {
                                    settoggel_vieweditsubcategorymodal(true);
                                    setitem1({ ...el });
                                    setitem2({ ...el1 });

                                    if (sub_category.special_cost) {
                                      setmodal_type(4);
                                    }
                                    if (sub_category.has_child) {
                                      setmodal_type(3);
                                    } else {
                                      setmodal_type(2);
                                    }
                                  },
                                };
                              }),
                          ]}
                          add_obj={{
                            card: (
                              <div
                                className="cards add_card"
                                onClick={() => {
                                  settoggel_addsubcategorymodal(true);
                                  setsubitem_array_id(i);
                                  setmodal_type(3);
                                }}
                              >
                                <Plus white={true} />
                              </div>
                            ),
                          }}
                        />
                      </>
                    ),
                  }}
                />
              ))}
          </>
        )}
        {!sub_category.has_child && sub_category.items && (
          <AccordianCards
            card_style={sub_category.special_cost ? { height: "80px" } : {}}
            data={[
              ...sub_category.items
                .filter((el) => el.delete_status !== 1 || !show_deleted_items)
                .map((el) => {
                  // if (el.delete_status === 1) {
                  //   return "";
                  // }
                  return {
                    name: el.name,
                    cost: (
                      <>
                        $
                        {typeof el.cost == "string" ||
                        typeof el.cost == "number" ? (
                          el.cost
                        ) : typeof el.cost == "object" ? (
                          <>
                            {el.cost.min_cost} for {el.cost.min_weight}lb
                            <br />+ ${el.cost.per_cost} per lb
                          </>
                        ) : (
                          "error"
                        )}
                      </>
                    ),
                    onviewedit: () => {
                      settoggel_vieweditsubcategorymodal(true);

                      setitem1({ ...el });
                      if (sub_category.special_cost) {
                        setmodal_type(4);
                      } else {
                        setmodal_type(2);
                      }
                    },
                  };
                }),
            ]}
            add_obj={{
              card: (
                <div
                  className="cards add_card"
                  style={sub_category.special_cost ? { height: "80px" } : {}}
                  onClick={() => {
                    settoggel_addsubcategorymodal(true);
                    if (sub_category.special_cost) {
                      setmodal_type(4);
                    } else {
                      setmodal_type(2);
                    }
                    // if (typeof sub_category.cost == 'string' || typeof sub_category.cost == 'number') {
                    //     setmodal_type(2)
                    // }
                    // else if (typeof sub_category.cost == 'object') {
                    //     setmodal_type(3)
                    // }
                    // else {
                    // }
                  }}
                >
                  <Plus white={true} />
                </div>
              ),
            }}
          />
        )}
      </div>
      {toggel_addsubcategorymodal ? (
        <AddSubcategoryModal
          setloader={setloader}
          og_item_list={og_item_list}
          setog_item_list={setog_item_list}
          type={modal_type}
          data={sub_category}
          item_array_id={subitem_array_id}
          cancel={() => {
            settoggel_addsubcategorymodal(false);
          }}
        />
      ) : (
        ""
      )}
      {toggel_vieweditsubcategorymodal ? (
        <ViewEditSubcategoryModal
          setloader={setloader}
          og_item_list={og_item_list}
          setog_item_list={setog_item_list}
          type={modal_type}
          data={sub_category}
          item1={item1}
          item2={item2}
          // setitem1={setitem1}
          // setitem2={setitem2}
          item_array_id={subitem_array_id}
          cancel={() => {
            settoggel_vieweditsubcategorymodal(false);
            setitem1({});
            setitem2({});
          }}
        />
      ) : (
        ""
      )}
    </div>
  );
};
