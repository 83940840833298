import React, { useState } from "react";
import "./cardsStyle.scss";
import Add_icon_for_card from "../../../img/cards/card_add.png";

export const Cards = ({ card_arr, last_card }) => {
  const [current_data, setcurrent_data] = useState([
    "retentionrate_selected1",
    "avg.order_selected1",
  ]);
  const handel_current_data = (txt) => {
    switch (txt.split("_")[0]) {
      case "retentionrate":
        setcurrent_data((prev) => [txt, prev[1]]);
        return;
      case "avg.order":
        setcurrent_data((prev) => [prev[0], txt]);
        return;

      default:
        break;
    }
  };
  // console.log(current_data);
  return (
    <div className="four_cards">
      {card_arr.map((el, i) => {
        if (el.divide && el.divide === 3) {
          return (
            <div key={i} className="card_body avg_order_card">
              <div className="card_left">
                <div className="card_title">{el.title}</div>
                <div className="card_num">
                  {current_data
                    ? current_data.includes(
                        el.title
                          .split(" ")
                          .map((el) => el.toLowerCase())
                          .join("") + "_selected1"
                      )
                      ? el.value[0] !== 0
                        ? el.value[0]
                        : current_data
                      : current_data.includes(
                          el.title
                            .split(" ")
                            .map((el) => el.toLowerCase())
                            .join("") + "_selected2"
                        )
                      ? el.value[1] !== 0
                        ? el.value[1]
                        : current_data
                      : current_data.includes(
                          el.title
                            .split(" ")
                            .map((el) => el.toLowerCase())
                            .join("") + "_selected3"
                        )
                      ? el.value[2] !== 0
                        ? el.value[2]
                        : current_data
                      : current_data
                    : current_data}
                </div>
              </div>
              <div className="card_value">
                {el.value[0] !== 0 || el.value[1] !== 0 || el.value[2] !== 0 ? (
                  // <div className="animate_card_to_infinity">
                  //   <div style={{ display: "flex" }}>
                  //     {el.value[0] !== 0 && <div>{el.value[0]}/Year</div>}
                  //     {el.value[1] !== 0 && <div>{el.value[1]}/Month</div>}
                  //     {el.value[2] !== 0 && <div>{el.value[2]}/Day</div>}
                  //     {el.value[0] !== 0 && <div>{el.value[0]}/Year</div>}
                  //     {el.value[1] !== 0 && <div>{el.value[1]}/Month</div>}
                  //     {el.value[2] !== 0 && <div>{el.value[2]}/Day</div>}
                  //   </div>
                  // </div>
                  // <div className="">
                  // <div>
                  <>
                    <div
                      className={
                        current_data.includes(
                          el.title
                            .split(" ")
                            .map((el) => el.toLowerCase())
                            .join("") + "_selected1"
                        )
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        handel_current_data(
                          el.title
                            .split(" ")
                            .map((el) => el.toLowerCase())
                            .join("") + "_selected1"
                        )
                      }
                    >
                      {el.options[0]}
                    </div>
                    <div
                      className={
                        current_data.includes(
                          el.title
                            .split(" ")
                            .map((el) => el.toLowerCase())
                            .join("") + "_selected2"
                        )
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        handel_current_data(
                          el.title
                            .split(" ")
                            .map((el) => el.toLowerCase())
                            .join("") + "_selected2"
                        )
                      }
                    >
                      {el.options[1]}
                    </div>
                    <div
                      className={
                        current_data.includes(
                          el.title
                            .split(" ")
                            .map((el) => el.toLowerCase())
                            .join("") + "_selected3"
                        )
                          ? "active"
                          : ""
                      }
                      onClick={() =>
                        handel_current_data(
                          el.title
                            .split(" ")
                            .map((el) => el.toLowerCase())
                            .join("") + "_selected3"
                        )
                      }
                    >
                      {el.options[2]}
                    </div>
                  </>
                ) : (
                  //   </div>
                  // </div>
                  "-"
                )}
              </div>
            </div>
          );

          return (
            <div key={i} className="card_body">
              <div className="card_title">{el.title}</div>
              <div className="card_value">
                {el.value[0] !== 0 || el.value[1] !== 0 || el.value[2] !== 0 ? (
                  <div className="animate_card_to_infinity">
                    <div style={{ display: "flex" }}>
                      {el.value[0] !== 0 && <div>{el.value[0]}/Year</div>}
                      {el.value[1] !== 0 && <div>{el.value[1]}/Month</div>}
                      {el.value[2] !== 0 && <div>{el.value[2]}/Day</div>}
                      {el.value[0] !== 0 && <div>{el.value[0]}/Year</div>}
                      {el.value[1] !== 0 && <div>{el.value[1]}/Month</div>}
                      {el.value[2] !== 0 && <div>{el.value[2]}/Day</div>}
                    </div>
                  </div>
                ) : (
                  "-"
                )}
              </div>
            </div>
          );
        }
        return (
          <div key={i} className="card_body">
            <div className="card_title">{el.title}</div>
            <div className="card_value">{el.value}</div>
          </div>
        );
      })}
      {last_card && (
        <div className="card_body last_card" onClick={last_card.click}>
          <img src={Add_icon_for_card} />
          <div>{last_card.title}</div>
        </div>
      )}
    </div>
  );
};
