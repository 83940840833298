import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { editteam, edit_team_password } from "../../../API/API";
import { encrypt_data } from "../../../RSA_Encryption/RSA";
import { Close } from "../../Common/Images/Images";
import { Switcher } from "../../Switcher/Switcher";
import { CheckPassword } from "../CheckPassword/CheckPassword";

import "./addteamModalStyle.scss";

export const ResetPasswordModal = ({
  close,
  current_data,
  setloader,
  setteam_list,
}) => {
  const [data, setdata] = useState({
    // name: "",
    // // number: "",
    // mail: "",
    password: "",
  });
  const current_store = useSelector((state) => state);

  const [edit_btn, setedit_btn] = useState(false);
  const [check_passsword, setcheck_passsword] = useState(false);
  useEffect(() => {
    setdata({
      ...data,
      // name: current_data.name,
      // number: current_data.mobile,
      // mail: current_data.email,
      // is_admin: current_data.admin,
    });
  }, []);
  useEffect(() => {
    if (
      current_data.name !== data.name ||
      current_data.mobile !== data.number ||
      current_data.email !== data.mail ||
      data.password !== "" ||
      current_data.admin !== data.is_admin
    ) {
      setedit_btn(true);
    } else {
      setedit_btn(false);
    }
  }, [data]);

  return (
    <>
      <div className="resetpasswordteammodal">
        <div className="modal_main">
          <div className="header">
            <Close clickhandel={close} />
          </div>
          <div className="body">
            {/* <div className="title">Name</div>
            <div className="cust_inp">
              <input
                type="text"
                placeholder="Insert Name"
                value={data.name}
                onChange={(e) => setdata({ ...data, name: e.target.value })}
              />
            </div>

            <div className="title">Mail</div>
            <div className="cust_inp">
              <input
                type="text"
                placeholder="Insert Mail"
                value={data.mail}
                onChange={(e) => setdata({ ...data, mail: e.target.value })}
              />
            </div> */}
            <div className="title">New Password</div>
            <div className="cust_inp">
              <input
                type="text"
                value={data.password}
                onChange={(e) => setdata({ ...data, password: e.target.value })}
              />
            </div>
            {/* <div className="title admin_switch">
              <div>Admin</div>
              <Switcher
                active={
                  data.is_admin === 0
                    ? true
                    : data.is_admin === 1
                    ? false
                    : true
                }
                setactive={() => {
                  setdata((prev) => ({
                    ...prev,
                    is_admin: prev.is_admin === 0 ? 1 : 0,
                  }));
                }}
              />
            </div> */}
          </div>
          <div className="footer">
            <div
              className="btn"
              type={edit_btn ? "type2" : "type1"}
              onClick={() => {
                if (edit_btn) {
                  setcheck_passsword(true);
                }
              }}
            >
              Save Password
            </div>
          </div>
        </div>
      </div>
      {check_passsword && (
        <CheckPassword
          close={close}
          btn_name="Save Password"
          setloader={setloader}
          btn_cb={() => {
            setloader(true);
            edit_team_password(
              {
                password: encrypt_data(data.password),
                id: current_data.id,
              },
              current_store.user.token,
              (data) => {
                if (!data.status) {
                  setloader(false);
                  return;
                }
                setloader(false);
                // setteam_list([
                //   ...data.data.employees.map((el, i) => {
                //     return {
                //       id: el.id,
                //       name: el.name,
                //       // mobile: el?.mobile ? el.mobile : "-",
                //       email: el.email,
                //       password: Math.floor(Math.random() * 2) + 6,
                //       admin: el.admin,
                //     };
                //   }),
                // ]);
                // close();
              }
            );
          }}
        />
      )}
    </>
  );
};
