import axios from "axios";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { checkpasssword, delete_team } from "../../../API/API";
// import { base_url } from "../../../API/data";
import { encrypt_data } from "../../../RSA_Encryption/RSA";
import { Close, Eye } from "../../Common/Images/Images";
import { Preview } from "../../FAQ/Preview/Preview";
import "./deleteteamStyle.scss";
export const DeleteTeam = ({
  close,
  setloader,
  current_data,
  setteam_list,
}) => {
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();

  return (
    <div className="team_delete">
      <div>
        <div className="header" onClick={() => close()}>
          <Close />
        </div>
        <div className="title">
          Do you really want to
          <br />
          delete this?
        </div>
        <div className="btn_group">
          <div
            className="btn"
            type="type4"
            onClick={() => {
              setloader(true);
              delete_team(
                { id: current_data.id },
                current_store.user.token,
                (data) => {
                  if (!data.status) {
                    setloader(false);
                    close();
                    navigate("/");
                    return;
                  }
                  setteam_list([
                    ...data.data.employees.map((el, i) => {
                      return {
                        id: el.id,
                        name: el.name,
                        // mobile: el?.mobile ? el.mobile : "-",
                        email: el.email,
                        password: Math.floor(Math.random() * 2) + 6,
                        admin: el.admin,
                      };
                    }),
                  ]);

                  setloader(false);
                  close();
                }
              );
            }}
          >
            Delete
          </div>
          <div className="btn" type="type4" onClick={() => close()}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};
