import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./vieworderStyle.scss";

import { PricelistAccordian } from "../../Component/AddOrders/PricelistAccordian/PricelistAccordian";
import { AddOrderTags } from "../../Component/ViewOrders/AddOrderTags/AddOrderTags";
import { AddOrderImages } from "../../Component/ViewOrders/AddOrderImages/AddOrderImages";
import { AddOrderStatus } from "../../Component/ViewOrders/AddOrderStatus/AddOrderStatus";

import { TrashIconBlack } from "../../Component/Common/Images/Images";

export const ViewOrder = ({ data, setshow_navbar }) => {
  // const current_store = useSelector((state) => state);

  const navigate = useNavigate();
  useEffect(() => {
    setshow_navbar(true);
    if (!data.order_id) {
      navigate("/404");
    }
  }, [data]);
  const [payment_details, setpayment_details] = useState({
    failed_cards: [],
    payed_as: {},
    payed: false,
  });
  const [add_order_current_showing, setadd_order_current_showing] = useState(
    "add_order_menu_tags"
  );
  // items - start
  const [order_item_cards, setorder_item_cards] = useState([
    // { item_name: "a", item_price: "12" },
    // { item_name: "b", item_price: "12" },
    // { item_name: "c", item_price: "12" },
  ]);
  const [sub_total, setsub_total] = useState(0);
  const [item_delete_modal, setitem_delete_modal] = useState({});

  let calc_addon_subtotal = (arr) => {
    if (arr === undefined) {
      return 0;
    }
    if (arr.length === 0) {
      return 0;
    }
    let sum = 0;
    arr.map((el) => (sum += Number(el.price)));
    return sum;
  };
  useEffect(() => {
    let temp_sub_total = 0;
    order_item_cards.map(
      (el) => (temp_sub_total += Number(el.total))
      // (temp_sub_total +=
      //   Number(el.item_price) + Number(calc_addon_subtotal(el.addon_items)))
    );
    setsub_total(temp_sub_total);
  }, [order_item_cards]);
  // items - end

  // tags - start
  const [tag_ids, settag_ids] = useState([]);
  const [to_launch, setto_launch] = useState(0);
  // { order_id: "", order_item: '' },
  // tags - end
  // images/notes - start
  const [globaldiscount, setglobaldiscount] = useState(0);
  const [discount_switch_value, setdiscount_switch_value] = useState("");
  const [discount_switch, setdiscount_switch] = useState(false);

  const [images, setimages] = useState(
    // Object.keys(data).indexOf("cust_img_note") == -1?
    [{ img: "", notes: "" }]
    // : [{ ...data.cust_img_note }]
  );
  useEffect(() => {
    if (Object.keys(data.file_data).length) {
      // order_item_cards, globaldiscount, discount_switch, images, tag_ids
      setorder_item_cards([...data.file_data.order_item]);
      // setglobaldiscount(Number(data.file_data.globaldiscount));
      setdiscount_switch_value(data.file_data.globaldiscount);
      setdiscount_switch(data.file_data.discount_switch);
      setimages([...data.file_data.images_notes]);
      settag_ids([...data.file_data.tags]);
      if (Object.keys(data.file_data.payment_details).length) {
        // setpayment_details({
        //   failed_cards: [],
        //   payed_as: {},
        //   payed: false,
        // });
        // return;
        setpayment_details({
          failed_cards: data.file_data.payment_details.failed_cards,
          payed_as: data.file_data.payment_details.payed_as,
          payed: data.file_data.payment_details.payed,
        });
      }
    }
    setto_launch(1);
  }, []);
  useEffect(() => {
    if (discount_switch) {
      if (sub_total < discount_switch_value) {
        setglobaldiscount(sub_total);
      } else {
        setglobaldiscount(discount_switch_value);
      }
    } else {
      if (discount_switch_value > 100) {
        setglobaldiscount(sub_total);
      } else {
        setglobaldiscount((sub_total / 100) * discount_switch_value);
      }
    }
  }, [discount_switch_value, discount_switch, sub_total]);
  // images/notes - end
  return (
    <div className="add_order">
      <div className="pagetitle">
        <div>View Order</div>
      </div>
      <div className="addorder">
        <div className="current_data_card">
          <div className="data_name">{data.name}</div>
          <div className="data_address">
            {data.address === "no, no, no"
              ? "No address on file"
              : data.address}
          </div>
          <div className="data_items"></div>
          <div className="data_phone">{data.phone}</div>
          <div
            className="data_ccnumber"
            fcolor={data.payment == "" ? "red" : ""}
          >
            {data.payment ? `**** **** ${data.payment}` : "No CC on file"}
          </div>
          {(data.client_particulars?.drycleaning == 1 ||
            data.client_particulars?.laundry == 1 ||
            data.client_particulars?.others == 1) && (
            <div className="data_clientparticulars">
              <div className="particular_title">Client Particulars</div>
              {data.client_particulars.drycleaning == 1 ? (
                <div>Drycleaning</div>
              ) : (
                ""
              )}
              {data.client_particulars.laundry == 1 ? <div>Laundry</div> : ""}
              {data.client_particulars.others == 1 ? <div>Others</div> : ""}
            </div>
          )}
          <div className="card_data_divider"> </div>
          <div className="data_order_items" scrolltype="white">
            {order_item_cards.map((el, i) => (
              <div className="order_item_cards">
                <div className="order_item_name">
                  <div className="item_name">
                    {el.item_name.split("%ITEMS_NAME_DIVIDER%").length == 3 ? (
                      <div className="name_part3">
                        {el.item_name
                          .split("%ITEMS_NAME_DIVIDER%")
                          .map((el1) => (
                            <div>{el1}</div>
                          ))}
                      </div>
                    ) : (
                      <div className="name_part2">
                        {el.item_name
                          .split("%ITEMS_NAME_DIVIDER%")
                          .map((el1) => (
                            <div>{el1}</div>
                          ))}
                      </div>
                    )}
                    {el.item_weight != -1 && (
                      <div className="weight_div">{el.item_weight}lb</div>
                    )}
                  </div>
                  <div>${el.item_price}</div>
                  {/* {el?.addon_items && el.addon_items.length > 0 && ( */}
                  <div className="item_addon_group">
                    {el.addon_items.map((el1, i) => (
                      <div className="item_addonitem">
                        <div className="addon_list">{i + 1}.</div>
                        <div className="addon_name">
                          <div>{el1.addon_title}</div>
                          <div>{el1.addon_sub}</div>
                        </div>
                        <div className="addon_price">
                          ${el1.price.toFixed(2)}
                        </div>
                      </div>
                    ))}
                    {el.addon_items.length > 0 &&
                    Number(el.discount_value) > 0 ? (
                      <div className="divider_line">
                        {/* {el.discount_value} */}
                      </div>
                    ) : (
                      ""
                    )}
                    {/* start options */}
                    {Number(el.discount_value) > 0 &&
                      el.addon_items.length > 0 && (
                        <div className="card_total">
                          {el.addon_items.length > 0 && (
                            <>
                              <div>SubTotal</div>
                              <div>
                                $
                                {Number(el.total) === 0
                                  ? (
                                      Number(el.item_price) +
                                      Number(
                                        el.addon_items.reduce(
                                          (prev, curr) => prev + curr.price,
                                          0
                                        )
                                      )
                                    ).toFixed(2)
                                  : (
                                      Number(el.total) +
                                      (Number(el.discount_value) > 0
                                        ? Number(el.discount_value)
                                        : 0)
                                    ).toFixed(2)}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    {/* {el.discount_value || "not exists"} */}
                    {Number(el.discount_value) > 0 && (
                      <>
                        <div className="card_discount_total">
                          <div>Discount</div>
                          <div>
                            - $
                            {Number(el.total) === 0
                              ? (
                                  Number(el.item_price) +
                                  Number(
                                    el.addon_items.reduce(
                                      (prev, curr) => prev + curr.price,
                                      0
                                    )
                                  )
                                ).toFixed(2)
                              : Number(el.discount_value).toFixed(2)}
                          </div>
                        </div>
                      </>
                    )}
                    <div className="divider_line"></div>
                    <div className="card_total">
                      <div>Total</div>
                      <div>${el.total}</div>
                    </div>
                  </div>
                  {/* // )} */}
                </div>
                {/* <div
                  className={
                    "delete_order_item" +
                    (add_order_current_showing == "add_order_menu_items"
                      ? " show"
                      : " not_show")
                  }
                >
                  <TrashIconBlack
                    white={true}
                    // clickhandel={() => {
                    //   setitem_delete_modal({ i: i, modal_show: true });
                    // }}
                  />
                </div> */}
              </div>
            ))}
          </div>
          <div className="data_subtotal">
            <div className="txt1">Subtotal</div>
            <div className="txt2">${Number(sub_total).toFixed(2)}</div>
          </div>
          <div className="data_discount">
            <div className="txt1">Discount</div>
            <div className="txt2">-${Number(globaldiscount).toFixed(2)}</div>
          </div>
          {/* <div className="data_line"></div> */}
          <div className="data_total">
            <div className="txt1">Total</div>
            <div className="txt2">
              ${Number(sub_total - globaldiscount).toFixed(2)}
            </div>
          </div>
        </div>
        <div className="current_data_options">
          <div className="order_menu">
            {["TAGS", "IMAGES", "STATUS"].map((el) => (
              <div
                className="order_menu_tabs"
                add_order_menu_active={(
                  `add_order_menu_${el.toLowerCase()}` ==
                  add_order_current_showing
                ).toString()}
                onClick={() => {
                  setadd_order_current_showing(
                    "add_order_menu_" + el.toLowerCase()
                  );
                }}

                // id={`add_order_menu_${el.toLowerCase()}`}
              >
                {el}
              </div>
            ))}
          </div>
          <div className="order_menu_body">
            {add_order_current_showing == "add_order_menu_items" ? (
              <>
                {/* <PricelistAccordian
                  order_item_cards={order_item_cards}
                  setorder_item_cards={setorder_item_cards}
                />
                <div className="continue_to_tags">
                  <div
                    className="btn"
                    type={order_item_cards.length == 0 ? "type1" : "type2"}
                    onClick={() => {
                      if (order_item_cards.length == 0) return;
                      setadd_order_current_showing("add_order_menu_tags");
                    }}
                  >
                    Continue to Tags
                  </div>
                </div> */}
              </>
            ) : add_order_current_showing == "add_order_menu_tags" ? (
              <AddOrderTags
                data={data}
                setadd_order_current_showing={setadd_order_current_showing}
                order_item_cards={order_item_cards}
                tag_ids={tag_ids}
                settag_ids={settag_ids}
              />
            ) : add_order_current_showing == "add_order_menu_images" ? (
              <AddOrderImages
                data={data}
                setadd_order_current_showing={setadd_order_current_showing}
                images={images}
                setimages={setimages}
                globaldiscount={globaldiscount}
                setglobaldiscount={setglobaldiscount}
                discount_switch={discount_switch}
                setdiscount_switch={setdiscount_switch}
                discount_switch_value={discount_switch_value}
                setdiscount_switch_value={setdiscount_switch_value}
                sub_total={sub_total}
                // loader={loader}
                // setloader={setloader}
              />
            ) : add_order_current_showing == "add_order_menu_status" ? (
              <AddOrderStatus
                data={data}
                total={Number(sub_total - globaldiscount).toFixed(2)}
                payment_details={payment_details}
                setpayment_details={setpayment_details}
                // setloader={setloader}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {item_delete_modal.modal_show ? (
        <div className="pricelist_item_delete_modal">
          <div>
            <div className="txt">Confirm Delete?</div>
            <div className="pricelist_item_delete_modal_delete_btn_group">
              <div
                className="btn"
                type="type4"
                onClick={() => {
                  let temp_arr = [...order_item_cards];
                  temp_arr.splice(item_delete_modal.i, 1);
                  let current_item_name =
                    order_item_cards[item_delete_modal.i].item_name;
                  setorder_item_cards([...temp_arr]);
                  settag_ids(
                    tag_ids.filter((el) => el.order_item != current_item_name)
                  );
                  setitem_delete_modal(false);
                }}
              >
                Delete
              </div>
              <div
                className="btn"
                type="type4"
                onClick={() => {
                  setitem_delete_modal({ i: -1, modal_show: false });
                }}
              >
                Cancel
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
