import React, { useEffect, useState } from "react";
import { faq_link_icons } from "../AddFAQ";

import "./faqiconStyle.scss";

export const FaqIcons = ({ link_inp, setlink_inp }) => {
  const [icons, seticons] = useState(faq_link_icons);
  const [current_icon, setcurrent_icon] = useState(0);
  const [show_icon_menu, setshow_icon_menu] = useState(false);
  useEffect(() => {
    let c = setInterval(() => {
      setcurrent_icon((prev) => (prev + 1) % icons.length);
    }, 1000);
    return () => {
      clearInterval(c);
    };
  }, []);
  return (
    <div className="link_icon_selector">
      <div className="menu" onClick={() => setshow_icon_menu((prev) => !prev)}>
        <div className="menu_icon_list">
          {link_inp.img
            ? icons.filter((el) => el.title === link_inp.img)[0].svg
            : icons[current_icon].svg}
        </div>
        <div className={"menu_main" + (show_icon_menu ? " active" : "")}>
          {icons.map((el) => (
            <div
              className={"icons" + (el.title === link_inp.img ? " active" : "")}
              onClick={() =>
                setlink_inp((prev) => ({ ...prev, img: el.title }))
              }
            >
              {el.svg}
            </div>
          ))}
          <div
            className={"clear" + (link_inp.img === "" ? " active" : "")}
            onClick={() => setlink_inp((prev) => ({ ...prev, img: "" }))}
          >
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};
