import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { sub } from "date-fns";

import "./custgraphStyle.scss";

const def_months = [
  // 0
  ["Jan", "January"],
  // 1
  ["Feb", "February"],
  // 2
  ["Mar", "March"],
  // 3
  ["Apr", "April"],
  // 4
  ["May", "May"],
  // 5
  ["Jun", "June"],
  // 6
  ["Jul", "July"],
  // 7
  ["Aug", "August"],
  // 8
  ["Sep", "September"],
  // 9
  ["Oct", "October"],
  // 10
  ["Nov", "November"],
  // 11
  ["Dec", "December"],
];

const grey_scale = [
  "9E9E9E",
  "8F8F8F",
  "7F7F7F",
  "6B6B6B",
  "5C5C5C",
  "4C4C4C",
  "5C5C5C",
  "6B6B6B",
  "7F7F7F",
  "8F8F8F",
];
const quarter_arr = [
  [0, 2],
  [0, 2],
  [0, 2],
  [3, 5],
  [3, 5],
  [3, 5],
  [6, 8],
  [6, 8],
  [6, 8],
  [9, 11],
  [9, 11],
  [9, 11],
];
const halfyear_arr = [
  [0, 5],
  [0, 5],
  [0, 5],
  [0, 5],
  [0, 5],
  [0, 5],
  [6, 11],
  [6, 11],
  [6, 11],
  [6, 11],
  [6, 11],
  [6, 11],
];
const calender_options = [
  {
    option: "Today",
    date: () => ({
      startDate: new Date(),
      endDate: new Date(),
    }),
  },
  {
    option: "This month",
    date: () => {
      const current_date = new Date();
      return {
        startDate: new Date(
          current_date.toString().replace(current_date.getDate(), 1)
        ),
        endDate: current_date,
      };
    },
  },
  {
    option: "Last month",
    date: () => {
      const current_date = new Date();
      const current_first_date = new Date(
        current_date.getFullYear(),
        current_date.getMonth()
      );
      return {
        startDate: sub(current_first_date, { months: 1 }),
        endDate: sub(current_first_date, { days: 1 }),
      };
    },
  },
  {
    option: "This quarter",
    date: () => {
      const current_date = new Date();
      const in_quarter = quarter_arr[current_date.getMonth()];

      return {
        startDate: new Date(current_date.getFullYear(), in_quarter[0]),
        endDate: current_date,
        // endDate: sub(
        //   new Date(current_date.getFullYear(), in_quarter[1] + 1),
        //   { days: 1 }
        // ),
      };
    },
  },
  {
    option: "Last quarter",
    date: () => {
      const current_date = new Date();
      const in_quarter = quarter_arr[current_date.getMonth()];

      return {
        startDate: sub(new Date(current_date.getFullYear(), in_quarter[0]), {
          months: 3,
        }),
        endDate: sub(new Date(current_date.getFullYear(), in_quarter[1] + 1), {
          days: 1,
          months: 3,
        }),
      };
    },
  },
  {
    option: "This half year",
    date: () => {
      const current_date = new Date();
      const in_halfyear = halfyear_arr[current_date.getMonth()];
      return {
        startDate: new Date(current_date.getFullYear(), in_halfyear[0]),
        endDate: current_date,
      };
    },
  },
  {
    option: "Last half year",
    date: () => {
      const current_date = new Date();
      const in_halfyear = halfyear_arr[current_date.getMonth()];
      return {
        startDate: sub(new Date(current_date.getFullYear(), in_halfyear[0]), {
          months: 6,
        }),
        endDate: sub(new Date(current_date.getFullYear(), in_halfyear[1]), {
          days: 1,
          months: 5,
        }),
      };
    },
  },
  {
    option: "This year",
    date: () => {
      const current_date = new Date();
      // const in_halfyear = halfyear_arr[current_date.getMonth()];
      return {
        startDate: new Date(current_date.getFullYear(), 0),
        endDate: current_date,
      };
    },
  },
  {
    option: "Last year",
    date: () => {
      const current_date = new Date();
      // const in_halfyear = halfyear_arr[current_date.getMonth()];
      return {
        startDate: sub(new Date(current_date.getFullYear(), 0), {
          months: 12,
        }),
        endDate: sub(new Date(current_date.getFullYear(), 0), { days: 1 }),
      };
    },
  },
];

export const Custgraph1 = ({ data, date }) => {
  const [values, setvalues] = useState({
    def_height: 0,
  });
  const [og_orders, setog_orders] = useState([]);
  const [current_selected_order, setcurrent_selected_order] = useState([
    {},
    false,
  ]);
  // const [og_date, setog_date] = useState([]);
  const [months, setmonths] = useState([{ month: 0 }, , { month: 0 }]);
  const [quantity, setquantity] = useState([0]);
  const [curr_state_order_details, setcurr_state_order_details] = useState({
    horizontal: false,
    vertical: false,
  });
  const [curr_state_order_details_data, setcurr_state_order_details_data] =
    useState("");
  const def_height = useRef();
  const cust_grap_ref = useRef();

  // const check_orders = () => {
  // };

  // useEffect(() => {
  //   if (data.orders && data.orders.length > 0) {
  //     check_orders();
  //   }
  // }, []);
  useLayoutEffect(() => {
    let temp_date = date.map((el) => ({
      month: new Date(el).getMonth(),
      year: new Date(el).getFullYear(),
      date: new Date(el).getDate(),
    }));
    let temp_arr = [
      ...def_months.slice(
        temp_date[0] ? temp_date[0] - 1 : 0,
        temp_date[1] ? temp_date[1] + 2 : 0
      ),
    ];
    // setog_date([...temp_date]);
    // setmonths([...temp_date]);
    // let temp_arr_quantity = [];

    let max_quantity = 0;
    if (data.og_arr && data.og_arr.length >= 0) {
      // setog_orders([...data.og_arr]);
      data.og_arr.map((el) => {
        if (el.items > max_quantity) {
          max_quantity = el.items;
        }
      });
      setquantity(max_quantity);

      print_months(
        [...temp_date],
        // [...data.og_arr, ...data.og_arr, ...data.og_arr.slice(0, 5)]
        [
          ...data.og_arr,
          // ...data.og_arr,
          // ...data.og_arr,
          // ...data.og_arr,
          // ...data.og_arr,
          // ...data.og_arr,
          // ...data.og_arr.slice(0, 4),
        ]
      );
    }

    return () => {};
  }, [data, date]);
  useEffect(() => {
    if (def_height.current) {
      setvalues((prev) => ({
        ...prev,
        def_height: def_height.current?.getBoundingClientRect().height,
      }));
    }
  }, [quantity]);

  let c;
  useEffect(() => {
    if (
      // Object.keys(current_selected_order[0]).length > 0 &&
      current_selected_order[1]
    ) {
      clearTimeout(c);
      c = setTimeout(() => {
        setcurrent_selected_order((prev) => [{ ...prev[0] }, false]);
        // setTimeout(()=>{
        //   setcurrent_selected_order((prev)=>[{...prev[0]},true]);
        // },300)
      }, 5000);
    }
  }, [current_selected_order]);

  const print_months = (month_num, og_arr) => {
    let [start_num_month, end_num_month] = [
      month_num[0].month,
      month_num[1].month,
    ];
    let [start_num_year, end_num_year] = [month_num[0].year, month_num[1].year];
    let month_num_arr = [
      // start_num - 1 === -1 ? 11 : start_num - 1
    ];
    if (end_num_month >= start_num_month && start_num_year === end_num_year) {
      for (let i = start_num_month; i <= end_num_month; i++) {
        month_num_arr.push({ month: i, year: start_num_year, data: [] });
      }
    } else {
      for (let i = start_num_month; i <= 11; i++) {
        month_num_arr.push({ month: i, year: start_num_year, data: [] });
      }
      for (let i = start_num_year + 1; i < end_num_year; i++) {
        for (let j = 0; j <= 11; j++) {
          month_num_arr.push({ month: j, year: i, data: [] });
        }
      }
      for (let i = 0; i <= end_num_month; i++) {
        month_num_arr.push({ month: i, year: end_num_year, data: [] });
      }
    }
    // return month_num_arr;

    let temp_max_plot = 0;
    if (og_arr.length > 0) {
      month_num_arr = month_num_arr.map((el) => {
        let temp_ogfiltered_orders = og_arr.filter((el1) => {
          let [curr_month, curr_year, curr_date] = [
            new Date(el1.order_date).getMonth(),
            new Date(el1.order_date).getFullYear(),
            new Date(el1.order_date).getDate(),
          ];
          return (
            el.month === curr_month &&
            el.year === curr_year &&
            new Date(`${curr_month + 1}/${curr_date}/${curr_year}`).valueOf() >=
              new Date(
                `${month_num[0].month + 1}/${month_num[0].date}/${
                  month_num[0].year
                }`
              ).valueOf() &&
            new Date(`${curr_month + 1}/${curr_date}/${curr_year}`).valueOf() <=
              new Date(
                `${month_num[1].month + 1}/${month_num[1].date}/${
                  month_num[1].year
                }`
              ).valueOf()
          );
        });
        let check_max_items_per_month = temp_ogfiltered_orders.length;
        // .reduce(
        //   (prev, curr) => prev + curr.items,
        //   0
        // );
        if (temp_max_plot < check_max_items_per_month) {
          temp_max_plot = check_max_items_per_month;
        }
        return {
          ...el,
          data: temp_ogfiltered_orders,
        };
      });
    }
    setquantity(temp_max_plot);
    // console.table(month_num_arr);
    setmonths([...month_num_arr]);
  };

  return (
    <div
      ref={cust_grap_ref}
      className="custgraph"
      type="type1"
      style={{
        "--def_height":
          quantity > 17 ? values.def_height / 5 : values.def_height,
      }}
    >
      {/* <div
        className={
          "show_order_details" + (current_selected_order[1] ? " active" : "")
        }
      >
        <div>Order ID - {current_selected_order[0].order_id}</div>
        <div>Items - {current_selected_order[0].items}</div>
        <div>Amount -{current_selected_order[0].amount || 0}</div>
      </div> */}
      {/* <div className="year">
        Year - {new Date(date[0]).getFullYear()}
        {new Date(date[0]).getFullYear() !==
          new Date(date[1]).getFullYear() && (
            <>
            &nbsp;to&nbsp;
            {new Date(date[1]).getFullYear()}
            </>
            )}
          </div> */}
      {/* <div>test qunantaty - {quantity}</div>s */}
      <div className="y_axis">
        {new Array(quantity)
          .fill(1)
          .map((el, i) =>
            i == 0 ? (
              <div ref={def_height}>
                {quantity > 17
                  ? (parseInt((quantity - i) / 5) +
                      ((quantity - i) % 5 > 0 ? 1 : 0)) *
                    5
                  : quantity}
              </div>
            ) : quantity > 17 ? (
              (quantity - i) % 5 == 0 ? (
                <div>{quantity - i}</div>
              ) : (
                ""
              )
            ) : (
              <div>{quantity - i}</div>
            )
          )}
      </div>
      <div className="x_axis" type={months.length}>
        {/* {months.length>6?"":""} */}
        {months.map((el, i) => (
          <div
            className="order_item_months"
            style={{
              "--order_count": el.data ? el.data.length : 0,
              // ...(months.length > 6
              //   ? {
              //       writingMode: "vertical-lr",
              //     }
              //   : {}),
            }}
          >
            {months.length > 10 ? (
              i === 0 || i == months.length - 1 ? (
                <div
                  style={{
                    writingMode: "vertical-lr",
                    transform: "rotate(-150deg)",
                  }}
                >
                  {def_months[el.month][0]}
                </div>
              ) : i == 1 ? (
                <div className="arrow_left">{"<"}</div>
              ) : i === months.length - 2 ? (
                <div className="arrow_right">{">"}</div>
              ) : (
                <div className="arrow_line">{"-"}</div>
              )
            ) : // def_months[el.month][0]
            def_months[el.month] ? (
              months.length > 6 ? (
                <div
                  style={{
                    writingMode: "vertical-lr",
                    transform: "rotate(-150deg)",
                  }}
                >
                  {def_months[el.month][0]}
                </div>
              ) : (
                def_months[el.month][0]
              )
            ) : (
              "err"
            )}
            <div
              className="order_items_group"
              style={
                {
                  // background:
                  // backgroundImage: "linear-gradient(0deg , #9E9E9E ,#8f8f8f)",
                  // backgroundOrigin: "0 100%",
                  // backgroundSize: `${100 / (quantity / 10)}%`,
                }
              }
            >
              {/* {new Array(quantity).fill(1).map((el1, i) => {
                if (!el.data) {
                  return "";
                }
                let temp_arr = el.data
                .filter((el2) => el2.items === quantity - i)
                .map((el) => el.order_id)
                .join("|,|");
                if (temp_arr === "") {
                  return "";
                }
                return (
                  <div>
                  {quantity - i} = {temp_arr}
                  </div>
                  );
              })} */}
              {el.data &&
                el.data.map((el1, i1) => (
                  <div
                    className="order_items_main"
                    style={{
                      "--background_color": `#${grey_scale[i1 % 10]}`,
                      "--set_height": el1.items,
                      // "--order_num": el.data.length - i1 - 1,
                      // writingMode: el?.data.length > 3 ? "vertical-lr" : "",
                    }}
                    // onClick={() =>
                    //   setcurrent_selected_order([{ ...el1 }, true])
                    // }
                    onMouseEnter={() =>
                      setcurr_state_order_details_data(i + "_" + i1)
                    }
                    type_horizontal={curr_state_order_details.horizontal.toString()}
                    // type_vertical={curr_state_order_details.vertical.toString()}
                    onClick={(e) => {
                      let curr_rect = e.target.getBoundingClientRect();

                      let [bound_top, bound_right, bound_bottom, bound_left] = [
                        cust_grap_ref.current.getBoundingClientRect().x,
                        cust_grap_ref.current.getBoundingClientRect().y,
                        cust_grap_ref.current.getBoundingClientRect().bottom,
                        cust_grap_ref.current.getBoundingClientRect().left,
                      ];
                      let [curr_top, curr_right, curr_bottom, curr_left] = [
                        e.target.firstChild.getBoundingClientRect().top,
                        e.target.firstChild.getBoundingClientRect().right,
                        e.target.firstChild.getBoundingClientRect().bottom,
                        e.target.firstChild.getBoundingClientRect().left,
                      ];

                      setcurr_state_order_details_data(i + "_" + i1);
                      setcurr_state_order_details({
                        horizontal:
                          curr_rect.right + 160 >
                          cust_grap_ref.current.getBoundingClientRect().right,
                        vertical:
                          curr_rect.bottom + 200 >
                          cust_grap_ref.current.getBoundingClientRect().bottom,
                      });
                    }}
                    onMouseLeave={() => {
                      setcurr_state_order_details_data(false);
                      // setcurr_state_order_details(false);
                    }}
                  >
                    <div
                      className="order_items_modal"
                      style={
                        {
                          // "--bottom_percentage": `${
                          //   (100 * i1) / el.data.length
                          // }%`,
                          // "--test": i1,
                          // "--test1": el.data.length / 2,
                          // ...(i1 !== 0 ? { left: 0 } : { right: "100%" }),
                          // ...(i1 !== 0
                          //   ? { bottom: "calc(100% + 10px)" }
                          //   : { bottom: 0, top: 0, margin: "auto 0" }),
                        }
                      }
                      type={(
                        curr_state_order_details_data ===
                        i + "_" + i1
                      ).toString()}
                    >
                      <div>Order ID - {el1.order_id}</div>
                      <div>Items - {el1.items || 0}</div>
                      <div>Amount - ${el1.amount || 0}</div>
                      <div>
                        Date -{" "}
                        {el1.order_date
                          ? el1.order_date
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("/")
                          : "date err"}
                      </div>
                    </div>
                    {/* {el1.order_id} */}
                    {/* <div>{el1.items || 0}</div> */}
                    {/* <div className="show_order_modal">
                      {/* Order ID -{el.order_id} */}
                    {/* Items -{el.items} */}
                    {/* Amount -{el1.amount || 0} */}
                    {/* </div> */}
                  </div>
                ))}
            </div>
          </div>
        ))}
        {/* {months.map((el) => {
          // if (months.length > 5) {
          //   return <div>{el[0]}</div>;
          // }
          return <div>{el[0]}</div>;
        })} */}
      </div>
    </div>
  );
};
