import React, { useEffect, useState } from "react";
import "./cardsStyle.scss";
import Add_icon_for_card from "../../../../img/cards/card_add.png";
import CountUp from "react-countup";

const NumberAnimated = (num, pred) => {
  // const [current_num, setcurrent_num] = useState(true);
  // useEffect(() => {
  //   if (current_num) {
  //     setcurrent_num(false);
  //     setTimeout(() => {
  //       setcurrent_num(true);
  //     }, 10);
  //   }
  // }, [pred]);

  // if (num == "-" || !num) {
  //   return 0;
  // }
  // if (!current_num) {
  //   return 0;
  // }

  // console.log(Number(num), num);
  if (!num) {
    return "-";
  }
  // console.log(num.split("."));
  // if (!num?.split()) {
  //   return num;
  // }
  num = (num + "").split(".");

  if (num.length == 2) {
    return (
      <>
        <CountUp end={num[0]} />.
        {num[1].split("").reverse()[0] === "%" ? (
          <>
            <CountUp end={num[1].slice(0, -1)} />%
          </>
        ) : (
          <CountUp end={num[1]} />
        )}
      </>
    );
  }
  // if (num.length == 1) {
  // }
  return <CountUp end={num} />;
  // if (Number(num)) {
  //   return <CountUp end={num} />;
  // }
  // let num_arr = num.split("");
  // let str = num_arr[num_arr.length - 1];
  // num_arr.length = num_arr.length - 1;
  // let new_num = num_arr.join("");
  // console.log(str, "....", new_num);
  // return (
  //   <>
  //     {NumberAnimated(new_num)}
  //     {str}
  //   </>
  // );
};

// const num_str = (num) => {
//   if (Number(num)) {
//     return;
//   }
//   let num_arr = num.split("");
//   let str = num_arr[num_arr.length - 1];
//   num_arr.length = num_arr.length - 1;
//   let new_num = num_arr.join("");
// };
export const ClientCards = ({ card_arr, last_card }) => {
  const [current_data, setcurrent_data] = useState([
    "retentionrate_selected1",
    "avg.order_selected1",
  ]);
  const [prediction, setprediction] = useState(false);
  const handel_current_data = (txt) => {
    switch (txt.split("_")[0]) {
      case "retentionrate":
        setcurrent_data((prev) => [txt, prev[1]]);
        return;
      case "avg.order":
        setcurrent_data((prev) => [prev[0], txt]);
        return;

      default:
        break;
    }
  };
  // console.log(current_data);
  return (
    <div className="client_cards_group" predictions={prediction.toString()}>
      {card_arr.map((el, i) => {
        // console.log(el);
        if (el.divide && el.divide === 3) {
          return (
            <div className="card_flip">
              <div key={i} className="card_body avg_order_card card_front">
                <div className="card_left">
                  <div className="card_title">{el.title}</div>
                  <div
                    className="card_num"
                    onClick={() => {
                      setprediction(true);
                    }}
                  >
                    {current_data
                      ? current_data.includes(
                          el.title
                            .split(" ")
                            .map((el) => el.toLowerCase())
                            .join("") + "_selected1"
                        )
                        ? el.value[0][0] !== 0
                          ? !prediction
                            ? NumberAnimated(el.value[0][0])
                            : el.value[0][0]
                          : current_data
                        : current_data.includes(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected2"
                          )
                        ? el.value[1][0] !== 0
                          ? !prediction
                            ? NumberAnimated(el.value[1][0])
                            : el.value[1][0]
                          : current_data
                        : current_data.includes(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected3"
                          )
                        ? el.value[2][0] !== 0
                          ? !prediction
                            ? NumberAnimated(el.value[2][0])
                            : el.value[2][0]
                          : "-"
                        : "-"
                      : "-"}
                  </div>
                </div>
                <div className="card_value">
                  {el.value[0] !== 0 ||
                  el.value[1] !== 0 ||
                  el.value[2] !== 0 ? (
                    <>
                      <div
                        className={
                          current_data.includes(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected1"
                          )
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handel_current_data(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected1"
                          )
                        }
                      >
                        {el.options[0]}
                      </div>
                      <div
                        className={
                          current_data.includes(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected2"
                          )
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handel_current_data(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected2"
                          )
                        }
                      >
                        {el.options[1]}
                      </div>
                      <div
                        className={
                          current_data.includes(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected3"
                          )
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handel_current_data(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected3"
                          )
                        }
                      >
                        {el.options[2]}
                      </div>
                    </>
                  ) : (
                    //   </div>
                    // </div>
                    "-"
                  )}
                </div>
              </div>
              <div
                key={i + "back"}
                className="card_body avg_order_card card_back"
              >
                <div className="card_left">
                  <div className="card_title">
                    {el.title} <br />
                    Prediction
                  </div>
                  <div
                    className="card_num"
                    onClick={() => {
                      setprediction(false);
                    }}
                  >
                    {current_data
                      ? current_data.includes(
                          el.title
                            .split(" ")
                            .map((el) => el.toLowerCase())
                            .join("") + "_selected1"
                        )
                        ? el.value[0][1] !== 0
                          ? prediction
                            ? NumberAnimated(el.value[0][1])
                            : el.value[0][1]
                          : "-"
                        : current_data.includes(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected2"
                          )
                        ? el.value[1][1] !== 0
                          ? prediction
                            ? NumberAnimated(el.value[1][1])
                            : el.value[1][1]
                          : "-"
                        : current_data.includes(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected3"
                          )
                        ? el.value[2][1] !== 0
                          ? prediction
                            ? NumberAnimated(el.value[2][1])
                            : el.value[2][1]
                          : "-"
                        : "-"
                      : "-"}
                  </div>
                </div>
                <div className="card_value">
                  {el.value[0] !== 0 ||
                  el.value[1] !== 0 ||
                  el.value[2] !== 0 ? (
                    <>
                      <div
                        className={
                          current_data.includes(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected1"
                          )
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handel_current_data(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected1"
                          )
                        }
                      >
                        {el.options[0]}
                      </div>
                      <div
                        className={
                          current_data.includes(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected2"
                          )
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handel_current_data(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected2"
                          )
                        }
                      >
                        {el.options[1]}
                      </div>
                      <div
                        className={
                          current_data.includes(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected3"
                          )
                            ? "active"
                            : ""
                        }
                        onClick={() =>
                          handel_current_data(
                            el.title
                              .split(" ")
                              .map((el) => el.toLowerCase())
                              .join("") + "_selected3"
                          )
                        }
                      >
                        {el.options[2]}
                      </div>
                    </>
                  ) : (
                    //   </div>
                    // </div>
                    "-"
                  )}
                </div>
              </div>
            </div>
          );

          return (
            <div key={i} className="card_body">
              <div className="card_title">{el.title}</div>
              <div className="card_value">
                {el.value[0] !== 0 || el.value[1] !== 0 || el.value[2] !== 0 ? (
                  <div className="animate_card_to_infinity">
                    <div style={{ display: "flex" }}>
                      {el.value[0] !== 0 && <div>{el.value[0]}/Year</div>}
                      {el.value[1] !== 0 && <div>{el.value[1]}/Month</div>}
                      {el.value[2] !== 0 && <div>{el.value[2]}/Day</div>}
                      {el.value[0] !== 0 && <div>{el.value[0]}/Year</div>}
                      {el.value[1] !== 0 && <div>{el.value[1]}/Month</div>}
                      {el.value[2] !== 0 && <div>{el.value[2]}/Day</div>}
                    </div>
                  </div>
                ) : (
                  "-"
                )}
              </div>
            </div>
          );
        }
        return (
          <div className="card_flip">
            <div
              key={i}
              className="card_body card_front"
              onClick={() => {
                setprediction(true);
              }}
            >
              <div className="card_title">{el.title}</div>
              <div className="card_value">
                {prediction ? el.value[0] : NumberAnimated(el.value[0])}
              </div>
            </div>
            <div
              key={i + "back"}
              className="card_body card_back"
              onClick={() => {
                setprediction(false);
              }}
            >
              <div className="card_title">
                {el.title} <br /> Prediction
              </div>
              <div className="card_value">
                {prediction ? <CountUp end={el.value[1]} /> : el.value[1]}
              </div>
            </div>
          </div>
        );
      })}
      {last_card && (
        <div className="card_body last_card" onClick={last_card.click}>
          <img src={Add_icon_for_card} />
          <div>{last_card.title}</div>
        </div>
      )}
    </div>
  );
};
