import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { delete_vendors } from "../../../API/API";
import { Close } from "../../Common/Images/Images";
import "./deleteVendor.scss";
export const Delete_Vendor = ({ close, setloader, data, setvendor_table }) => {
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();

  return (
    <div className="vendor_delete">
      <div>
        <div className="header" onClick={() => close()}>
          <Close />
        </div>
        <div className="title">
          Do you really want to
          <br />
          delete this?
        </div>
        <div className="btn_group">
          <div
            className="btn"
            type="type4"
            onClick={() => {
              setloader(true);
              delete_vendors(
                { id: data.id },
                current_store.user.token,
                (data) => {
                  if (!data.status) {
                    setloader(false);
                    close();
                    return navigate("/");
                  }
                  setvendor_table([
                    ...data.data.vendors.map((el) => ({
                      id: el.id,
                      name: el.name,
                      add: el.address,
                      mobile: el.mobile_no.slice(1),
                      note: el.notes,
                    })),
                  ]);
                  setloader(false);
                  close();
                }
              );
            }}
          >
            Delete
          </div>
          <div className="btn" type="type4" onClick={() => close()}>
            Cancel
          </div>
        </div>
      </div>
    </div>
  );
};
