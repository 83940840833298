import React, { useEffect, useState } from "react";
import { Close } from "../../Common/Images/Images";
import { Switcher } from "../../Switcher/Switcher";

import "./addteamModalStyle.scss";

export const ViewteamModal = ({ close, current_data }) => {
  const [data, setdata] = useState({
    name: "",
    number: "",
    mail: "",
    password: "",
  });
  const [edit_btn, setedit_btn] = useState(false);
  useEffect(() => {
    setdata({
      ...data,
      name: current_data.name,
      number: current_data.mobile,
      mail: current_data.email,
    });
  }, []);
  // useEffect(() => {
  //   if (
  //     current_data.name !== data.name ||
  //     current_data.mobile !== data.number ||
  //     current_data.email !== data.mail ||
  //     data.password !== ""
  //   ) {
  //     setedit_btn(true);
  //   } else {
  //     setedit_btn(false);
  //   }
  // }, [data]);

  return (
    <div className="addteammodal">
      <div className="modal_main">
        <div className="header">
          <Close clickhandel={close} />
        </div>
        <div className="body">
          <div className="title">Name</div>
          <div className="cust_inp">
            <input
              type="text"
              placeholder="Insert Name"
              value={data.name}
              readOnly={true}
              // onChange={(e) => setdata({ ...data, name: e.target.value })}
            />
          </div>
          {/* <div className="title">Number</div>
          <div className="cust_inp">
          <input
          type="text"
          placeholder="Insert Number"
          value={data.number}
          // onChange={(e) => setdata({ ...data, number: e.target.value })}
          />
        </div> */}
          <div className="title">Mail</div>
          <div className="cust_inp">
            <input
              type="text"
              placeholder="Insert Mail"
              value={data.mail}
              // readOnly={true}
              // onChange={(e) => setdata({ ...data, mail: e.target.value })}
            />
          </div>
          <div className="title">Password</div>
          <div className="cust_inp">
            <input
              type="text"
              placeholder={
                current_data.password
                  ? new Array(Number(current_data.password)).fill("*").join("")
                  : "Insert Password"
              }
              value={data.password}
              readOnly={true}
              // onChange={(e) => setdata({ ...data, password: e.target.value })}
            />
          </div>
          <div className="title admin_switch">
            <div>Admin</div>
            <Switcher
              active={
                current_data.admin === 0
                  ? true
                  : current_data.admin === 1
                  ? false
                  : true
              }
              setactive={
                () => ""
                //   {
                //   setteam_data((prev) => ({
                //     ...prev,
                //     is_admin: prev.is_admin === 0 ? 1 : 0,
                //   }));
                // }
              }
            />
          </div>
        </div>
        {/* <div className="footer">
          <div className="btn" type={edit_btn ? "type2" : "type1"}>
            Add Employee
          </div>
        </div> */}
      </div>
    </div>
  );
};
