import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as Arrow_down } from "../../../img/icons/black/arrow_down_icon.svg";
import "./custdropdownStyle.scss";

export const CustDropdown = ({
  custid,
  value,
  custclass,
  defaultplaceholder,
  dropdown_arr,
  tempclose,
  setvalue,
}) => {
  // const [dropdown_value, setdropdown_value] = useState('')
  const [dropdown_id, setdropdown_id] = useState(custid || "");
  const [dropdown_active, setdropdown_active] = useState(false && !tempclose);
  const get_height = useRef();
  const dropdown = useRef();
  useEffect(() => {
    // if (value) {
    // }
    window.addEventListener("click", (e) => {
      let flyoutEl = document.getElementById("custdropdown" + custid),
        targetEl = e.target; // clicked element
      do {
        if (targetEl == flyoutEl) {
          return;
        }
        targetEl = targetEl.parentNode;
      } while (targetEl);
      setdropdown_active(false);
    });
  }, []);
  // useEffect(() => {
  //     // if (value != '') {
  //     setdropdown_value((value && value !== '') ? value : defaultplaceholder)
  //     // }
  // }, [value])
  // useEffect(() => {
  //     if (dropdown_value != defaultplaceholder) {
  //         setvalue(dropdown_value)
  //     }
  // }, [dropdown_value])
  return (
    <div
      ref={dropdown}
      className={
        "custdropdown " +
        (custclass || "") +
        (dropdown_active ? " custdropdown_active " : "")
      }
      id={"custdropdown" + dropdown_id}
      onClick={() => {
        setdropdown_active(!dropdown_active);
      }}
    >
      <div className="default_txt">
        <div>
          {value === "" || value == undefined
            ? defaultplaceholder
            : dropdown_arr[value].value}
        </div>
        <Arrow_down
          className="dropdown_arrow_down"
          style={
            !dropdown_active || dropdown_arr.length == 0
              ? {}
              : { transform: "rotate(180deg)" }
          }
        />
      </div>
      <div
        className={"dropdown_body"}
        style={
          !dropdown_active
            ? { height: "0px", opacity: 1 }
            : {
                maxHeight: `${
                  get_height.current.getBoundingClientRect().height >
                  dropdown.current?.getBoundingClientRect().bottom
                    ? dropdown.current?.getBoundingClientRect().bottom - 100
                    : get_height.current.getBoundingClientRect().height
                }px`,
                zIndex: 1000,
                opacity: 1,
                overflowY: "scroll",
              }
        }
      >
        <div ref={get_height}>
          {dropdown_arr.map((el) => (
            <div
              className={
                "dropdown_item " +
                (value === el.key ? "dropdown_item_active" : "")
              }
              onClick={() => {
                // setdropdown_value(el.key)
                if (setvalue) {
                  setvalue(el.key);
                }
              }}
            >
              {el.value}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
