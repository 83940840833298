import React, { useEffect, useState } from "react";
import { mobile_convert_display } from "../../../API/API";
import { Close } from "../../Common/Images/Images";
import "./addvendorStyle.scss";

export const ViewVendor = ({ data, close }) => {
  const [vendor_details, setvendor_details] = useState({
    id: "",
    naem: "",
    number: "",
    address: "",
    note: "",
  });
  useEffect(() => {
    setvendor_details({
      id: data.id,
      name: data.name,
      number: data.mobile,
      address: data.add,
      note: data.note,
    });
  }, [data]);
  return (
    <div className="add_vendor">
      <div>
        <div className="header">
          <Close clickhandel={() => close()} />
        </div>
        <div className="body">
          {[
            {
              title: "Name",
              input: {
                type: "text",
                placeholder: "Insert Name",
                value: vendor_details.name,
                update_value: ({ target }) => "",
                // setvendor_details((prev) => ({
                //   ...prev,
                //   name: target.value,
                // })),
              },
            },
            {
              title: "Number",
              input: {
                type: "text",
                placeholder: "Insert Number",
                value: mobile_convert_display(vendor_details.number),
                update_value: ({ target }) => "",
                // setvendor_details((prev) => ({
                //   ...prev,
                //   number: target.value,
                // })),
              },
            },
            {
              title: "Address",
              input: {
                type: "text",
                placeholder: "Insert Address",
                value: vendor_details.address,
                update_value: ({ target }) => "",
                // setvendor_details((prev) => ({
                //   ...prev,
                //   address: target.value,
                // })),
              },
            },
          ].map((el) => (
            <div>
              <div className="title">{el.title}</div>
              <div className="cust_input">
                <input
                  type={el.input.type}
                  placeholder={el.input.placeholder}
                  value={el.input.value}
                  // onChange={el.input.update_value}
                  readOnly={true}
                />
              </div>
            </div>
          ))}
          <div>
            <div className="title">Note</div>
            <div className="cust_input with_textarea">
              <textarea
                type="text"
                placeholder='Insert Note;Like "Leather, Bridal"'
                value={vendor_details.note}
                onChange={({ target }) =>
                  setvendor_details((prev) => ({ ...prev, note: target.value }))
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
