import React, { useState } from "react";
import "./addorderimagesStyle.scss";

import { AddOrderImagesModal } from "./AddOrderImagesModal/AddOrderImagesModal";

import image_add_img from "../../../img/miscellaneous/close_square_add.png";
import camera from "../../../img/miscellaneous/camera.png";
import delete_img_note from "../../../img/miscellaneous/trash.png";

export const AddOrderImages = ({
  data,
  // setadd_order_current_showing,
  images,
  // setimages,
  // globaldiscount,
  // setglobaldiscount,
  discount_switch,
  // setdiscount_switch,
  discount_switch_value,
  // setdiscount_switch_value,
  // sub_total,
}) => {
  const [current_image_index, setcurrent_image_index] = useState("");
  const [image_modal, setimage_modal] = useState(false);
  // const add_img = (current, i) => {
  //   if (current.target.files.length) {
  //     let temp_arr = [];
  //     Object.values(current.target.files).map((el) => {
  //       var reader = new FileReader();
  //       reader.readAsDataURL(el);
  //       reader.onload = function () {
  //         temp_arr.push(reader.result);
  //         if (current.target.files.length == temp_arr.length) {
  //           let temp_images = [...images];
  //           temp_images[i].img = [...temp_arr];
  //           setimages([...temp_images]);
  //         }
  //       };
  //     });
  //   }
  // };
  // const handle_change_note = (data, i) => {
  //   let temp_images = [...images];
  //   temp_images[i].note = data;
  //   setimages([...temp_images]);
  // };

  return (
    <div className="addorderimages">
      <div className="addorderimages_title">Customer Image/Note</div>
      <div className="addorderimages_group">
        {images.map((el, i) => {
          if (i == 0 && el.img.length == 0 && el.notes.length == 0) {
            return (
              <div div className="images_set_main_body">
                <div className="addorderimages_img_set">
                  <div className="images">
                    <img src="" />
                    <div
                      className="images_overlay"
                      onClick={() => {
                        // setimage_modal(true)
                        // setcurrent_image_index(i)
                      }}
                    >
                      0
                    </div>
                  </div>
                </div>
                <textarea value="No Note" />
              </div>
            );
          }
          return (
            <>
              {i == 1 ? (
                <div className="addorderimages_title">Add Image/Note</div>
              ) : (
                ""
              )}
              <div div className="images_set_main_body">
                <div className="addorderimages_img_set">
                  {el.img.length > 0 ? (
                    <div className="images">
                      <img src={el.img[0]} />
                      <div
                        className="images_overlay"
                        onClick={() => {
                          setimage_modal(true);
                          setcurrent_image_index(i);
                        }}
                      >
                        {el.img.length}
                      </div>
                    </div>
                  ) : (
                    <>
                      {/* <input
                        type="file"
                        accept=".jpg,.png"
                        id={"add_img" + i}
                        onChange={(e) => add_img(e, i)}
                        multiple
                      />
                      <label htmlFor={"add_img" + i}>
                        <img className="camera" src={camera} />
                      </label> */}
                    </>
                  )}
                </div>
                {/* not completed check mtlp EDITMODAL.JS line 725  */}
                {/* <label
                                    className=" t-white"
                                    mouse="pointer"
                                    htmlFor="addphoto"
                                    onClick={() => { }}
                                    >
                                    + Add photo
                                </label> */}
                <textarea
                  value={el.note || el.notes}
                  placeholder="Add Note"
                  // onChange={(e) => {
                  //   if (i == 0) return;
                  //   handle_change_note(e.target.value, i);
                  // }}
                />
                {/* <div className="delete_img_note">
                  <img
                    src={delete_img_note}
                    style={{ visibility: i == 0 ? "hidden" : "visible" }}
                    onClick={() => {
                      if (i == 0) return;
                      let temp_images = [...images];
                      temp_images.splice(i, 1);
                      setimages([...temp_images]);
                    }}
                  />
                </div> */}
              </div>
            </>
          );
        })}
        {/* <div className="addorderimages_icon">
          <img
            src={image_add_img}
            onClick={() => {
              setimages([...images, { img: [], note: "" }]);
            }}
          />
        </div> */}
      </div>
      <div className="global_discount">
        <div>
          <div className="addorderimages_title">Discount</div>
          <div className="discount_group">
            {/* <input type='checkbox' id='globaldiscountswitchdollar_to_percent' /> */}
            <div
              className="discountinput_group"
              show={discount_switch ? "dollar" : "percentage"}
            >
              <div>$</div>
              <input
                placeholder="Amount"
                type="number"
                value={discount_switch_value}
                // onChange={(e) => {
                //   setdiscount_switch_value(e.target.value);
                // }}
              />
              <div>%</div>
            </div>
            {/* <div className="switch_convert_dollar_and_percent">
              <div
                className="main_bg"
                onClick={() => {
                  setdiscount_switch(!discount_switch);
                }}
              >
                <div
                  className="slider_btn"
                  show={discount_switch ? "dollar" : "percentage"}
                ></div>
              </div>
            </div> */}
          </div>
        </div>
        <div>
          <div className="customer_discount_code">
            {
              data.discount_code ? (
                <>
                  <div>Customer Added Code:</div>
                  <div>{data.discount_code}</div>
                </>
              ) : (
                <></>
              )
              // 'no discount code'
            }
          </div>
          {/* <div className='addorderimages_title'>
                        Customer Discount
                    </div>
                    <div className='custdropdown_discount_code'>
                        <div className='custdropdown_discount_code_body' show={discount_switch ? 'dollar' : 'percentage'}>
                            <div >
                                $
                            </div>
                            <input placeholder='Choose Amount' type='number' value={discount_switch_value} onChange={(e) => { setdiscount_switch_value(e.target.value) }} />
                            <div >
                                %
                            </div>
                        </div>
                    </div> */}
        </div>
      </div>
      {/* <div className="image_btn_group">
        <div
          className="btn"
          type="type2"
          onClick={() => setadd_order_current_showing("add_order_menu_tags")}
        >
          Add another Tag
        </div>
        <div
          className="btn"
          type="type3"
          onClick={() => setadd_order_current_showing("add_order_menu_status")}
          // setadd_order_current_showing("add_order_menu_images");
        >
          Submit Order
        </div>
      </div> */}
      {image_modal && (
        <AddOrderImagesModal
          images={images}
          current_image_index={current_image_index}
          setimage_modal={setimage_modal}
          // setimages={setimages}
        />
      )}
    </div>
  );
};
