import React, { useEffect, useState } from "react";
import { ArrowDown, Eye, Pencil, Tick } from "../Images/Images";
import "./accordianStyle.scss";

export const Accordian = ({
  acc_obj,
  viewedit,
  show_client_status,
  client_show_toggel,
}) => {
  const [open, setopen] = useState(false);
  // useEffect(() => {
  //     if (!acc_obj.id) {
  //         return
  //     }
  //     window.onclick =
  //         (e) => {
  //             let flyoutEl = document.getElementById('custaccordian' + acc_obj.id),
  //                 targetEl = e.target;
  //             do {
  //                 if (targetEl == flyoutEl) {
  //                     // clicked inside
  //                     return;
  //                 }
  //                 targetEl = targetEl.parentNode;
  //             } while (targetEl);
  //             // clicked outside
  //         }

  // }, [])
  if (!acc_obj.id && acc_obj.id !== 0) {
    return <div>give id to accordians</div>;
  }
  return (
    <div className="accordian" id={"custaccordian" + acc_obj.id}>
      <div
        className="accordian_header"
        onClick={() => {
          setopen(!open);
        }}
      >
        {acc_obj.name}

        <ArrowDown
          custStyle={{ transform: `rotate(${open ? 180 : 0}deg)` }}
          type="sm"
        />
      </div>
      <Pencil
        edit={true}
        type="m"
        clickhandel={viewedit}
        custstyle={{
          height: 23,
          width: 23,
          position: "absolute",
          top: "7px",
          //   bottom: "20px",
          right: "40px",
          //   margin: "auto",
        }}
      />
      <Tick
        type="m"
        color={show_client_status === 1 ? "4" : "3"}
        clickhandel={client_show_toggel}
        style={{
          // border: "1px solid red",
          position: "absolute",
          top: 6,
          // bottom: 0,
          right: "80px",
          // margin: "auto",
          border: "1px solid var(--svg_current_color)",
          height: 25,
          width: 25,
          padding: 5,
          borderRadius: "var( --border-radius-6)",
        }}
      />
      <div
        className={"accordian_body"}
        style={{
          height: `${
            open
              ? document.getElementById("accordian_get_height" + acc_obj.id)
                  .clientHeight
              : 0
          }px`,
        }}
      >
        <div id={"accordian_get_height" + acc_obj.id}>{acc_obj.acc_body}</div>
      </div>
    </div>
  );
};
