import React, { useEffect, useLayoutEffect, useState } from "react";
import "./chargeordermodalStyles.scss";

import { Close } from "../../../Common/Images/Images";
import { chargeorder } from "../../../../API/API";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// const run_apis = !false;
export const ChargeOrderModal = ({
  data,
  setpayment_modal,
  total,
  delivery,
  payment_details,
  setpayment_details,
  setloader,
  setstatuses,
}) => {
  const navigate = useNavigate();
  const [current_active, setcurrent_active] = useState("");
  const [card_err, setcard_err] = useState(false);
  const [card_api_err, setcard_api_err] = useState("");
  const [card_dropdown, setcard_dropdown] = useState(false);
  const [card_dropdown_active, setcard_dropdown_active] = useState(false);
  const [current_selected_card, setcurrent_selected_card] = useState();

  const current_store = useSelector((state) => state);
  useLayoutEffect(() => {
    if (data.payment == null && data.card_list.length == 0) {
      setcard_err(true);
    }
    if (payment_details.failed_cards.length != 0) {
      setcard_dropdown(true);
      if (
        data.card_list.filter(
          (el) =>
            [
              ...payment_details.failed_cards,
              data.card_list.filter((el) => el.is_primary == "1")[0],
            ]
              .map((el) => el.id)
              .indexOf(el.id) == -1
        ).length == 0
      ) {
        setcard_err(true);
      }
    }
    setcurrent_selected_card(
      data.card_list.length == 0
        ? ""
        : data.card_list.filter((el) => el.is_primary == "1")[0].id
    );
  }, []);
  const handelchargenow = () => {
    if (
      ["card", "cash", "mark_as_charged", "manual_card", "pay_later"].indexOf(
        current_active
      ) == -1
    ) {
      return;
    }
    switch (current_active) {
      case "card":
        if (current_selected_card != "") {
          let card_obj = {
            order_id: data.order_id,
            amount: total,
            delivery: delivery,
            client_id: data.client_id,
            payment_type: current_active,
            card_id: current_selected_card,
            dateTime: new Date(),
          };
          setloader(true);
          chargeorder(
            {
              ...card_obj,
            },
            current_store.user.token,
            (data1) => {
              if (data1.card_debit_error) {
                setpayment_details({
                  ...payment_details,
                  failed_cards: [
                    ...payment_details.failed_cards,
                    data.card_list.filter(
                      (el) => el.id == current_selected_card
                    )[0],
                  ],
                });
                setcurrent_selected_card("");
                setcard_dropdown(true);
                if (
                  data.card_list.filter(
                    (el) =>
                      [
                        ...payment_details.failed_cards,
                        data.card_list.filter(
                          (el) => el.id == current_selected_card
                        )[0],
                      ]
                        .map((el) => el.id)
                        .indexOf(el.id) == -1
                  ).length == 0
                ) {
                  setcard_err(true);
                }
                setloader(false);
              } else {
                if (!data1.status) {
                  setloader(false);
                  return navigate("/404");
                }
                setstatuses([...data1.data.statusLogs]);

                setpayment_details({
                  ...payment_details,
                  payed_as: {
                    ...card_obj,
                    card_data: data.card_list.filter(
                      (el) => el.id == current_selected_card
                    )[0],
                  },
                  payed: true,
                });
                setpayment_modal(false);
                setloader(false);
              }
            }
          );
        }
        break;
      case "cash":
        let cash_obj = {
          order_id: data.order_id,
          amount: total,
          delivery: delivery,
          client_id: data.client_id,
          payment_type: current_active,
          dateTime: new Date(),
        };
        setloader(true);
        chargeorder(
          {
            ...cash_obj,
          },
          current_store.user.token,
          (data1) => {
            if (!data1.status) {
              setloader(false);
              return navigate("/404");
            }
            setstatuses([...data1.data.statusLogs]);
            setpayment_details({
              ...payment_details,
              payed_as: {
                ...cash_obj,
              },
              payed: true,
            });
            setpayment_modal(false);
            setloader(false);
          }
        );
        break;
      case "mark_as_charged":
        let cash_obj1 = {
          order_id: data.order_id,
          amount: total,
          delivery: delivery,
          client_id: data.client_id,
          payment_type: current_active,
          // card_id: current_selected_card,
          dateTime: new Date(),
        };
        setloader(true);
        chargeorder(
          {
            ...cash_obj1,
          },
          current_store.user.token,
          (data1) => {
            // console.log(data1);
            if (!data1.status) {
              setloader(false);
              return navigate("/404");
            }
            setstatuses([...data1.data.statusLogs]);
            setpayment_details({
              ...payment_details,
              payed_as: {
                ...cash_obj1,
              },
              payed: true,
            });
            setpayment_modal(false);
            setloader(false);
          }
        );
        break;
      case "manual_card":
        {
          // return;
          let cash_obj1 = {
            order_id: data.order_id,
            amount: total,
            delivery: delivery,
            client_id: data.client_id,
            payment_type: current_active,
            dateTime: new Date(),
          };
          setloader(true);
          chargeorder(
            {
              ...cash_obj1,
            },
            current_store.user.token,
            (data1) => {
              if (!data1.status) {
                setloader(false);
                if (
                  data1.status == false &&
                  data1.err_msg &&
                  data1.err_msg == "try again later"
                ) {
                  return;
                }
                navigate("/404");
                return;
              }
              setstatuses([...data1.data.statusLogs]);
              setpayment_details({
                ...payment_details,
                payed_as: {
                  ...cash_obj1,
                },
                payed: true,
              });
              setpayment_modal(false);
              setloader(false);
            }
          );
        }
        break;
      case "pay_later":
        {
          let cash_obj1 = {
            order_id: data.order_id,
            amount: total,
            delivery: delivery,
            client_id: data.client_id,
            payment_type: current_active,
            dateTime: new Date(),
          };
          setloader(true);
          chargeorder(
            {
              ...cash_obj1,
            },
            current_store.user.token,
            (data1) => {
              if (!data1.status) {
                setloader(false);
                navigate("/404");

                return;
              }
              setstatuses([...data1.data.statusLogs]);
              setpayment_details({
                ...payment_details,
                payed_as: {
                  ...cash_obj1,
                },
                payed: true,
              });
              setpayment_modal(false);
              setloader(false);
            }
          );
        }
        break;

      default:
        break;
    }
  };
  return (
    <div className="chargeordermodal">
      <div className="chargeordermodal_main">
        <div className="chargeordermodal_header">
          <div className="heading" style={{ height: "78px" }}>
            Payment Method
          </div>
          <Close
            clickhandel={() => {
              setpayment_modal(false);
            }}
          />
        </div>
        <div className="chargeordermodal_body">
          <div className="cards">
            {card_err && payment_details.failed_cards.length > 0 && (
              <div
                className="card_body"
                onClick={() => {
                  setpayment_details({
                    ...payment_details,
                    failed_cards: [],
                  });
                  setpayment_modal(false);
                  setTimeout(() => {
                    setpayment_modal(true);
                  }, 10);
                }}
              >
                <div>Reset Card</div>
                <div>on file</div>
              </div>
            )}
            {!(payment_details.payment == "" || card_err) ? (
              <div
                className={
                  "card_body" +
                  (current_active == "card" ? " card_body_active" : "")
                }
                card_disabled={(
                  payment_details.payment == "" || card_err
                ).toString()}
                onClick={() => {
                  setcurrent_active("card");
                  if (card_dropdown) {
                    setcard_dropdown_active(!card_dropdown_active);
                  }
                }}
              >
                {!card_dropdown ? (
                  <>
                    <div>Credit Card</div>
                    <div>on file</div>
                  </>
                ) : (
                  <>
                    <div>Other Credit</div>
                    <div>Card on file</div>
                    <div
                      className={
                        "cc_card_dropdown" +
                        (card_dropdown_active ? " card_dropdown_active" : "")
                      }
                      style={
                        card_dropdown_active
                          ? {
                              height: `${
                                12 +
                                data.card_list.filter(
                                  (el) =>
                                    payment_details.failed_cards
                                      .map((el) => el.id)
                                      .indexOf(el.id) == -1
                                ).length *
                                  31
                              }px`,
                            }
                          : {}
                      }
                    >
                      <div>
                        {data.card_list
                          .filter(
                            (el) =>
                              payment_details.failed_cards
                                .map((el) => el.id)
                                .indexOf(el.id) == -1
                          )
                          .map((el) => (
                            <div
                              className={
                                "cc_card_body" +
                                (current_selected_card == el.id
                                  ? " current_selected_card"
                                  : "")
                              }
                              onClick={() => {
                                setcurrent_selected_card(el.id);
                              }}
                            >
                              **** **** {el.cc_last_4_digits}
                            </div>
                          ))}
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              ""
            )}
            <div
              className={
                "card_body" +
                (current_active == "manual_card" ? " card_body_active" : "")
              }
              onClick={() => {
                setcurrent_active("manual_card");
                if (card_dropdown) {
                  setcard_dropdown_active(false);
                }
              }}
            >
              <div>Credit Card</div>
              <div>to swipe</div>
            </div>
            <div
              className={
                "card_body" +
                (current_active == "cash" ? " card_body_active" : "")
              }
              onClick={() => {
                setcurrent_active("cash");
                if (card_dropdown) {
                  setcard_dropdown_active(false);
                }
              }}
            >
              <div>Accept</div>
              <div>Cash</div>
            </div>
            <div
              className={
                "card_body" +
                (current_active == "mark_as_charged" ? " card_body_active" : "")
              }
              onClick={() => {
                setcurrent_active("mark_as_charged");
                if (card_dropdown) {
                  setcard_dropdown_active(false);
                }
              }}
            >
              <div>Mark as</div>
              <div>Charged</div>
            </div>
            <div
              className={
                "card_body" +
                (current_active == "pay_later" ? " card_body_active" : "")
              }
              onClick={() => {
                setcurrent_active("pay_later");
                if (card_dropdown) {
                  setcard_dropdown_active(false);
                }
              }}
            >
              <div>Pay</div>
              <div>later</div>
            </div>

            {card_api_err && (
              <div className="card_body card_error">Error: {card_api_err} </div>
            )}
          </div>
        </div>
        <div className="chargeordermodal_footer">
          <div className="total_due">
            <div>Total Due:</div>
            <div>${total}</div>
          </div>
          <div className="charge_now">
            <div className="btn" type="type2" onClick={handelchargenow}>
              Charge Now
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
