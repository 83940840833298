import React from "react";
import search_img from "../../../img/common/searchandfilter/search.png";
import filter_img from "../../../img/common/searchandfilter/filter.png";
import { ReactComponent as Filter_Icon } from "../../../img/icons/filter_icon.svg";

import "./searchandfilterStyle.scss";

export const SearchAndFilterBar = ({
  placeholder,
  change_search,
  handlechange_search,
  handleclick_filter,
  filter_show,
}) => {
  return (
    <div className="searchandfilterbar">
      <div
        className={
          "search_input " + (filter_show == "no" ? "remove_margin_right" : "")
        }
      >
        <img src={search_img} />
        <input
          placeholder={placeholder}
          value={change_search}
          onChange={(e) => handlechange_search(e.target.value)}
        />
      </div>
      {filter_show == "no" ? (
        ""
      ) : (
        <div className="filter_img_div">
          <Filter_Icon onClick={() => handleclick_filter(true)} />

          {/* <img src={filter_img} onClick={() => handleclick_filter(true)} /> */}
        </div>
      )}
    </div>
  );
};
