import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setitems } from "../../../API/API";
import { Close, Tick } from "../../Common/Images/Images";

export const ItemsViewEditModal = ({
  current_item,
  og_item_list,
  cancel,
  item_list,
  setitem_list,
  setloader,
  setdeletemodal,
}) => {
  const current_store = useSelector((state) => state);

  const [item_name, setitem_name] = useState("");
  const [haschild_checkbox, sethaschild_checkbox] = useState(false);
  const [edit_btn, setedit_btn] = useState(false);
  // const [add_item, setadd_item] = useState(false)
  useEffect(() => {
    setitem_name(current_item.name);
  }, []);
  return (
    <div className="itemadd">
      <div>
        <div className="head">
          <Close clickhandel={cancel} />
        </div>
        <div className="body">
          <div className="title">Item Name</div>
          <input
            type="text"
            style={{ borderColor: "var(--modal-bg)" }}
            placeholder="Enter Item-Name"
            value={current_item.name}
            readOnly
            // onChange={(e) => {
            //   setitem_name(e.target.value);
            // }}
          />
          {edit_btn && (
            <>
              <div className="title">new Item Name</div>
              <input
                type="text"
                placeholder="Enter Item-Name"
                value={item_name}
                onChange={(e) => {
                  setitem_name(e.target.value);
                }}
              />
            </>
          )}
          {/* <div
            className="custoptionselect"
            onClick={() => {
              sethaschild_checkbox(!haschild_checkbox);
            }}
          >
            <div
              className={
                "checkbox " + (haschild_checkbox ? " checkbox_active" : "")
              }
            ></div>
            <div className="title">Will contain collections ?</div>
          </div> */}
        </div>

        <div className="footer">
          {/* <Tick
                        color={item_name.trim().length > 0 ? 3 : 0}
                        custStyle={(item_name.trim().length > 0) ? {} : { cursor: 'no-drop' }}
                        clickhandel={() => {
                            cancel();
                        }}
                    /> */}
          {/* <div
            className="btn"
            type={item_name.trim().length > 0 ? "type2" : "type1"}
            onClick={() => {
              if (item_name.trim().length > 0) {
                let temp_arr = [...og_item_list];
                temp_arr.push({
                  has_child: haschild_checkbox,
                  id: temp_arr.length,
                  name: item_name,
                  special_cost: false,
                  items: [],
                });
                setitems(temp_arr, current_store.user.token, (data) => {
                });
                // cancel();
              }
            }}
          >
            Add
          </div> */}
          {edit_btn ? (
            <div
              className="btn"
              type="type3"
              onClick={() => {
                let temp_items = [...og_item_list];
                temp_items = temp_items.map((el) => {
                  if (el.id == current_item.id) {
                    return {
                      ...el,
                      name: item_name,
                    };
                  }
                  return el;
                });
                setitems(temp_items, current_store.user.token, (data) => {
                  setitem_list([...temp_items]);
                  cancel();
                });
              }}
            >
              Save
            </div>
          ) : (
            <div
              className="btn"
              type="type2"
              onClick={() => {
                setedit_btn(true);
              }}
            >
              Edit
            </div>
          )}
          <div
            className="btn"
            type="type5"
            onClick={() => {
              setdeletemodal(true);
            }}
          >
            Delete
          </div>
        </div>
      </div>
    </div>
  );
};
