import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import "./addorderimagesmodalStyle.scss";

import add_icon from "../../../../img/miscellaneous/close_square_add.png";
import trash_icon from "../../../../img/miscellaneous/trash_white.png";
import { Close } from "../../../Common/Images/Images";
import { base64tolink } from "../../../../API/API";
import { useSelector } from "react-redux";

export const AddOrderImagesModal = ({
  data,
  current_image_index,
  images,
  setimage_modal,
  setimages,
  loader,
  setloader,
}) => {
  const current_store = useSelector((state) => state);
  const [currentshowing_img, setcurrentshowing_img] = useState(0);
  const [confirm_delete_modal, setconfirm_delete_modal] = useState(false);
  const slider1 = useRef();
  const settings1 = {
    dots: false,
    infinite: true,
    arrows: !confirm_delete_modal,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: current_image_index,
    afterChange: (i) => setcurrentshowing_img(i),
  };
  const set_height_width = (i) => {
    setTimeout(() => {
      let current_img = document.getElementsByClassName(
        "show_images_set_height_width" + i
      );
      [...current_img].map((el) => {
        if (
          el.getBoundingClientRect().width / el.getBoundingClientRect().height >
          1.7395
        ) {
          el.style.width = "527.08px";
          el.style.height = "auto";
          // current_img.style.borderInline = '7px solid #fff'
        } else {
          el.style.width = "auto";
          el.style.height = "310px";
          // current_img.style.borderBlock = '7px solid #fff'
        }
      });
    }, 0);
  };
  useEffect(() => {
    [...images[current_image_index].img].map((el, i) => set_height_width(i));
    // slider1.current.slickGoTo(current_image_index)
    // slider1.slick('slickGoTo', 2);
  }, [images]);
  useEffect(() => {
    // setTimeout(() => {
    //     let current_img = document.getElementById('current_img')
    //     if ((current_img.getBoundingClientRect().width / current_img.getBoundingClientRect().height) > 1) {
    //         current_img.style.width = '100%'
    //         current_img.style.height = 'auto'
    //         // current_img.style.borderInline = '7px solid #fff'
    //     }
    //     else {
    //         current_img.style.width = 'auto'
    //         current_img.style.height = '100%'
    //         // current_img.style.borderBlock = '7px solid #fff'
    //     }
    // }, 0);
  }, [currentshowing_img]);
  const add_img_to_current_open_item = (current, i) => {
    if (current.target.files.length) {
      let temp_arr = [];
      setloader(true);
      Object.values(current.target.files).map((el, img_num) => {
        var reader = new FileReader();
        reader.readAsDataURL(el);
        reader.onload = function () {
          base64tolink(
            {
              base_64: reader.result.split(",")[1],
              name:
                data.order_id +
                "_" +
                i +
                "_" +
                (images[i].img.length + img_num + 1) +
                "-" +
                new Date().getTime() +
                ".jpeg",
            },
            current_store.user.token,
            (data) => {
              if (!data.status) {
                // navigate("/orders");
                return;
              }
              temp_arr.push(data.link);
              if (current.target.files.length == temp_arr.length) {
                let temp_images = [...images];
                // temp_images[i].img = [...temp_arr];
                temp_images[i].img = [...images[i].img, ...temp_arr];
                setimages([...temp_images]);
                setloader(false);
              }
            }
          );
          //   temp_arr.push(reader.result);
          //   if (current.target.files.length == temp_arr.length) {
          // let temp_images = [...images];
          // setimages([...temp_images]);
        };
        // };
      });
    }
  };
  return (
    <div className="addorderimagesmodal">
      <div className="addorderimagesmodal_main">
        <div className="action_btns">
          <div
            className="delete_img"
            style={{
              visibility: current_image_index == 0 ? "hidden" : "visibility",
            }}
          >
            <img
              src={trash_icon}
              onClick={() => {
                setconfirm_delete_modal(true);
              }}
            />
          </div>
          <div className="add_img">
            <input
              type="file"
              accept=".jpg,.png"
              id={"add_img_to_current_open_item"}
              onChange={(e) =>
                add_img_to_current_open_item(e, current_image_index)
              }
              multiple
            />
            <label
              htmlFor="add_img_to_current_open_item"
              style={{
                visibility: current_image_index == 0 ? "hidden" : "visibility",
              }}
            >
              <img src={add_icon} style={{ filter: "invert(0%)" }} />
            </label>
          </div>
          <div className="close_img">
            <Close
              clickhandel={() => {
                setimage_modal(false);
              }}
            />
          </div>
        </div>
        <div className="img_div">
          <Slider {...settings1} ref={slider1}>
            {images[current_image_index].img.map((el, i) => (
              <div>
                <img src={el} className={"show_images_set_height_width" + i} />
              </div>
            ))}
          </Slider>
          <div className="dots_to_show">
            {images[current_image_index].img.map((el, i) => (
              <div
                className={`dot ${i == currentshowing_img ? "dot_active" : ""}`}
              ></div>
            ))}
          </div>
          {/* <img src={images[current_image_index].img[currentshowing_img]} id='current_img' /> */}

          {
            confirm_delete_modal ? (
              <div className="confirm_delete_image_modal">
                <div>
                  <div className="txt">Delete Image?</div>
                  <div>
                    <div
                      className="btn"
                      type="type4"
                      onClick={() => {
                        let temp_images = [...images];
                        if (
                          currentshowing_img + 1 ==
                          images[current_image_index].img.length
                        ) {
                          setcurrentshowing_img(0);
                        }
                        temp_images[current_image_index].img.splice(
                          currentshowing_img,
                          1
                        );
                        setimages([...temp_images]);
                        if (images[current_image_index].img.length == 0) {
                          setimage_modal(false);
                        }
                        setconfirm_delete_modal(false);
                      }}
                    >
                      Delete
                    </div>
                    <div
                      className="btn"
                      type="type4"
                      onClick={() => {
                        setconfirm_delete_modal(false);
                      }}
                    >
                      Cancel
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )
            // <>
            //     <div className='prev_img'>
            //         <img src={prev_icon} onClick={() => { setcurrentshowing_img((currentshowing_img != 0) ? currentshowing_img - 1 : images[current_image_index].img.length - 1) }} />
            //     </div>
            //     <div className='next_img'>
            //         <img src={next_icon} onClick={() => { setcurrentshowing_img(((currentshowing_img + 1) < images[current_image_index].img.length) ? currentshowing_img + 1 : 0) }} />
            //     </div>

            // </>
          }
        </div>
      </div>
    </div>
  );
};
