import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { setitems } from "../../../API/API";
import { Close } from "../../Common/Images/Images";
import { CustDropdown } from "../../Custom/CustDropdown/CustDropdown";
import "./addsubcategorymodalStyle.scss";

// Note
//      type0 = needs to be edited and errors
//      type1 = adding subcategory
//      type2 = adding products for category = true and subcategory = false
//      type3 = adding products for category = true and subcategory = true
//      type4 = adding products to categoy which has minimum price with minimum weight and cost/lb

export const AddSubcategoryModal = ({
  setloader,
  og_item_list,
  setog_item_list,
  data,
  type,
  cancel,
  item_array_id,
}) => {
  const current_store = useSelector((state) => state);
  const [selectedsubcategory, setselectedsubcategory] = useState("");
  const [product_name, setproduct_name] = useState("");
  const [description, setdescription] = useState("");
  const [cost, setcost] = useState(0);
  const [min_weight, setmin_weight] = useState("");
  const [cost_per_lb, setcost_per_lb] = useState("");
  const [add_btn, setadd_btn] = useState(false);

  useEffect(() => {
    if (type === 1) {
      if (product_name.trim().length == 0) {
        setadd_btn(false);
      } else {
        setadd_btn(true);
      }
    }
    if (type === 2) {
      if (description.trim().length == 0 || Number(cost) < 0) {
        setadd_btn(false);
      } else {
        setadd_btn(true);
      }
    }
    if (type === 3) {
      if (description.trim().length == 0 || Number(cost) < 0) {
        setadd_btn(false);
      } else {
        setadd_btn(true);
      }
    }
    if (type === 4) {
      if (
        description.trim().length === 0 ||
        // cost == "" ||
        Number(cost) < 0 ||
        min_weight == "" ||
        min_weight == 0 ||
        cost_per_lb == "" ||
        cost_per_lb == 0
      ) {
        setadd_btn(false);
      } else {
        setadd_btn(true);
      }
    }
    // setselectedsubcategory()
  }, [product_name, description, cost, min_weight, cost_per_lb]);
  useEffect(() => {
    if (type === 4 && cost_per_lb !== "" && min_weight != "") {
      setcost(cost_per_lb * min_weight);
    }
  }, [cost_per_lb, min_weight]);

  return (
    <div className="additemcategorymodal">
      <div>
        <div className="head">
          <Close clickhandel={cancel} />
          {/* {type} */}
        </div>
        <div className="body">
          <div className="title">Category</div>
          <div className="cust_input input">
            <input value={data.name} readOnly />
          </div>
          {type === 0 && <>needs revision not completed</>}
          {type === 1 && (
            <>
              <div className="title">Product</div>
              <div className="cust_input input">
                <input
                  type="text"
                  placeholder="insert product"
                  value={product_name}
                  onChange={(e) => {
                    setproduct_name(e.target.value);
                  }}
                />
              </div>
            </>
          )}
          {type === 2 && (
            <>
              <div className="title">Description</div>
              <div className="cust_input input">
                <input
                  type="text"
                  placeholder="insert description"
                  value={description}
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                />
              </div>
              <div className="title">Cost</div>
              <div className="cust_input input">
                <input
                  type="number"
                  placeholder="insert cost"
                  value={cost}
                  onChange={(e) => {
                    setcost(Math.abs(e.target.value));
                  }}
                />
              </div>
            </>
          )}
          {type === 3 && (
            <>
              <div className="title">Product</div>
              <div className="cust_input input">
                <input
                  type="text"
                  placeholder="insert product"
                  value={data.items[item_array_id].name}
                  readOnly
                />
              </div>
              <div className="title">Description</div>
              <div className="cust_input input">
                <input
                  type="text"
                  placeholder="insert description"
                  value={description}
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                />
              </div>
              <div className="title">Cost</div>
              <div className="cust_input input">
                <input
                  type="number"
                  placeholder="insert cost"
                  value={cost}
                  onChange={(e) => {
                    setcost(Math.abs(e.target.value));
                  }}
                />
              </div>
            </>
          )}
          {type === 4 && (
            <>
              <div className="title">Description</div>
              <div className="cust_input input">
                <input
                  type="text"
                  placeholder="insert description"
                  value={description}
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                />
              </div>
              <div className="title">Cost per lb</div>
              <div className="cust_input input input_padding_5">
                <div>$</div>
                <input
                  type="number"
                  placeholder="insert cost"
                  value={cost_per_lb}
                  onChange={(e) => {
                    setcost_per_lb(Math.abs(e.target.value));
                  }}
                />
                <div>/ lb</div>
              </div>
              <div className="title">Minimum weight</div>
              <div className="cust_input input">
                <input
                  type="number"
                  placeholder="insert cost"
                  value={min_weight}
                  onChange={(e) => {
                    setmin_weight(Math.abs(e.target.value));
                  }}
                />
                <div>lb</div>
              </div>
              {min_weight != "" && min_weight != 0 && (
                <>
                  <div className="title">
                    Cost for {min_weight == "" ? 0 : Number(min_weight)} lb
                    Weight
                  </div>
                  <div className="cust_input input input_padding_5">
                    <div>$</div>
                    <input
                      type="number"
                      placeholder="insert cost"
                      value={cost}
                      onChange={(e) => {
                        setcost(Math.abs(e.target.value));
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}

          {/* <div className='cust_input input'> */}
          {/* <CustDropdown
                        custid={'dropdown1'}
                        value={selectedsubcategory}
                        custclass={'border_black'}
                        defaultplaceholder={'select product'}
                        dropdown_arr={[...data.item_subcategory.map((el, i) => { return { key: i, value: el.name } })]}
                        // tempclose={}
                        setvalue={setselectedsubcategory}
                    /> */}
          {/* </div> */}
        </div>
        <div className="footer">
          <div
            className="btn"
            type={add_btn ? "type2" : "type1"}
            onClick={() => {
              if (!add_btn) {
                return;
              }
              setloader(true);
              let temp_arr = [...og_item_list];
              switch (type) {
                case 1:
                  temp_arr[data.id] = {
                    ...temp_arr[data.id],
                    items: [
                      ...temp_arr[data.id].items,
                      {
                        id: temp_arr[data.id].items.length,
                        name: product_name,
                        items: [],
                        delete_status: 0,
                      },
                    ],
                  };
                  break;
                case 2:
                  temp_arr[data.id] = {
                    ...temp_arr[data.id],
                    items: [
                      ...temp_arr[data.id].items,
                      {
                        item_id: temp_arr[data.id].items.length,
                        name: description,
                        cost: cost,
                        delete_status: 0,
                      },
                    ],
                  };
                  break;
                case 3:
                  temp_arr[data.id] = {
                    ...temp_arr[data.id],
                    items: [
                      ...temp_arr[data.id].items.map((el, i) => {
                        if (i == item_array_id) {
                          return {
                            ...el,
                            items: [
                              ...el.items,
                              {
                                item_id: el.items.length,
                                name: description,
                                cost: cost,
                                delete_status: 0,
                              },
                            ],
                          };
                        }
                        return el;
                      }),
                    ],
                  };
                  break;
                // test case 4
                case 4:
                  temp_arr[data.id] = {
                    ...temp_arr[data.id],
                    items: [
                      ...temp_arr[data.id].items,
                      {
                        item_id: temp_arr[data.id].items.length,
                        name: description,
                        cost: {
                          // cost for min_weight
                          min_cost: cost,
                          // minimum weight to be considered
                          min_weight: min_weight,
                          // extra weight cost / lb
                          per_cost: cost_per_lb,
                        },
                        delete_status: 0,
                      },
                    ],
                  };
                  break;

                default:
                  break;
              }
              //   return;

              setitems(temp_arr, current_store.user.token, (data) => {
                setloader(false);
                setog_item_list([...temp_arr]);
                cancel();
              });
              // cancel()
            }}
          >
            Add
          </div>
        </div>
      </div>
    </div>
  );
};
