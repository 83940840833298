import React, { useEffect, useState } from "react";
import "./futurepredictionStyle.scss";
// const calc_day = (num) => {
//   num = Number(num);
//   if (num === 0) {
//     return "Today";
//   }
//   if (num === 1) {
//     return "Tomorrow";
//   }
//   if (num === 2) {
//     return "Day After Tomorrow";
//   }
//   if (num >= 3 && num <= 5) {
//     return num + " days";
//   }

//   // else if (num === 6) {
//   //   return "Within a Week";
//   // } else if (num >= 7 && num < 14) {
//   //   return "Within a Week";
//   // } else if (num >= 14 && num < 21) {
//   //   return "Next Week";
//   // } else if (num >= 21 && num < 28) {
//   //   return "Next Week";
//   // }
//   if (num >= 0 && num < 7) {
//     return "In a week";
//   }
//   if (num >= 7 * 1 && num < 7 * 2) {
//     return "In 2 weeks";
//   }
//   if (num >= 7 * 2 && num < 7 * 3) {
//     return "In 3 weeks";
//   }
//   if (num >= 7 * 3 && num < 7 * 4) {
//     return "In 4 weeks";
//   }
//   if (num >= 7 * 4 && num < 7 * 5 - 5) {
//     return "Within a month";
//   }
//   if (num >= 7 * 5 - 5 && num < 7 * 10 - 10) {
//     return "In 2 months";
//   }
//   if (num >= 7 * 10 - 10 && num < 7 * 15 - 15) {
//     return "In 3 months";
//   }
//   if (num >= 7 * 15 - 15 && num < 7 * 20 - 20) {
//     return "In 4 months";
//   }
//   if (num >= 7 * 20 - 20 && num < 7 * 25 - 25) {
//     return "In 5 months";
//   }
//   if (num >= 7 * 25 - 25 && num < 7 * 30 - 30) {
//     return "In 6 months";
//   }
//   if (num >= 7 * 30 - 30 && num < 7 * 35 - 35) {
//     return "In 7 months";
//   }
//   if (num >= 7 * 35 - 35 && num < 7 * 40 - 40) {
//     return "In 8 months";
//   }
//   if (num >= 7 * 40 - 40 && num < 7 * 45 - 45) {
//     return "In 9 months";
//   }
//   if (num >= 7 * 45 - 45 && num < 7 * 50 - 50) {
//     return "In 10 months";
//   }
//   if (num >= 7 * 50 - 50 && num < 7 * 55 - 55) {
//     return "In 11 months";
//   }
//   if (num >= 7 * 55 - 55 && num < 7 * 60 - 60) {
//     return "Within a year";
//   }
//   return num + " days";
// };
// const calc_avg = (num) => {
//   let temp_avg_data = 1 / num;
//   if (temp_avg_data >= 0 && temp_avg_data < 7) {
//     return "Once per week";
//   }
//   if (temp_avg_data >= 7 * 1 && temp_avg_data < 7 * 2) {
//     return "Once every 2 weeks";
//   }
//   if (temp_avg_data >= 7 * 2 && temp_avg_data < 7 * 3) {
//     return "Once every 3 weeks";
//   }
//   if (temp_avg_data >= 7 * 3 && temp_avg_data < 7 * 4) {
//     return "Once every 4 weeks";
//   }
//   if (temp_avg_data >= 7 * 4 && temp_avg_data < 7 * 5 - 5) {
//     return "Once per month";
//   }
//   if (temp_avg_data >= 7 * 5 - 5 && temp_avg_data < 7 * 10 - 10) {
//     return "Once every 2 months";
//   }
//   if (temp_avg_data >= 7 * 10 - 10 && temp_avg_data < 7 * 15 - 15) {
//     return "Once every 3 months";
//   }
//   if (temp_avg_data >= 7 * 15 - 15 && temp_avg_data < 7 * 20 - 20) {
//     return "Once every 4 months";
//   }
//   if (temp_avg_data >= 7 * 20 - 20 && temp_avg_data < 7 * 25 - 25) {
//     return "Once every 5 months";
//   }
//   if (temp_avg_data >= 7 * 25 - 25 && temp_avg_data < 7 * 30 - 30) {
//     return "Once every 6 months";
//   }
//   if (temp_avg_data >= 7 * 30 - 30 && temp_avg_data < 7 * 35 - 35) {
//     return "Once every 7 months";
//   }
//   if (temp_avg_data >= 7 * 35 - 35 && temp_avg_data < 7 * 40 - 40) {
//     return "Once every 8 months";
//   }
//   if (temp_avg_data >= 7 * 40 - 40 && temp_avg_data < 7 * 45 - 45) {
//     return "Once every 9 months";
//   }
//   if (temp_avg_data >= 7 * 45 - 45 && temp_avg_data < 7 * 50 - 50) {
//     return "Once every 10 months";
//   }
//   if (temp_avg_data >= 7 * 50 - 50 && temp_avg_data < 7 * 55 - 55) {
//     return "Once every 11 months";
//   }
//   if (temp_avg_data >= 7 * 55 - 55 && temp_avg_data < 7 * 60 - 60) {
//     return "Once every year";
//   }

//   return "Once every 1+ year";
// };

export const FuturePredictions = ({
  data,
  latest_values,
  values,
  order_list,
  calc_day,
  calc_avg,
}) => {
  // const [order_list, setorder_list] = useState([[], []]);
  // const [values, setvalues] = useState({
  //   latest_order: 0,
  //   total_days: 0,
  //   year_avg: 0,
  //   month_avg: {
  //     month_6: 0,
  //     month_4: 0,
  //     month_3: 0,
  //     month_2: 0,
  //     month_1: 0,
  //   },
  //   monthly_avg: 0,
  //   week_avg: {
  //     weeek_2: 0,
  //     weeek_1: 0,
  //   },
  //   weekly_avg: 0,
  //   daily_avg: 0,
  // });
  // const [latest_values, setlatest_values] = useState({
  //   latest_order: 0,
  //   total_days: 0,
  //   year_avg: 0,
  //   month_avg: {
  //     month_6: 0,
  //     month_4: 0,
  //     month_3: 0,
  //     month_2: 0,
  //     month_1: 0,
  //   },
  //   monthly_avg: 0,
  //   week_avg: {
  //     weeek_2: 0,
  //     weeek_1: 0,
  //   },
  //   weekly_avg: 0,
  //   daily_avg: 0,
  // });
  // useEffect(() => {
  //   if (data.orders && data.orders.length > 0) {
  //     let daily_avg = 0;
  //     let temp_og_arr = data.orders.sort((a, b) => {
  //       return (
  //         new Date(a.order_date).valueOf() - new Date(b.order_date).valueOf()
  //       );
  //     });
  //     let temp_latest_arr = [...temp_og_arr].splice(-5);
  //     setorder_list([[...temp_og_arr], [...temp_latest_arr]]);

  //     let temp_values = {
  //       total_days: (
  //         (new Date().getTime() -
  //           new Date(temp_og_arr[0].order_date).getTime()) /
  //         (1000 * 3600 * 24)
  //       ).toFixed(0),
  //       latest_order:
  //         temp_og_arr[temp_og_arr.length - 1].order_date.split("T")[0],
  //     };

  //     temp_values = {
  //       ...temp_values,
  //       daily_avg: temp_og_arr.length / temp_values.total_days,
  //     };
  //     setvalues((prev) => ({
  //       ...prev,
  //       ...temp_values,
  //       estimated_day_left_for_future_order: Math.ceil(
  //         1 / Number(temp_values.daily_avg)
  //       ),
  //     }));

  //     let temp_latest_values = {
  //       total_days: (
  //         (new Date().getTime() -
  //           new Date(temp_latest_arr[0].order_date).getTime()) /
  //         (1000 * 3600 * 24)
  //       ).toFixed(0),
  //       latest_order:
  //         temp_latest_arr[temp_latest_arr.length - 1].order_date.split("T")[0],
  //     };
  //     temp_latest_values = {
  //       ...temp_latest_values,
  //       daily_avg: temp_latest_arr.length / temp_latest_values.total_days,
  //     };
  //     setlatest_values((prev) => ({
  //       ...prev,
  //       ...temp_latest_values,
  //       estimated_day_left_for_future_order: Math.round(
  //         1 / Number(temp_latest_values.daily_avg)
  //       ),
  //     }));
  //   }
  // }, [data]);
  // console.log(
  //   calc_avg(values.daily_avg) +
  //     "...,..." +
  //     calc_day(values.estimated_day_left_for_future_order) +
  //     "...,..." +
  //     calc_avg(latest_values.daily_avg) +
  //     "...,..." +
  //     calc_day(latest_values.estimated_day_left_for_future_order)
  // );
  return (
    <div
      className="futurepredictions_body"
      style={order_list[0].length === 0 ? { width: "100%" } : {}}
    >
      {order_list[0].length === 0 ? (
        <div
          style={{
            textAlign: "center",
            width: "100%",
            border: 0,
            padding: "0px 0px 0px 43px",
          }}
        >
          No Order
        </div>
      ) : (
        <>
          <div className="lifetime_trend">
            <div className="title">Lifetime Trend</div>
            <div>
              <div>All Time AVG.</div>
              <div>{calc_avg(values.daily_avg)}</div>
            </div>
            <div>
              <div>Expected order</div>
              <div>{calc_day(values.estimated_day_left_for_future_order)}</div>
            </div>
          </div>
          {/* <hr /> */}
          <div className="current_trend">
            <div className="title">Current Trend</div>
            <div>
              <div>Latest 5 AVG.</div>
              <div>{calc_avg(latest_values.daily_avg)}</div>
            </div>
            <div>
              <div>Expected order</div>
              <div>
                {calc_day(latest_values.estimated_day_left_for_future_order)}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
